// Import packages
import React, { useState, useEffect, useRef } from "react";
import { connect } from 'react-redux';

// Import styles
import './favorites.scss';

// Import components
import ProductCardGrid from "../../../components/cards/product-card-grid/ProductCardGrid";

//import utils
import {BackIcon, BlackArrowRightIcon} from "../../../assets/images";
import {Link} from "react-router-dom";
import { GetFavoritePosts } from "../../../redux/actions";
import { getPropsFromState } from "../../../redux/mapStateToProps";
import { Pagination } from "antd";
import { PER_PAGE_POSTS_COUNT } from "../../../constants/constants";

function Favorites (props) {

    useEffect(() => {
        props.GetFavoritePosts({
            offset: 0,
            limit: PER_PAGE_POSTS_COUNT,
        })
    }, [])

    const { favorites, translation, windowSizes } = props;
    const [currentPage, setCurrentPage] = useState(1);
    const isFetching = useRef(false)

    useEffect(() => {
      // console.log('favorites.count',favorites?.list.length,favorites?.count, currentPage)
        if(favorites?.count > 0 &&  !favorites?.list?.length && currentPage > 1){
            // case: in last page removing all items
            onPageChanged(currentPage-1)
        }
    }, [favorites?.count])

    async function onPageChanged (page) {
        if (!isFetching.current) {
            isFetching.current = true;
            setCurrentPage(page);
            window.scrollTo({
                top: 0
            });
           await props.GetFavoritePosts({
                offset: (page - 1) * PER_PAGE_POSTS_COUNT,
                limit: PER_PAGE_POSTS_COUNT,
            })
            isFetching.current = false;
        }
    }

    const itemRender = (_, type, originalElement) => {
        if (type === 'prev') {
            return <a className={'prev_btn'}><BlackArrowRightIcon className={'arrow_icon'}/>{translation?.search_page_prevBtn}</a>;
        }
        if (type === 'next') {
            return <a className={'next_btn'}>{translation?.search_page_nextBtn}<BlackArrowRightIcon className={'arrow_icon'}/></a>;
        }
        return originalElement;
    };

    return <section className='favorites-wrapper'>
        <Link to={'/profile'} className={`${(windowSizes?.width || window.innerWidth) < 801 ? 'mobile-back' : 'back'}`}>
            <BackIcon/> <span>{translation?.profile_back_title}</span>
        </Link>
        <div className={'favorites-title'}>{translation?.profile_favourite_title}</div>
        <div className={'favorite-blocked-products'}>{
            (!!favorites?.list?.length ? favorites?.list?.map(item => {
                return <div className={'favorite-card'}>
                    <ProductCardGrid data={item} key={item.id}/>
                </div>
            }) : <div className="favorite-empty-view">
                <p>{translation?.profile_favourite_empty_text}</p>
            </div>)}
        </div>
        <div className={'pagination'}>
            <Pagination current={currentPage}
                        onChange={onPageChanged}
                        total={favorites?.count}
                        defaultPageSize={PER_PAGE_POSTS_COUNT}
                        hideOnSinglePage={true}
                        itemRender={itemRender}
                        className={`search__posts_list__pagination ${currentPage===1? 'hide-prev' : ""}`}
            />
        </div>
    </section>
}

const mapStateToProps = (state) => getPropsFromState(state, [
    'translation',
    'selectedLanguage',
    'favorites',
    'windowSizes',
]);

const mapDispatchToProps = {
    GetFavoritePosts,
};

export default connect(mapStateToProps, mapDispatchToProps)(Favorites);
