// import packages
import React from "react";
import {connect} from "react-redux";
import Slider, {Settings} from "react-slick";
import {Tabs} from "antd";

//Import styles
import "./post-section.scss";

//Import Components
import {ArrowRightIcon } from "../../assets/images";
import {SectionHeader} from "./SectionHeader";

// Import utils
import {TabItemType, PostItemType} from "../../redux/actions/actionTypes";
import {getPropsFromState} from "../../redux/mapStateToProps";
import ProductCardGrid from "../cards/product-card-grid/ProductCardGrid";

type PropsTypes = {
    //todo change any type
    staticTexts?: any;
    withTabs?: boolean;
    showHeader?: boolean;
    seeAllButton?: boolean;
    title?: string;
    description?: string;
    posts?: Array<PostItemType>;
    tabs?: Array<TabItemType>;
    onTabChange?: any
    translation?:any
    windowSizes?:any
};

type StateTypes = {
    disabledPrev: boolean,
    disabledNext: boolean,
};

class PostSection extends React.Component<PropsTypes, StateTypes> {
    private section: Slider | any = null; //todo change any to null
    constructor(props: PropsTypes) {
        super(props);
        this.state = {
            disabledPrev: true,
            disabledNext: false,
        };
    }

    componentDidUpdate(prevProps: Readonly<PropsTypes>, prevState: Readonly<StateTypes>, snapshot?: any) {
        if (this.props?.posts?.length !== prevProps?.posts?.length) {
            let itemCount = this.getShowSlideCount();

            this.setState({
                ...this.state,
                disabledNext: (this.props?.posts?.length || 0) - 1 < itemCount,
            });
        }
    }

    getShowSlideCount = () => {
        let width = this.props.windowSizes?.width || window.innerWidth;
        if (width > 1250) return 5;
        else if (width > 800) return 4;
        else if (width > 600) return 3;
        else if (width > 450) return 2;
        else return 1;
    };

    afterSlideChange = (next: any) => {
        let itemCount = this.getShowSlideCount();
        this.setState({
            disabledPrev: next === 0,
            disabledNext: next + itemCount === this.props?.posts?.length,
        });
    };


    render() {
        const {withTabs, showHeader = true, seeAllButton = true,  title, description, posts, tabs,translation} = this.props
        const {disabledPrev, disabledNext} = this.state;
        const settings: Settings = {
            dots: false,
            arrows: false,
            infinite: false,
            speed: 500,
            slidesToShow: 5,
            slidesToScroll: 1,
            // centerMode: window.innerWidth < 600,
            swipe: true,
            beforeChange: (current, next) => this.afterSlideChange(next),
            responsive: [
                {
                    breakpoint: 1250,
                    settings: {
                        slidesToShow: 4,
                    }
                },
                {
                    breakpoint: 800,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 450,
                    settings: {
                        centerMode: true,
                        slidesToShow: 1,
                        centerPadding: "26px 0 0",
                    }
                },
            ]
        };
        const showSliderButtons = (posts?.length || 0) > this.getShowSlideCount();
        return <div className={`post_section`}>
            {showHeader && <SectionHeader className={'post_section__header'}
                                          title={title}
                                          translation={translation}
                                          seeAllButton={seeAllButton}
                                          description={description}/>}
            <div className="post_section__slider">
                {showSliderButtons && !disabledPrev && <button className="post_section__slider__arrow prev"
                                          onClick={() => this.section.slickPrev()}>
                    <ArrowRightIcon/>
                </button>}
                {
                    withTabs && <Tabs onChange={this.props.onTabChange}>
                        {
                            !!tabs?.length && tabs?.map(item => <Tabs.TabPane tab={item.title} key={item?.id}/>)
                        }
                    </Tabs>
                }
                <div className="post_section__list slider_list">
                    {posts?.length ? <Slider {...settings}
                            ref={(component: Slider | null) => {
                                this.section = component;
                            }}>
                        {posts?.map(item => {
                            return <ProductCardGrid className={'card_grid'}
                                                    data={item}
                                                    slug={'similar'}
                                                    key={item.id}/>
                            // return <ProductCard key={item.id}/>
                        })}
                    </Slider> :
                        <div className={'empty'}>{translation?.homepage_post_empty}</div>
                    }
                </div>
                {showSliderButtons && !disabledNext && <button className="post_section__slider__arrow next"
                                          onClick={() => this.section.slickNext()}>
                    <ArrowRightIcon/>
                </button>}
            </div>
        </div>
    }
}

const mapStateToProps = (state: any) => getPropsFromState(state, [
    // names (type - string) of props
    'translation',
    'windowSizes',
]);

const mapDispatchToProps = {
    //redux actions
};

export default connect(mapStateToProps, mapDispatchToProps)(PostSection);
