import {USER_CONSTS} from "../constants";


export interface LoggedInUserType {
    username: string,
    firstName: string
    lastName: string
    birthday: string
    profilePicturePath: string
    region: string
    address: string
    state: string
    phone: string
    isBlocked: boolean
    isNotificationsEnable: string
}


export type UserStateType = {
    loggedInUser: null | LoggedInUserType,
    isLoggedIn: boolean,
    accessToken: string,
    refreshToken: string,
};

export const initialState: UserStateType = {
    loggedInUser: null,
    isLoggedIn: false,
    accessToken: '',
    refreshToken: '',
};

export default (state: UserStateType = initialState, action: any): UserStateType => {
    switch (action.type) {
        case USER_CONSTS.LOG_IN:
            const user = action.payload?.user
            return {
                ...state,
                refreshToken: action.payload.refreshToken,
                accessToken: action.payload.accessToken,
                loggedInUser: user,
                isLoggedIn: true,
            };
        case USER_CONSTS.REFRESH_TOKEN:
            return {
                ...state,
                refreshToken: action.payload.refreshToken,
                accessToken: action.payload.accessToken,
            };
        case USER_CONSTS.GET_CURRENT_USER:
        case USER_CONSTS.UPDATE_CURRENT_USER:
            let profilePicture = action.payload?.profilePicturePath;
            profilePicture= profilePicture? {
                ...profilePicture,
                path: `${profilePicture.path}?date=${Date.now()}`
            } : null
            return {
                ...state,
                loggedInUser: {
                    ...action.payload,
                    profilePicturePath: profilePicture
                },
            };
        case USER_CONSTS.LOG_OUT:
            return {
                ...state,
                ...initialState,
            };
        default:
            return state;
    }
}
