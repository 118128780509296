import {useEffect, useState} from "react";

const useTimer = (start: number, timeOutCallback:any) => {
    const [counter, setCounter] = useState(start);
    useEffect(() => {
        if (counter === 0) {
            timeOutCallback && timeOutCallback()
            return;
        }
        setTimeout(() => {
            setCounter(counter - 1);
        }, 1000);
    }, [counter]);
    return counter;
};

export function Timer(props: any): JSX.Element {
    const {startTime,  timeOutCallback  } = props
    const timeLeft = useTimer(startTime,timeOutCallback);
    return <div>{timeLeft}</div>;
}
