import io from "socket.io-client";
import { store } from "../../redux/store";
import { NOTIFICATION_TYPES } from "../constants";
import { _urlSocketNotification } from "../../redux/api";
import {
    GetNonOpenedNotificationsCount,
} from "../emitters";
import { newNotification } from "../../redux/actions";
import { NOTIFICATION_CONSTS } from "../../redux/constants";

export const socketNotificationConnection = {
    _instance: null,
    get instance () {
        return this._instance;
    },
    set instance (instance) {
        this._instance = instance;
    },
    connectToSocket () {
        // console.log("start connect");

        if (store.getState().user.accessToken) {
            const conf = { 'reconnectionDelay': 5000, }
            if (process.env.REACT_APP_ENVIRONMENT === 'production') {
                conf['path'] = '/notification/socket.io'
            }
            this._instance = io.connect(_urlSocketNotification, conf);
            this._instance.on('connect', () => {
                const engine = this._instance.io.engine;
                // console.log("notification socket connected", engine.transport.name);
                let token = store.getState().user.accessToken;
                this._instance.emit(NOTIFICATION_TYPES.AUTHENTICATION, { 'x-access-token': token });
            });

            this._instance.on(NOTIFICATION_TYPES.AUTHENTICATED, async() => {
                // console.log('AUTHENTICATED');
                await store.dispatch({
                    type: NOTIFICATION_CONSTS.NOTIFICATION_SOCKET_AUTHENTICATED
                });
                GetNonOpenedNotificationsCount();
            });

            this._instance.on(NOTIFICATION_TYPES.UNAUTHORIZED, (error) => {
                // handle the error
                // console.log("UNAUTHORIZED");
                // console.log(`Authentication error: ${error.message}`);

                store.dispatch({
                    type: NOTIFICATION_CONSTS.NOTIFICATION_SOCKET_UNAUTHORIZED
                })
            });

            this._instance.on(NOTIFICATION_TYPES.NEW_NOTIFICATION, (data) => {
                // console.log('notification...', data);
                newNotification(data);
            });

            this._instance.on('disconnect', (reason) => {
                // console.log(reason);

                if (reason === 'io server disconnect') {
                    let token = store.getState().user.accessToken;
                    token && this._instance.connect();
                }
                // else the socket will automatically try to reconnect
            });
            this._instance.on('reconnect', (attemptNumber) => {
                //console.log('socket reconnected', attemptNumber);
            });
        }
    },
    disconnect () {
        if (this._instance) {
            // console.log('disconnected')
            this._instance.disconnect()
            this._instance = null
        }
    }
};
