import { USER_CONSTS, NOTIFICATION_CONSTS } from "../constants";

export const initialState = {
    notificationSocketConnected: false,
    notifications: {
        list: [],
        offset: 0,
        count: 0,
    },
    nonOpenedNotificationCount: 0,
};

export default (state = initialState, action) => {
    switch ( action.type ) {
        case NOTIFICATION_CONSTS.NOTIFICATION_SOCKET_AUTHENTICATED:
            return {
                ...state,
                notificationSocketConnected: true
            };
        case NOTIFICATION_CONSTS.NOTIFICATION_SOCKET_UNAUTHORIZED:
            return {
                ...state,
                notificationSocketConnected: false
            };
        case NOTIFICATION_CONSTS.NEW_NOTIFICATION:
            return {
                ...state,
                notifications: {
                    ...state.notifications,
                    list: state.notifications?.offset > 0
                        ? state.notifications.list
                        : [action.payload, ...state.notifications.list],
                    count: state.notifications.count + 1
                }
            };
        case NOTIFICATION_CONSTS.GET_NOTIFICATION_LIST:
            // console.log(action.payload, 'reducer');
            return {
                ...state,
                notifications: {
                    list: action.payload.data,
                    offset: action.payload.offset,
                    count: action.payload.count,
                }
            };
        case NOTIFICATION_CONSTS.GET_NON_OPENED_NOTIFICATIONS_COUNT:
            return {
                ...state,
                nonOpenedNotificationCount: action.payload
            };
        case NOTIFICATION_CONSTS.REMOVE_NOTIFICATION:
            return {
                ...state,
                notifications: {
                    list: state.notifications?.list?.filter(item => item.id !== action.payload),
                    count: state.notifications.count - 1,
                    offset: state.notifications.offset
                }
            };

        case NOTIFICATION_CONSTS.REMOVE_ALL_NOTIFICATIONS:
            return {
                ...state,
                notifications: {
                    list: [],
                    count: 0,
                    offset: 0
                }
            };

        case NOTIFICATION_CONSTS.SET_ALL_NOTIFICATIONS_OPENED:
            return {
                ...state,
                notifications: {
                    list: state.notifications?.list?.map(item => {
                        return {
                            ...item,
                            opened: true
                        }
                    }),
                    count: state.notifications.count,
                    offset: state.notifications.offset,
                },
                nonOpenedNotificationCount: 0
            };
        case USER_CONSTS.LOG_OUT:
            return {
                ...state,
                ...initialState,
            };
        default:
            return state;
    }
}
