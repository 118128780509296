// import packages
import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {Breadcrumb, Dropdown, Popover} from "antd";
import {Link, useNavigate, useParams} from "react-router-dom";
import moment from "moment";

// Import assets
import './post-details.scss';
import {
    CallIcon,
    CompareDarkIcon, CompareRedIcon, CopiedIcon, CopyIcon, fullScreen, fullScreen2,
    HeartDarkIcon,
    HeartIconDarkFill,
    PhoneIcon,
    ShareDarkIcon, ShareFacebookIcon, ShareLinkedinIcon, VisitIcon
} from "../../assets/images";

// Import utils
import {getPropsFromState} from "../../redux/mapStateToProps";
import {
    GetPostById,
    LikePost,
    UnLikePost,
    AddPostToComparedList,
    RemovePostFromComparedList,
    AddPhoneStatistics
} from "../../redux/actions";
import PostSlider from "./slider/PostSlider";
import {PHONE_TYPES, POST_TYPES, PRICE_TYPES} from "../../constants/constants";
import {MainButton} from "../../components/ui-elements/button/Buttons";

import {priceWithCommas} from "../../utils/helperFunctions";
import {FullscreenControl, Map, Placemark, YMaps} from "react-yandex-maps";
import BestOffersSection from "../../components/post-section/BestOffersSection";
import SimilarPostsSection from "../../components/post-section/SimilarPostsSection";
import {generateFileMediaUrl} from "../../utils/generateMediaUrl";
import {priceByCurrency} from "../../utils/priceByCurrency";
import {
    FacebookShareButton,
    LinkedinShareButton,
} from "react-share";
import {WEB_PAGE_URL} from "../../redux/api";
import {CopyToClipboard} from "react-copy-to-clipboard";
import VisitModal from "../../components/ui-elements/modal/VisitModal";

const PostDetails = (props) => {
    const {isLoggedIn, comparedPosts, selectedLanguage, translation, priceType} = props
    const params = useParams()
    // console.log(params.id)
    const navigate = useNavigate();

    const [post, setPost] = useState(null)
    const [ymap, setYmap] = useState(null)
    const [loading, setLoading] = useState(true)
    const [copyed, setCopyed] = useState(false);
    const [ymapFullScreenControl, setYmapFullScreenControl] = useState(null)
    const [mapFullscreenIcon, setMapFullscreenIcon] = useState(fullScreen)
    const [visible, setVisible] = useState(false);

    const isFetching = useRef(false)
    const compared = !!comparedPosts?.find((p) => p.id === post?.id);
    const shareUrl = `${WEB_PAGE_URL}/post/${post?.slug}?lg=${selectedLanguage?.code}`


    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        props.GetPostById(params.slug).then((data) => {
            setPost(data);
        }).finally(() => {
            setLoading(false)
        })
    }, [params.slug, selectedLanguage?.code])


    useEffect(() => {
        ymapFullScreenControl
        && ymapFullScreenControl.events.add('click', (event) => {
            setMapFullscreenIcon(event?.originalEvent?.target?._selected ? fullScreen : fullScreen2)
        })
    }, [ymapFullScreenControl])

    const toggleVisibility = () => {
        setVisible(!visible)
    }

    const items = [
        {
            label: post?.contacts?.map((item) => {
                return <a onClick={e => e.stopPropagation()}
                          className={"lang_menu_item"}
                          href={`tel:${item?.phone}`}>
                    {PHONE_TYPES.HOME.key === item.type ? <PhoneIcon/> : <CallIcon/>} {item.phone}
                </a>
            }),
            key: '1',
        },
        {
            type: 'divider',
        },
        {
            label: <div onClick={e => e.stopPropagation()}
                        className={"lang_menu_item"}>
                <div onClick={toggleVisibility}>
                    <VisitIcon/> {translation?.homepage_post_visit}
                </div>
            </div>,
            key: '2',
        },
    ]

    function toggleFavorite() {
        if (isLoggedIn) {
            if (!isFetching.current) {
                isFetching.current = true;
                const favoritePromise = post.isFavorite ? props.UnLikePost(post.id) : props.LikePost(post.id);
                favoritePromise.finally(() => {
                    isFetching.current = false;
                })
                setPost({
                    ...post,
                    isFavorite: !post.isFavorite
                })
            }
        } else {
            navigate('/auth/sign-in')
        }
    }

    function toggleCompare() {
        compared ? props.RemovePostFromComparedList(post?.id) : props.AddPostToComparedList(post)
    }

    function onPhoneDropDownVisibleChanged(visible) {
        // console.log(ymap)
        visible && props.AddPhoneStatistics(post?.id)
    }

    const shareContent = (
        <div className={'social-media'}>
            {copyed ?
                <div className={'copied-link'} onClick={e => {
                    e.stopPropagation()
                }}>
                    <CopiedIcon/> {translation?.link_copied}
                </div> :
                <CopyToClipboard text={shareUrl}
                                 onCopy={() => setCopyed(true)}>
                    <div onClick={e => {
                        e.stopPropagation()
                    }}
                         className={'copy-share'}
                    >
                        <CopyIcon/> {translation?.link_copy}
                    </div>
                </CopyToClipboard>
            }
            <FacebookShareButton url={shareUrl} quote={"A&P"}>
                <p><ShareFacebookIcon size={18} round={true}/> Facebook</p>
            </FacebookShareButton>
            <LinkedinShareButton url={shareUrl} title={'A&P'}>
                <p><ShareLinkedinIcon size={18} round={true}/> Linkedin</p>
            </LinkedinShareButton>
        </div>
    );

    function toggleShare() {
        setCopyed(false)
    }

    return <div className='post-details'>
        <div className="post-details__header">
            <Breadcrumb>
                <Breadcrumb.Item><Link to={'/'}>{translation?.post_linkHome_title}</Link></Breadcrumb.Item>
                <Breadcrumb.Item>ID:{post?.postNumber}</Breadcrumb.Item>
            </Breadcrumb>

            <div className="post-details__header__actions">
                {compared ? <CompareRedIcon className="action_btn" onClick={toggleCompare}/> :
                    <CompareDarkIcon className="action_btn" onClick={toggleCompare}/>}
                {post?.isFavorite ? <HeartIconDarkFill onClick={toggleFavorite} className="action_btn"/> :
                    <HeartDarkIcon onClick={toggleFavorite} className="action_btn"/>}
                <Popover content={shareContent} trigger="click" placement="bottom">
                    <ShareDarkIcon className="action_btn" onClick={toggleShare}/>
                </Popover>
            </div>
        </div>
        <div className="content">
            <div className="content__left">
                <div className={'mobile-block-wrapper'}>
                    <div className={'mobile-header'}>
                        <div className={'badges'}>
                            {post?.isUrgent && <div className={'urgent'}>{translation?.filter_urgent}</div>}
                            {post?.isSpecialOffer && <div className={'top'}>Top</div>}
                            {post?.isNew && <div className={'new_badgs'}>{translation?.homepage_post_new}</div>}
                            {/*<div className="is_top"></div>*/}
                        </div>
                        <div className="header_right">
                            <div className="updated">
                                <span>{translation?.post_updated_text}: </span>{moment(post?.updetedAt).format("DD.MM.YYYY")}
                            </div>
                            <div className="id">ID: {post?.postNumber}</div>

                        </div>
                    </div>
                    <div className="post_title">{post?.title}</div>
                    <div className={`${priceType === POST_TYPES.RENT.key  ? 'prices-block' : ''}`}>
                        {post?.type.map((type) => {
                            if (type === POST_TYPES.SELL.key) {
                                return <div className={`${priceType === POST_TYPES.SELL.key ? "post_price" : "price"}`}>
                                    {post?.priceSell ?
                                        priceWithCommas(priceByCurrency(post.priceSell, post.currency)) :
                                        <div className={'contractual'}>{translation?.homepage_post_price} :<span>{translation?.homepage_post_price_text}</span>
                                        </div>}
                                </div>
                            }
                        })}
                        {post?.type.map((type) => {
                            if (type === POST_TYPES.RENT.key) {
                                return <div className={`${priceType === POST_TYPES.RENT.key ? "post_price" : "price"}`}>
                                    {post?.priceRent ?
                                        priceWithCommas(priceByCurrency(post.priceRent, post.currency)) :
                                        <div className={'contractual'}>{translation?.homepage_post_price} :<span>{translation?.homepage_post_price_text}</span></div>}
                                    {post?.priceRent ? post?.rentPriceType === PRICE_TYPES.MONTHLY ?
                                        <span className={'rent'}> / {translation?.homepage_post_priceType_mount}</span> :
                                        <span className={'rent'}> / {translation?.homepage_post_priceType_day}</span> : ""
                                    }
                                </div>
                            }
                        })
                        }
                    </div>
                    {post?.address &&
                        <div className="post_address" onClick={() => {
                            ymapFullScreenControl?.enterFullscreen?.();
                            setMapFullscreenIcon(fullScreen2)
                        }}>
                            {post.address}
                        </div>}

                    <Dropdown menu={{items}} trigger={['click']} onVisibleChange={onPhoneDropDownVisibleChanged}>
                        <a onClick={e => e.stopPropagation()}>
                            <MainButton title={translation?.post_showNumber_btn} className="post_numbers"/>
                        </a>
                    </Dropdown>

                </div>
                <PostSlider medias={post?.medias} loading={loading} post={post}/>

                <div className={'mobile-details-wrapper'}>
                    <div className="details">{translation?.post_details_title}</div>
                    <div className="details_content">
                        {post?.roomsCount && <div className="item">
                            <span className="item_title">{post?.roomsCount}</span>
                            <span className="item_desc">{translation?.filter_room_label}</span>
                        </div>}
                        {post?.area && <div className="item">
                            <span
                                className="item_title">{post?.area} {translation?.filter_measurement_unit_meter}<span>2</span></span>
                            <span className="item_desc">{translation?.post_area}</span>
                        </div>}
                        {post?.floorNumber && <div className="item">
                            {post?.buildingFloorsCount ?
                                <span className="item_title">{post?.floorNumber} / {post?.buildingFloorsCount}</span>
                                : <span className="item_title">{post?.floorNumber}</span>
                            }
                            <span className="item_desc">{translation?.filter_floor_label}</span>
                        </div>}
                        {post?.floorNumber === null && <div className="item">
                            <span className="item_title">{post?.buildingFloorsCount}</span>
                            <span className="item_desc">{translation?.filter_floor_label}</span>
                        </div>}
                        {post?.bathroomsCount && <div className="item">
                            <span className="item_title">{post?.bathroomsCount}</span>
                            <span className="item_desc">{translation?.filter_bathrooms_label}</span>
                        </div>
                        }
                    </div>
                </div>

                {post?.details &&
                    <div className="content__left__details" dangerouslySetInnerHTML={{__html: post?.details}}/>}


                {!!post?.attributes?.length && <>
                    <div className="content__left__advantages">{translation?.post_advantages_title}</div>
                    <div className="content__left__advantages_list">
                        {
                            post.attributes.map(atr => {
                                return <div key={atr.id} className="list_item">
                                    <img src={generateFileMediaUrl(atr?.icon?.path)} alt={""}/>
                                    <span>{atr.title}</span>
                                </div>
                            })
                        }
                    </div>
                </>}
            </div>
            <div className="content__right">
                <div className="content__right__info">
                    <div className="post_info_header">
                        <div className={'badges'}>
                            {post?.isUrgent && <div className={'urgent'}>{translation?.filter_urgent}</div>}
                            {post?.isSpecialOffer &&
                                <div className={'top'}>{translation?.homepage_post_special_offer}</div>}
                            {post?.isNew && <div className={'new_badgs'}>{translation?.homepage_post_new}</div>}
                            {/*<div className="is_top"></div>*/}
                        </div>
                        <div className="header_right">
                            <div className="updated">
                                <span>{translation?.post_updated_text}: </span>{moment(post?.updetedAt).format("DD.MM.YYYY")}
                            </div>
                            <div className="id">ID: {post?.postNumber}</div>

                        </div>
                    </div>
                    <div className="post_title">{post?.title}</div>
                    <div className={`${priceType === POST_TYPES.RENT.key  ? 'prices-block' : ''}`}>
                        {post?.type.map((type) => {
                            if (type === POST_TYPES.SELL.key) {
                                return <div className={`${priceType === POST_TYPES.SELL.key ? "post_price" : "price"}`}>
                                    {post?.priceSell ?
                                        priceWithCommas(priceByCurrency(post.priceSell, post.currency)) :
                                        <div className={'contractual'}>{translation?.homepage_post_price} :<span>{translation?.homepage_post_price_text}</span>
                                        </div>}
                                </div>
                            }
                        })}
                        {post?.type.map((type) => {
                            if (type === POST_TYPES.RENT.key) {
                                return <div className={`${priceType === POST_TYPES.RENT.key ? "post_price" : "price"}`}>
                                    {post?.priceRent ?
                                        priceWithCommas(priceByCurrency(post.priceRent, post.currency)) :
                                        <div className={'contractual'}>{translation?.homepage_post_price} :<span>{translation?.homepage_post_price_text}</span>
                                        </div>}
                                    {post?.priceRent ? post?.rentPriceType === PRICE_TYPES.MONTHLY ?
                                        <span className={'rent'}> / {translation?.homepage_post_priceType_mount}</span> :
                                        <span className={'rent'}> / {translation?.homepage_post_priceType_day}</span> : ""
                                    }
                                </div>
                            }
                        })
                        }
                    </div>
                    {post?.address &&
                        <div className="post_address" onClick={() => {
                            ymapFullScreenControl?.enterFullscreen?.();
                            setMapFullscreenIcon(fullScreen2)
                        }}>
                            {post.address}
                        </div>}

                    <Dropdown menu={{items}} trigger={['click']} onVisibleChange={onPhoneDropDownVisibleChanged}>
                        <a onClick={e => e.stopPropagation()}>
                            <MainButton title={translation?.post_showNumber_btn} className="post_numbers"/>
                        </a>
                    </Dropdown>

                    <div className="details">{translation?.post_details_title}</div>
                    <div className="details_content">
                        {post?.roomsCount && <div className="item">
                            <span className="item_title">{post?.roomsCount}</span>
                            <span className="item_desc">{translation?.filter_room_label}</span>
                        </div>}
                        {post?.area && <div className="item">
                            <span
                                className="item_title">{post?.area} {translation?.filter_measurement_unit_meter}<span>2</span></span>
                            <span className="item_desc">{translation?.post_area}</span>
                        </div>}
                        {post?.floorNumber && <div className="item">
                            {post.buildingFloorsCount ?
                                <span className="item_title">{post?.floorNumber} / {post?.buildingFloorsCount}</span>
                                : <span className="item_number">{post?.floorNumber}</span>
                            }
                            <span className="item_desc">{translation?.filter_floor_label}</span>
                        </div>}
                        {post?.floorNumber === null && <div className="item">
                            <span className="item_title">{post?.buildingFloorsCount}</span>
                            <span className="item_desc">{translation?.filter_floor_label}</span>
                        </div>}
                        {post?.bathroomsCount && <div className="item">
                            <span className="item_title">{post?.bathroomsCount}</span>
                            <span className="item_desc">{translation?.filter_bathrooms_label}</span>
                        </div>
                        }
                    </div>
                </div>
                <div className="content__right__location">
                    <div className="content__right__location__title">{translation?.post_location_title}</div>
                    <div className="content__right__location__map">
                        {post?.id && <YMaps query={{apikey: '75a37665-fab6-4ccb-935a-bce1e1913cd1', lang: `en`}}>
                            <Map
                                state={{
                                    center: [post?.location?.latitude, post?.location?.longitude],
                                    zoom: 13,
                                    hue: "ff0000",
                                }}
                                onLoad={(ympasInstance) => {
                                    setYmap(ympasInstance)
                                }}
                                options={{
                                    minZoom: 5
                                }}
                                modules={["Placemark", "geocode", "geoObject.addon.hint", "templateLayoutFactory", "layout.ImageWithContent"]}
                                width={'100%'} height={'100%'}
                            >
                                <Placemark
                                    geometry={[post?.location?.latitude, post?.location?.longitude]}
                                />
                                {ymap &&
                                    <FullscreenControl
                                        instanceRef={(ref) => {
                                            setYmapFullScreenControl(ref)
                                        }}
                                        options={{
                                            position: {
                                                bottom: 40,
                                                right: 20
                                            }
                                        }}
                                        data={{image: mapFullscreenIcon}}
                                    />}
                            </Map>
                        </YMaps>}
                    </div>

                </div>

                {!!post?.filters?.length && <div className="content__right__categories">
                    {post.filters.map(filter => {
                        return <div className="content__right__categories__item" key={filter?.id}>
                            <span className="key">{filter?.title}</span>
                            <span className="line"/>
                            {filter?.values?.length ? filter.values.map((value, index) => {
                                    return <React.Fragment key={value.id}>
                                        <span className="value">
                                            {value?.title}
                                        </span>
                                        {index + 1 !== filter.values.length && <span className="dot">/</span>}
                                    </React.Fragment>
                                }) :
                                <span className="value">-</span>}

                        </div>
                    })}
                </div>}
                <div className={'mobile-advantages-wrapper'}>
                    {!!post?.attributes?.length && <>
                        <div className="advantages_title">{translation?.post_advantages_title}</div>
                        <div className="advantages_list">
                            {
                                post.attributes.map(atr => {
                                    return <div key={atr.id} className="list_item">
                                        <img src={generateFileMediaUrl(atr?.icon?.path)} alt={""}/>
                                        <span>{atr.title}</span>
                                    </div>
                                })
                            }
                        </div>
                    </>}
                </div>
            </div>
        </div>
        {post?.category?.id && <SimilarPostsSection categoryId={post.category.id} post={post}/>}
        <BestOffersSection
            seeAllButton={false}/>
        <VisitModal visible={visible}
                    post={post}
                    toggleVisibility={toggleVisibility}/>
    </div>
}

const mapStateToProps = (state) => getPropsFromState(state, [
    'translation',
    'categories',
    'isLoggedIn',
    'comparedPosts',
    'selectedCurrency',
    'selectedLanguage',
    'priceType'
]);


const mapDispatchToProps = {
    //redux actions
    GetPostById,
    LikePost,
    UnLikePost,
    AddPostToComparedList,
    RemovePostFromComparedList,
    AddPhoneStatistics
};

export default connect(mapStateToProps, mapDispatchToProps)(PostDetails);








