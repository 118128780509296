import {store} from "../redux/store";
import { CURRENCIES,} from "../constants/constants";

export function priceByCurrency(price, currency, suffix = true) {
    const state= store?.getState()?.general
    const selectedCurrency= state?.selectedCurrency
    const rates= state?.ratesList
    let _price = price
    if(currency !== selectedCurrency){
         _price = price * rates?.find(r=>r.currency === `${currency}_${selectedCurrency}`)?.rate
    }
    if(!_price){
        return price
    }
    _price = Math.floor(_price * 100) / 100
    if(suffix){
        return selectedCurrency=== CURRENCIES.USD.key ? `${CURRENCIES[selectedCurrency].suffix}${_price}` :  `${_price} ${CURRENCIES[selectedCurrency].suffix}`
    }
    return _price
}
