import {UTIL_CONSTS} from "../constants";
import {store} from "../store";
import {PageOffsetType, WindowSizesType} from "../reducers/utils";

export const ToggleLeftSideMenu = () => {
    return (dispatch: any) => {
        dispatch({
            type: UTIL_CONSTS.TOGGLE_LEFT_SIDE_MENU
        })
    }
};

export const setPageOffset = (data: PageOffsetType | null = null) => {
    store.dispatch({
        type: UTIL_CONSTS.SET_PAGE_OFFSET,
        payload: data || {
            pageName: '',
            offset: 0
        }
    })
};

export const setWindowSizes = (data: WindowSizesType) => {
    store.dispatch({
        type: UTIL_CONSTS.SET_WINDOW_SIZES,
        payload: data
    })
};
