import isEmail from "validator/es/lib/isEmail";

export const validateAuth = loginCreds => {
    const errors = {
        username: false,
        password: false
    };
    let result = true;
    const { username, password } = loginCreds;
    // console.log(isEmail(username));
    if (username === "" || (!!username.length && !isEmail(username))) {
        errors.username = true;
        result = false;
    }
    if (!password.length || password.length < 8) {
        errors.password = true;
        result = false;
    }

    return { errors, result };
};
export const validateSignUpData = (loginCreds, errors) => {
    let result = true;
    Object.keys(errors).map((field) => {
        if (field === "username" && (loginCreds[field] === "" ||
            (!!loginCreds[field].length && !isEmail(loginCreds[field])))) {
            errors.username = true;
            result = false;
        }
        if (field === "password" && (loginCreds[field].length < 8 || loginCreds[field].length > 20)) {
            errors.password = true;
            result = false;
        }
        if (field === "firstName" && !loginCreds[field].length) {
            errors.firstName = true;
            result = false;
        }
        if (field === "acceptPrivacyPolicy" && !loginCreds[field]) {
            errors.acceptPrivacyPolicy = true;
            result = false;
        }
        if (field === "repeatPassword" && loginCreds.repeatPassword !== loginCreds.password) {
            errors.repeatPassword = true;
            result = false;
        }
    });
    if (loginCreds.day || loginCreds.month || loginCreds.year) {
        if (!loginCreds.day || !loginCreds.month || !loginCreds.year) {
            errors.day = !loginCreds.day
            errors.month = !loginCreds.month
            errors.year = !loginCreds.year
            result = false
        }
        const birthday = new Date(`${loginCreds.year}-${loginCreds.month}-${loginCreds.day}`)
        const currentDate = new Date();
        if (birthday > currentDate) {
            result = false
            errors.day = loginCreds.day > currentDate.getDate()
            errors.month = loginCreds.month > currentDate.getMonth() + 1
        }
    }
    return result;

};

export const validateData = (err, data) => {
    let errors = {};
    let result = true;
    Object.keys(err).forEach(item => {
        //console.log(typeof(err[item]),'subResult');
        if (typeof (err[item]) === "object" && err[item] !== null) {
            const subResult = validateData(err[item], data[item]);
            //console.log(subResult,'subResult');
            errors[item] = { ...subResult.errors };
            if (!subResult.result) {
                result = false;
            }
        } else if (!data[item] || (data[item] && data[item].length === 0) || data[item] === "" || data[item] === 0) {
            errors[item] = true;
            result = false;
        } else {
            errors[item] = false;
        }
    });
    return { result, errors };
};
