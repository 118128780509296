import {GENERAL_CONSTS} from "../constants";
import {CategoriesItemType} from "../actions/actionTypes";
import {LanguageType} from "../../types/types";
import {CURRENCY_ENUM} from "../../constants/constants";

export type GeneralStateType = {
    languages: Array<Object>,
    selectedLanguage: LanguageType | null ,
    translation: any,
    staticTexts: any,
    priceType: any,
    selectedCurrency: CURRENCY_ENUM,

    information: object,
    banners: Array<Object>,
    categories: Array<CategoriesItemType>,
    mainSlider: Array<Object>,
    filtersList: Array<Object>,
    ratesList: Array<Object>,
    faqList:Array<Object>,
    regionsList:Array<Object>,
    residencesList:Array<Object>,
};

export const initialState: GeneralStateType = {
    languages: [],
    selectedLanguage: null,
    translation: {},
    staticTexts: {},
    selectedCurrency: CURRENCY_ENUM.AMD,

    information: {},
    filtersList: [],
    categories: [],
    banners: [],
    mainSlider: [],
    ratesList: [],
    faqList: [],
    regionsList: [],
    residencesList: [],
    priceType:null,
};

export default (state: GeneralStateType = initialState, action: any): GeneralStateType => {
    switch (action.type) {
        case GENERAL_CONSTS.GET_LANGUAGES:
            const mainLanguage = action.payload.find((lg: any) => lg.isMain);
            const selectedLanguageValid = state?.selectedLanguage?.code
                && action.payload.find((lg: any) => lg.code === state.selectedLanguage?.code);
            const newSelectedLanguage = selectedLanguageValid ? selectedLanguageValid :
                (mainLanguage ? mainLanguage : action.payload?.[0]);
            const newState = {
                ...state,
                languages: action.payload,
                selectedLanguage: newSelectedLanguage,
            };
            if (state.staticTexts && state.staticTexts?.[newSelectedLanguage?.code]) {
                newState.translation = state.staticTexts?.[newSelectedLanguage?.code]
            }
            return newState;
        case GENERAL_CONSTS.CHANGE_LANGUAGE:
            return {
                ...state,
                selectedLanguage: action.payload,
                translation: state.staticTexts?.[action.payload.code]
            };
        case GENERAL_CONSTS.GET_STATIC_TEXTS:
            return {
                ...state,
                staticTexts: action.payload
            };
        case GENERAL_CONSTS.GET_FILTERS:
            return {
                ...state,
                filtersList: action.payload
            };
        case GENERAL_CONSTS.GET_CATEGORIES:
            return {
                ...state,
                categories: action.payload,
            };
        case GENERAL_CONSTS.GET_BANNERS:
            return {
                ...state,
                banners: action.payload,
            };
        case GENERAL_CONSTS.GET_RATES:
            return {
                ...state,
                ratesList: action.payload,
            };
        case GENERAL_CONSTS.CHANGE_CURRENCY:
            return {
                ...state,
                selectedCurrency: action.payload,
            };
        case GENERAL_CONSTS.GET_SLIDER:
            return {
                ...state,
                mainSlider: action.payload
            };
        case GENERAL_CONSTS.GET_INFORMATION:
            return {
                ...state,
                information: action.payload
            };
        case GENERAL_CONSTS.GET_FAQ:
            return {
                ...state,
                faqList: action.payload
            };
        case GENERAL_CONSTS.GET_REGIONS:
            return {
                ...state,
                regionsList: action.payload
            };
        case GENERAL_CONSTS.GET_RESIDENCES:
            return {
                ...state,
                residencesList: action.payload
            };
        case GENERAL_CONSTS.PRICE_TYPE:
            return {
                ...state,
                priceType: action.payload
            };
        default:
            return state;
    }
}
