export const NOTIFICATION_CONSTS = {
    NEW_NOTIFICATION:"NEW_NOTIFICATION",
    NOTIFICATION_SOCKET_AUTHENTICATED:"NOTIFICATION_SOCKET_AUTHENTICATED",
    NOTIFICATION_SOCKET_UNAUTHORIZED:"NOTIFICATION_SOCKET_UNAUTHORIZED",

    GET_NON_OPENED_NOTIFICATIONS_COUNT: "GET_NON_OPENED_NOTIFICATIONS_COUNT",
    SET_ALL_NOTIFICATIONS_OPENED: "SET_ALL_NOTIFICATIONS_OPENED",
    REMOVE_ALL_NOTIFICATIONS: "REMOVE_ALL_NOTIFICATIONS",
    REMOVE_NOTIFICATION: "REMOVE_NOTIFICATION",
    GET_NOTIFICATION_LIST: "GET_NOTIFICATION_LIST",
};
