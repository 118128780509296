export const NOTIFICATION_TYPES = {
    // emit types
    AUTHENTICATION: "authentication",
    AUTHENTICATED: "authenticated",
    UNAUTHORIZED: "unauthorized",

    GET_NON_OPENED_NOTIFICATIONS_COUNT: "non-opened-notifications-count",
    SET_ALL_NOTIFICATIONS_OPENED: "set-all-notifications-opened",
    SET_NOTIFICATION_OPENED: 'set-notification-opened',
    REMOVE_ALL_NOTIFICATIONS: "remove-all-notifications",
    REMOVE_NOTIFICATION: "remove-notification",


    //listener types
    NEW_NOTIFICATION: "new-notification",
};
