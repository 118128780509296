// Import packages
import React, {useEffect, useRef} from "react";
import {connect} from "react-redux";
import {useLocation} from "react-router-dom";
import {socketNotificationConnection} from "../../socket/api/socketNotificationConnection";

// Import assets
import './layout.scss'

// Import components
import InfoHeader from "../../components/header/info-header/InfoHeader";
import MainHeader from "../../components/header/main-header/MainHeader";
import Footer from "../../components/footer/Footer";

// Import utils
import {
    GetCategories,
    GetPostsInitialInfo,
    GetPostsInfo,
    GetBestOffers,
    GetRates,
    GetSlider,
   GetRegionList,
    GetBanners,
    GetInformation,
    GetLanguages,
    GetStaticTexts,
    GetRecentlyViewedPostsByIds,
    LogOut,
    TokenValidation,
    GetNotifications,
    GetCurrentUser,
    GetContact,
    GetFaqList,
    setWindowSizes, GetSimilarPosts,
} from "../../redux/actions";
import {getPropsFromState} from "../../redux/mapStateToProps";
import {debounce} from "../../utils/debounce";
import VisitModal from "../../components/ui-elements/modal/VisitModal";

const Layout: React.FC = (props: any) => {
    const {children, selectedCurrency, selectedLanguage, isLoggedIn, loggedInUser, recentlyViewed} = props
    const location = useLocation()
    const currentCurrency = useRef(selectedCurrency)
    const languageSelected = useRef(false)
    const loggedInState = useRef(isLoggedIn)

    //hooks
    useEffect(() => {
        props.GetStaticTexts().then(async () => {
            await props.GetLanguages();
            languageSelected.current = true
        })
        props.GetSlider()
        props.GetRegionList()
        props.GetPostsInitialInfo(selectedCurrency)
        props.GetCategories()
        props.GetRates()
        props.GetBanners()
        props.GetContact()
        props.GetInformation()
    }, [])

    useEffect(() => { //currency changed
        if (selectedCurrency !== currentCurrency.current) {
            props.GetPostsInitialInfo(selectedCurrency)
            currentCurrency.current = selectedCurrency
        }
    }, [selectedCurrency])

    useEffect(() => { //language changed
        if (languageSelected.current) {
            props.GetSlider()
            props.GetRegionList()
            props.GetCategories()
            props.GetBanners()
            props.GetInformation()
            props.GetContact()
            props.GetBestOffers()
            if (recentlyViewed?.length) {
                const recentlyViewedPostsIds = recentlyViewed.map((p: any) => p.id)
                props.GetRecentlyViewedPostsByIds(recentlyViewedPostsIds)
            }
            location.pathname === '/faq' && props.GetFaqList()
        }
    }, [selectedLanguage?.code])

    useEffect(() => { //loggedIn states
        if ((isLoggedIn !== loggedInState.current) && isLoggedIn) {
            //skip first effect and handle if user is logged in.
            if (recentlyViewed?.length) {
                const recentlyViewedPostsIds = recentlyViewed.map((p: any) => p.id)
                props.GetRecentlyViewedPostsByIds(recentlyViewedPostsIds)
            }
        }
        loggedInState.current = isLoggedIn

        if (isLoggedIn) {
            props.TokenValidation().then(() => {
                // console.log('token valid')
                if (!socketNotificationConnection.instance) {
                    socketNotificationConnection.connectToSocket();
                }
                props.GetCurrentUser()
                props.GetNotifications()
            })
        } else {
            // console.log('log - out', !!socketNotificationConnection._instance)
            socketNotificationConnection.disconnect();
        }
    }, [isLoggedIn])

    useEffect(() => {
        //handle window resize
        const debouncedHandleResize = debounce(() => {
            setWindowSizes({
                height: window.innerHeight,
                width: window.innerWidth
            })
        }, 100)

        window.addEventListener('resize', debouncedHandleResize)
        return () => {
            window.removeEventListener('resize', debouncedHandleResize)
        }
    }, [])

    //console.log('isLoggedIn - ', isLoggedIn, loggedInState.current)

    const hide = location.pathname.includes('/auth')
    return <div className={`layout_wrapper`}>
        <InfoHeader/>
        {!hide && <MainHeader isLoggedIn={isLoggedIn}
                                    loggedInUser={loggedInUser}
                                    LogOut={props.LogOut}/>}
        <div className={`layout_wrapper__content`}>
            {children}
        </div>
        {!hide && <Footer/>}
    </div>
}
const mapDispatchToProps = {
    GetLanguages,
    GetStaticTexts,
    GetCategories,
    GetPostsInitialInfo,
    GetPostsInfo,
    GetBestOffers,
    GetRates,
    GetBanners,
    GetSlider,
    GetInformation,
    GetRecentlyViewedPostsByIds,
    TokenValidation,
    GetNotifications,
    GetCurrentUser,
    GetContact,
    GetFaqList,
    setWindowSizes,
    LogOut,
   GetRegionList
};

const mapStateToProps = (state: any) => {
    return getPropsFromState(state, [
        'loggedInUser',
        'isLoggedIn',
        'selectedCurrency',
        'recentlyViewed',
        'notificationsList',
        'selectedLanguage',
    ])
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
