import React, {useEffect, useRef} from "react";
import styled from "styled-components";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import {
    HeartDarkIcon,
    CompareDarkIcon,
    ShareDarkIcon,
    PhoneIcon,
    PhoneWhiteIcon,
    CallIcon,
    HeartIconDarkFill,
    CompareRedIcon,
    Card_Map,
    MapCallIcon,
    CopyIcon,
    ShareLinkedinIcon,
    ShareFacebookIcon,
    CopiedIcon,
    VisitIcon
} from "../../../assets/images";
import {MainButton} from "../../ui-elements/button/Buttons";
import {generateImageMediaUrl} from "../../../utils/generateMediaUrl";
import {Dropdown, MenuProps, Popover} from "antd";
import {CONDITION_TYPES, CURRENCY_ENUM, PHONE_TYPES, POST_TYPES, PRICE_TYPES} from "../../../constants/constants";
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {connect} from "react-redux";
import {
    UnLikePost,
    LikePost,
    AddPostToComparedList,
    RemovePostFromComparedList,
    AddPhoneStatistics
} from "../../../redux/actions";
import {priceByCurrency} from "../../../utils/priceByCurrency";
import {PostItemType} from "../../../redux/actions/actionTypes";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {FacebookShareButton, LinkedinShareButton} from "react-share";
import {WEB_PAGE_URL} from "../../../redux/api";
import {priceWithCommas} from "../../../utils/helperFunctions";
import VisitModal from "../../ui-elements/modal/VisitModal";
import {Link} from 'react-router-dom'
import './productCardList.scss'

const ProductImage = styled.div`
  width: 210px;

  .img_wrapper {
    width: 100%;
    padding-top: 100%;
    position: relative;
    overflow: hidden;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 1s;
      display: block;

      &:hover {
        transform: scale(1.2);
      }
    }

    .icons_wrapper {
      display: none;
      flex-direction: column;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
    }

    .badges {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .urgent {
        width: 66px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #B79B7A;
        color: white;
        height: 33px;
        padding: 0 6px;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .top {
        width: 66px;
        height: 33px;
        min-width: 33px;
        padding: 0 6px;
        background-color: #CDBB99;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
      }
    }
  }

  @media all and (max-width: 1300px) {
    width: 190px;
  }

  @media all and (max-width: 1050px) {
    width: 150px;
    .img_wrapper {
      .icons_wrapper {
        display: flex;
      }
    }
  }

  @media all and (max-width: 450px) {
    width: 130px;
  }

`;

const Icon = styled.button`
  width: 32px;
  height: 32px;
  backdrop-filter: blur(2px);
  background-color: transparent;
  overflow: hidden;
  position: relative;
  margin-bottom: 1px;

  svg {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
  }
`;


type MapDispatchPropsType = {
    LikePost: (id: string) => Promise<any>
    UnLikePost: (id: string) => Promise<any>
    AddPostToComparedList: (post: PostItemType) => void
    RemovePostFromComparedList: (id: string) => void
    AddPhoneStatistics: (id: string) => void
}

type MapStatePropsType = {
    isLoggedIn?: boolean
    staticTexts?: any
    comparedPosts?: [PostItemType]
    requestLoading?: boolean
    selectedCurrency?: CURRENCY_ENUM
    type?: any;
}

type Props = MapStatePropsType & MapDispatchPropsType & {
    className?: string;
    bordered?: boolean;
    isLoggedIn?: boolean;
    data?: any;
}

const ProductCardList: React.FC<Props> = (props: any) => {
    const {className, isLoggedIn, data, comparedPosts, translation, selectedLanguage, type} = props

    const navigate = useNavigate();
    const [copyed, setCopyed] = React.useState<boolean>(false);
    const isFetching = useRef(false)
    const [visible, setVisible] = React.useState<boolean>(false);
    const compared = !!comparedPosts?.find((p: any) => p.id === data?.id);
    const shareUrl = `${WEB_PAGE_URL}/post/${data?.slug}?lg=${selectedLanguage?.code}`

    const toggleVisibility = () => {
        setVisible(!visible)
    }

    const items: MenuProps['items'] = [
        {
            label: data?.contacts?.map((item: any) => {
                return <a onClick={e => e.stopPropagation()}
                          className={"lang_menu_item"}
                          href={`tel:${item?.phone}`}>
                    {PHONE_TYPES.HOME.key === item.type ? <PhoneIcon/> : <CallIcon/>} {item.phone}
                </a>
            }),
            key: '1',
        },
        {
            type: 'divider',
        },
        {
            label: <div onClick={e => e.stopPropagation()}
                        className={"lang_menu_item"}>
                <div onClick={toggleVisibility}>
                    <VisitIcon/> {translation?.homepage_post_visit}
                </div>
            </div>,
            key: '2',
        },
    ]


    function toggleFavorite(e: any) {
        e && e.preventDefault();
        e && e.stopPropagation();
        if (isLoggedIn) {
            if (!isFetching.current) {
                isFetching.current = true;
                const favoritePromise = data?.isFavorite ? props.UnLikePost(data?.id) : props.LikePost(data?.id);
                favoritePromise.finally(() => {
                    isFetching.current = false;
                })
            }
        } else {
            navigate('/auth/sign-in')
        }
    }

    const shareContent = (
        <div className={'social-media'}>
            {copyed ?
                <div className={'copied-link'} onClick={e => {
                    e.stopPropagation()
                }}>
                    <CopiedIcon/> {translation?.link_copied}
                </div> :
                <CopyToClipboard text={shareUrl}
                                 onCopy={() => setCopyed(true)}>
                    <div onClick={e => {
                        e.stopPropagation()
                    }}
                         className={'copy-share'}
                    >
                        <CopyIcon/> {translation?.link_copy}
                    </div>
                </CopyToClipboard>
            }
            <FacebookShareButton url={shareUrl} quote={"A&P"}>
                <p><ShareFacebookIcon size={18} round={true}/> Facebook</p>
            </FacebookShareButton>
            <LinkedinShareButton url={shareUrl} title={'A&P'}>
                <p><ShareLinkedinIcon size={18} round={true}/> Linkedin</p>
            </LinkedinShareButton>
        </div>
    );

    function toggleCompare(e: any) {
        e && e.preventDefault();
        e && e.stopPropagation();
        // console.log('compared', compared);
        compared ? props.RemovePostFromComparedList(data?.id) : props.AddPostToComparedList(data)
    }

    function onPhoneDropDownVisibleChanged(visible: boolean) {
        // console.log('onPhoneDropDownClick')
        visible && props.AddPhoneStatistics(data?.id)
    }

    function toggleShare(e: any) {
        e && e.preventDefault();
        e && e.stopPropagation();
        setCopyed(false)
    }


    return (
        <Link className={`productCardList-wrapper ${className}`}
              to={`/post/${data.slug}`}>
            <ProductImage>
                <div className="img_wrapper">
                    <div className={'badges'}>
                        {data?.isUrgent && <div className={'urgent'}>{translation?.filter_urgent}</div>}
                        {data?.isSpecialOffer &&
                            <div className={'top'}>{translation?.homepage_post_special_offer}</div>}
                        {data?.isNew && <div className={'new_badgs'}>{translation?.homepage_post_new}</div>}
                    </div>
                    <img src={generateImageMediaUrl(data?.mediaMain?.path)} alt=""/>
                </div>
            </ProductImage>
            <div className="post_content">
                <div className="content_top">
                    <div className="title_wrapper">
                        <div className="title">{data?.title}</div>
                        <div className="information">
                            {data?.condition && Object.values(CONDITION_TYPES).map(key => {
                                if (data?.condition === key) {
                                    return <span>{translation[`cards_condition_text_${key}`]}</span>
                                }
                            })}
                            {data?.condition && <span> • </span>}
                            {data?.floorNumber && <span>{data?.floorNumber}{translation?.cards_floorNumber_text}</span>}
                            {data.buildingFloorsCount && data?.floorNumber && <span> • </span>}
                            {data.buildingFloorsCount &&
                                <span>{data.buildingFloorsCount} {translation?.cards_buildingFloors_text}</span>}
                            {data.buildingFloorsCount && data?.roomsCount && <span> • </span>}
                            {data?.roomsCount && <span> {data?.roomsCount} {translation?.cards_roomsCount_text}</span>}
                        </div>
                    </div>

                    <div className={`${type === POST_TYPES.RENT.key ? 'price-block' : 'price_wrapper'}`}>
                        {data?.type.map((item: any) => {
                            if (item === POST_TYPES.SELL.key) {
                                return <div
                                    className={`${type === POST_TYPES.SELL.key ? 'price' : 'rentPrice'}`}>{data?.priceSell ?
                                    priceWithCommas(priceByCurrency(data.priceSell, data.currency)) :
                                    <div className={'contractual'}>
                                        {translation?.homepage_post_price} :<span>{translation?.homepage_post_price_text}</span>
                                    </div>}
                                </div>
                            }
                            if (item === POST_TYPES.RENT.key) {
                                return <div
                                    className={`${type === POST_TYPES.RENT.key ? 'price' : 'rentPrice'}`}>{data?.priceRent ?
                                    priceWithCommas(priceByCurrency(data.priceRent, data.currency)) :
                                    <div
                                        className={'contractual'}>{translation?.homepage_post_price} :<span>{translation?.homepage_post_price_text}</span>
                                    </div>}
                                    {data?.priceRent ? data?.rentPriceType === PRICE_TYPES.MONTHLY ?
                                        <span
                                            className={'rent'}> / {translation?.homepage_post_priceType_mount}</span> :
                                        <span
                                            className={'rent'}> / {translation?.homepage_post_priceType_day}</span> : ""
                                    }
                                </div>
                            }
                        })
                        }
                    </div>
                </div>
                <div className="post_description">
                    {data?.description}
                </div>
                <div className="post_address">
                    <Card_Map/> {data?.location?.region?.title}, {data?.location?.region?.residence?.title}
                </div>

                <div className="post_actions">
                    <div className="right_part">
                        <div className="phone_btn">
                            <Dropdown menu={{items}} trigger={['click']}
                                      onVisibleChange={onPhoneDropDownVisibleChanged}>
                                <a onClick={e => e.stopPropagation()}>
                                    <MainButton title={translation?.post_showNumber_btn} light className=""/>
                                </a>
                            </Dropdown>
                            <MainButton title={<PhoneWhiteIcon/>} light className="phone_btn_mob"/>
                        </div>
                        <button className="favorite_btn" onClick={toggleFavorite}>
                            {data?.isFavorite ? <>
                                    <HeartIconDarkFill/><span>{translation?.post_favouriteRemove_title}</span></>
                                : <><HeartDarkIcon/><span>{translation?.post_favouriteAdd_title}</span></>}
                        </button>
                        <Icon onClick={toggleCompare} className={'compare'}>
                            {compared ? <CompareRedIcon/> :
                                <CompareDarkIcon/>}
                        </Icon>
                        <Icon onClick={toggleShare} className={'share-icon'}>
                            <Popover content={shareContent} trigger="click" placement="bottom">
                                <ShareDarkIcon className="action_icon"/>
                            </Popover>
                        </Icon>

                        <div className={`${type === POST_TYPES.RENT.key ? 'priceBlock' : 'mobile-price'}`}>

                            {data?.type.map((item: any) => {
                                if (item === POST_TYPES.SELL.key) {
                                    return <div
                                        className={`${type === POST_TYPES.SELL.key ? 'price' : 'rentPrice'}`}>{data?.priceSell ?
                                        priceWithCommas(priceByCurrency(data.priceSell, data.currency)) :
                                        <div className={'contractual'}>
                                            {translation?.homepage_post_price} :<span>{translation?.homepage_post_price_text}</span>
                                        </div>}
                                    </div>
                                }
                                if (item === POST_TYPES.RENT.key) {
                                    return <div
                                        className={`${type === POST_TYPES.RENT.key ? 'price' : 'rentPrice'}`}>{data?.priceRent ?
                                        priceWithCommas(priceByCurrency(data.priceRent, data.currency)) :
                                        <div
                                            className={'contractual'}>{translation?.homepage_post_price} :<span>{translation?.homepage_post_price_text}</span>
                                        </div>}
                                        {data?.priceRent ? data?.rentPriceType === PRICE_TYPES.MONTHLY ?
                                            <span
                                                className={'rent'}> / {translation?.homepage_post_priceType_mount}</span> :
                                            <span
                                                className={'rent'}> / {translation?.homepage_post_priceType_day}</span> : ""
                                        }
                                    </div>
                                }
                            })
                            }
                        </div>
                        <div className="phone">
                            <Dropdown menu={{items}}
                                      trigger={['click']}
                                      onVisibleChange={onPhoneDropDownVisibleChanged}>
                                <a onClick={e => e.stopPropagation()}>
                                    <MainButton title={<MapCallIcon/>} light/>
                                </a>
                            </Dropdown>
                        </div>
                    </div>

                    <div className="date">{moment(data?.updetedAt).format("DD.MM.YYYY")}</div>
                </div>

                <div className="line"/>
                <VisitModal visible={visible}
                            post={data}
                            toggleVisibility={toggleVisibility}/>
            </div>
        </Link>
    )
}
const mapDispatchToProps = {
    LikePost,
    UnLikePost,
    AddPostToComparedList,
    RemovePostFromComparedList,
    AddPhoneStatistics
}
const mapStateToProps = (state: any) => {
    return getPropsFromState(state, [
        'isLoggedIn',
        'selectedCurrency',
        'comparedPosts',
        'translation',
        'selectedLanguage',
    ])
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductCardList);
