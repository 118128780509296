import {initialState as utils} from "../reducers/utils";
import {initialState as general} from "../reducers/general";
import {initialState as user} from "../reducers/user";
import {initialState as post} from "../reducers/posts";
import {initialState as contacts} from "../reducers/contacts"
import {initialState as notifications} from "../reducers/notifications"

const reducers = {
    utils,
    general,
    user,
    post,
    contacts,
    notifications,
};

export default state => {
    const sData = {};
    Object.keys(reducers).forEach(selectorKey => {
        Object.keys(reducers[selectorKey]).forEach(key => {
            sData[key] = state[selectorKey][key];
        });
    });
    return sData;
};





