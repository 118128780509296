// import packages
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

// Import assets
import "./passord.scss"

//Import components
import AuthWrapper from "../../components/auth-wrapper/AuthWrapper";
import {InputGroup} from "../../components/ui-elements/input-group/inputGroup";
import {MainButton} from "../../components/ui-elements/button/Buttons";

// Import utils
import { USER_ERROR_TYPES} from "../../constants/errorTypes";
import {getPropsFromState} from "../../redux/mapStateToProps";
import {connect} from "react-redux";
import {ForgotUserPassword} from "../../redux/actions";
import isEmail from "validator/es/lib/isEmail";

type MapDispatchPropsType = {
    ForgotUserPassword: (username: string) => Promise<any>
}

type MapStatePropsType = {
    isLoggedIn: boolean
    staticTexts: any
}


type PropsType = MapStatePropsType & MapDispatchPropsType


type InputValueType = {
    name: string,
    value: any
}

const ForgotPassword: React.FC<PropsType> = (props:any) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [username, setUsername] = useState<string>('')
    const [errorMessages, setErrorMessages] = useState<string>('')
    const navigate = useNavigate();
    const {translation} = props

    useEffect(() => {

    },[])

    //functions
    function getInputValues({value}: InputValueType) {
        value= value.toLowerCase()
        setUsername(value)
        setErrorMessages('')
    }

    function handleForgotPassErrors(slug: string) {
        // console.log('handleSignInErrors, slug: ', slug, errorMessages)
        switch (slug) {
            case USER_ERROR_TYPES.USER_NOT_FOUND:
                setErrorMessages(`${translation?.ERROR_USER_NOT_FOUND}`)
                break;
            case USER_ERROR_TYPES.USER_NOT_ACTIVATED:
                setErrorMessages(`${translation?.ERROR_USER_NOT_ACTIVATED}`)
                break;
        }
    }

    async function forgotPassword() {
        setErrorMessages('')
        setLoading(true)
        await props.ForgotUserPassword(username).then(() => {
            navigate('/')
        }).then(() => {
            navigate('/auth/confirm-email', {
                state: {
                    redirectedFromForgotPassword: true,
                    username: username
                }
            })
        }).catch((error: any) => {
            const slug = error && error.response && error.response.data && error.response.data.slug;
            // console.log("error.response", error.response);
            if (slug) {
                handleForgotPassErrors(slug);
            }
        }).finally(()=>{
            setLoading(false)
        })
    }


    return <AuthWrapper className={'password-form'}>
        <div className="title">{translation?.reset_password}</div>
        <div className={'text'}>{translation?.reset_password_description}</div>
        <InputGroup
            inputType={'input'}
            type={"text"}
            name={'username'}
            disabled={loading}
            value={username}
            // error={!username}
            placeholder={translation?.reset_password_email_placeholder}
            label={translation?.reset_password_email_label}
            autoComplete={'off'}
            regExp={/^[a-zA-Z0-9@.-]*$/}
            maxLength={50}
            onKeyDown={(e: any) => {
                e.key === 'Enter' && isEmail(username)&& forgotPassword()
            }}
            onChange={getInputValues}
        >
            <div className={`error-message ${!!errorMessages ? 'show' : 'hide'}`}>
                {errorMessages}
            </div>
        </InputGroup>
        <MainButton title={'Next'}
                    loading={loading}
                    disabled={!username || !isEmail(username)}
                    className="button"
                    click={forgotPassword}/>
    </AuthWrapper>
}

const mapDispatchToProps = {
    ForgotUserPassword
}
const mapStateToProps = (state: any) => {
    return getPropsFromState(state, [
        'isLoggedIn',
        'translation'
    ])
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
