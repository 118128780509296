import {_urlContacts, request} from "../api";
import {CONTACT_CONSTS} from "../constants";


export const GetContact = () => {
    const requestData = {
        url: `${_urlContacts}`,
        method: "GET",
        languageFlag: true
    };

    return dispatch => {
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;
                await dispatch({
                    type: CONTACT_CONSTS.GET_CONTACT,
                    payload: data
                })
            })
    };
};

export const SendMessage = (data) => {
    const requestData = {
        url: `${_urlContacts}`,
        method: "POST",
        data
    };
    return dispatch => {
        return request(requestData).finally(() => {
        })
    }
};