import {POSTS_CONSTS, USER_CONSTS} from "../constants";
import {PostItemType} from "../actions/actionTypes";

export type PostsObject = {
    list: Array<PostItemType>,
    count: number,
    hasMore?: boolean,
    filter?: any,
}

export type PostsStateType = {
    postData: any,
    initialPostData: any,
    posts: PostsObject,
    favorites: PostsObject,
    bestOffers: Array<PostItemType> | null,
    comparedPosts: Array<PostItemType>,
    recentlyViewed: Array<PostItemType>,
};

export const initialState = {
    postData: null,
    initialPostData: null,
    posts: {
        list: [],
        count: 0,
        hasMore: true,
        filter: null
    },
    bestOffers: [],
    comparedPosts: [],
    recentlyViewed: [],
    favorites: {
        list: [],
        count: 0,
    },
    filtersData: null
};

export default (state: PostsStateType = initialState, action: any) => {
    switch (action.type) {
        case POSTS_CONSTS.GET_POSTS_INFO:
            return {
                ...state,
                postData: action.payload,
            };
        case POSTS_CONSTS.GET_POSTS_INITIAL_INFO:
            return {
                ...state,
                postData: action.payload,
                initialPostData: action.payload,
            };
        case POSTS_CONSTS.SET_POSTS_INITIAL_INFO_AS_CURRENT:
            return {
                ...state,
                postData: state.initialPostData
            };

        case POSTS_CONSTS.GET_POST_SEARCH:
            return {
                ...state,
                posts: {
                    list: action.payload.reset ? action.payload.posts : [...state.posts.list, ...action.payload.posts],
                    count: action.payload.count,
                    text: action.payload.text,
                    hasMore: action.payload.hasMore,
                },
            };


        case POSTS_CONSTS.GET_BEST_OFFERS:
            return {
                ...state,
                bestOffers: action.payload,
            };
        case POSTS_CONSTS.GET_FAVORITE_POSTS:
            return {
                ...state,
                favorites: {
                    list: action.payload.data,
                    count: action.payload.count,
                },
            };
        case POSTS_CONSTS.ADD_POST_TO_RECENTLY_VIEWED:
            const viewedPost = {
                ...action.payload,
                mediaMain: action.payload?.medias?.[0]
            }
            const filteredList = state.recentlyViewed?.filter(post => post.id !== action.payload.id) || []
            // console.log(viewedPost,filteredList,'Viewed')
            return {
                ...state,
                recentlyViewed: [viewedPost, ...filteredList].slice(0, 30),
            };

        case POSTS_CONSTS.RESET_RECENTLY_VIEWED_POSTS:
            return {
                ...state,
                recentlyViewed: action.payload,
            };

        case POSTS_CONSTS.ADD_POST_TO_COMPARED_LIST:
            const filteredComparedList = state.comparedPosts?.filter(post => post.id !== action.payload.id) || []
            return {
                ...state,
                comparedPosts: [action.payload, ...filteredComparedList],
            };

        case POSTS_CONSTS.REMOVE_POST_FROM_COMPARED_LIST:
            return {
                ...state,
                comparedPosts: state.comparedPosts?.filter(post => post.id !== action.payload),
            };

        case POSTS_CONSTS.SET_FILTERS_DATA:
            return {
                ...state,
                filtersData: action.payload
            };

        case POSTS_CONSTS.TOGGLE_POST_LIKE:
            return {
                ...state,
                posts: {
                    ...state.posts,
                    list: state?.posts?.list?.map((item: any) => item.id === action.payload.id ?
                        {
                            ...item,
                            isFavorite: action.payload.likeState
                        } : item
                    )
                },
                favorites: {
                    ...state.favorites,
                    list: state.favorites?.list?.filter(post => post.id !== action.payload.id),
                    count: action.payload.likeState ? state.favorites.count : state.favorites.count - 1
                },
                bestOffers: state.bestOffers?.map((item: any) => item.id === action.payload.id ?
                    {
                        ...item,
                        isFavorite: action.payload.likeState
                    } : item
                ),
                comparedPosts: state.comparedPosts?.map((item: any) => item.id === action.payload.id ?
                    {
                        ...item,
                        isFavorite: action.payload.likeState
                    } : item
                ),
                recentlyViewed: state.recentlyViewed?.map((item: any) => item.id === action.payload.id ?
                    {
                        ...item,
                        isFavorite: action.payload.likeState
                    } : item
                ),

            };
        case USER_CONSTS.LOG_OUT:
            return {
                ...state,
                posts: {
                    ...state.posts,
                    list: state?.posts?.list?.map((item: any) => ({
                            ...item,
                            isFavorite: false
                        })
                    ),
                },
                bestOffers: state.bestOffers?.map((item: any) => ({
                    ...item,
                    isFavorite: false
                })),
                comparedPosts: state.comparedPosts?.map((item: any) => ({
                    ...item,
                    isFavorite: false
                })),
                recentlyViewed: state.recentlyViewed?.map((item: any) => ({
                    ...item,
                    isFavorite: false
                })),
                favorites: {
                    list: [],
                    count: 0
                },
            };
        default:
            return state;
    }
}
