import {USER_CONSTS, UTIL_CONSTS} from "../constants";
import {request, _urlUsers, _urlOauth, _urlSubscribe, _urlMedia} from "../api";
import {store} from "../store";
import {RequestDataType} from "./actionTypes";
import {stringify} from "query-string";

export const AddUser = (userData: any) => {
    const requestData: RequestDataType = {
        url: _urlUsers,
        method: "POST",
        data: userData,
    };

    return (dispatch: any) => {
        dispatch({
            type: UTIL_CONSTS.START_LOADING
        });
        return request(requestData)
    };
};

export const ActivateUser = (activationData: any) => {
    const requestData: RequestDataType = {
        url: `${_urlUsers}/activation`,
        method: "POST",
        data: activationData,
    };

    return async (dispatch: any) => {
        return await request(requestData).then((res) => {
            const loginData = res?.data;
            return GetLoggedInUser(loginData?.accessToken).then(async (loggedInUser: any) => {
                await dispatch({
                    type: USER_CONSTS.LOG_IN,
                    payload: {
                        ...loginData,
                        user: loggedInUser,
                    }
                });
            })
        })
    };
};

export const ResendActivation = (username: string) => {
    const requestData: RequestDataType = {
        url: `${_urlUsers}/resend-activation`,
        method: "POST",
        languageFlag: true,
        data: {
            "username": username
        },
    };
    return () => {
        return request(requestData)
    }
};

export const UpdateUserLanguage = (language: string) => {
    const requestData: RequestDataType = {
        url: `${_urlUsers}/language`,
        method: "PUT",
        token: true,
        data: {
            language
        }
    };

    return () => {
        return request(requestData)
    }
};

export const ForgotUserPassword = (username: string) => {
    const requestData: RequestDataType = {
        url: `${_urlUsers}/forgot-password`,
        method: "POST",
        languageFlag: true,
        data: {
            "username": username
        },
    };
    return (dispatch: any) => {
        return request(requestData)
    };
};

export const CheckResetPasswordCode = (resetData: any) => {
    const requestData: RequestDataType = {
        url: `${_urlUsers}/check-reset-password-code`,
        method: "POST",
        data: resetData,
    };

    return async (dispatch: any) => {
        return await request(requestData)
    };
};

export const ResetUserPassword = (resetData: any) => {
    const requestData: RequestDataType = {
        url: `${_urlUsers}/reset-password`,
        method: "POST",
        data: resetData,
    };
    return async (dispatch: any) => {
        dispatch({
            type: UTIL_CONSTS.START_LOADING
        });
        return await request(requestData)
            .then((res) => {
                const loginData = res?.data;
                return GetLoggedInUser(loginData?.accessToken).then(async (loggedInUser: any) => {
                    await dispatch({
                        type: USER_CONSTS.LOG_IN,
                        payload: {
                            ...loginData,
                            user: loggedInUser,
                        }
                    });
                })
            })
            .finally(() => {
                dispatch({
                    type: UTIL_CONSTS.END_LOADING
                });
            })
    };
};

export const UpdateCurrentUserPassword = (updatedData: any) => {
    const requestData = {
        url: `${_urlUsers}/current/password`,
        method: "PUT",
        token: true,
        data: updatedData
    };

    return (dispatch: any) => {
        return request(requestData)
    }
};

export const GetCurrentUser = () => {
    const requestData: RequestDataType = {
        url: `${_urlUsers}/current`,
        method: "Get",
        token: true,
        languageFlag: true,
    };
    return (dispatch: any) => {
        return request(requestData)
            .then(async (res) => {
                const resData = res && res.data
                if (resData) {
                    await dispatch({
                        type: USER_CONSTS.GET_CURRENT_USER,
                        payload: resData
                    });
                }
            }).catch(() => {
                // console.log('catch');
            })
    }
};

export const UpdateCurrentUser = (userData: any) => {
    const requestData: RequestDataType = {
        url: `${_urlUsers}/current`,
        method: "PATCH",
        token: true,
        languageFlag: true,
        data: userData
    };

    return (dispatch: any) => {
        return request(requestData)
            .then(async (res) => {
                const data = (res && res.data) || userData;
                // console.log(data, 'dataaa');
                data && await dispatch({
                    type: USER_CONSTS.UPDATE_CURRENT_USER,
                    payload: data
                });
            })
    }
};

export const SendSubscribe = (data: any) => {
    const requestData = {
        url: `${_urlSubscribe}`,
        method: "POST",
        data
    };
    // console.log(data, 'data');
    return (dispatch: any) => {
        return request(requestData).finally(() => {
        })
    }
};

export const UploadUserProfilePicture = (formData: any) => {
    const requestData = {
        url: `${_urlMedia}/profile-picture`,
        token: true,
        method: "POST",
        data: formData,
        customHeaders: {
            "Content-Type": "multipart/form-data",
            scope: "access:member",
        }
    };

    return (dispatch: any) => {
        // @ts-ignore
        return request(requestData)
            .then(res => res?.data)
            .catch(() => null)
    }
};

//===============Authentication====================

export const LoginUser = (loginCreds: any) => {
    const requestData: RequestDataType = {
        url: `${_urlOauth}/token`,
        method: "POST",
        data: stringify(loginCreds),
        customHeaders: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    };
    return (dispatch: any) => {
        return request(requestData)
            .then((res) => {
                const loginData = res?.data;
                return GetLoggedInUser(loginData?.accessToken).then(async (loggedInUser: any) => {
                    // console.log('user', loggedInUser);
                    await dispatch({
                        type: USER_CONSTS.LOG_IN,
                        payload: {
                            ...loginData,
                            user: loggedInUser,
                        }
                    });
                })
            })
    }
};

export const GetLoggedInUser = async (newToken: any) => {
    // console.log('newToken', newToken);
    const requestData = {
        url: `${_urlUsers}/current`,
        languageFlag: true,
        token: true,
        newToken
    };
    return request(requestData).then((res) => {
        return res?.data
    })
};

export const LogOut = () => {
    return (dispatch: any) => {
        dispatch({
            type: USER_CONSTS.LOG_OUT
        });
    }
};

export const TokenValidation = () => {
    const AccessToken = store.getState()?.user?.accessToken;
    // console.log('authentication start',)
    const requestData: RequestDataType = {
        url: `${_urlOauth}/authenticate`,
        method: "GET",
        scope: true,
        customHeaders: {
            "Accept": "application/json",
            "x-access-token": AccessToken,
        }
    };
    return (dispatch: any) => {
        return request(requestData)
            .then(async (res) => {
                // console.log('authentication success')
                return res?.data?.accessToken;
            })
    }
};


export const RefreshToken = () => {
    const logEnabled = false;
    const refToken = store.getState()?.user?.refreshToken;
    logEnabled && console.log('start-refreshing-function');
    const requestData: RequestDataType = {
        url: `${_urlOauth}/refresh-token`,
        method: "POST",
        data: stringify({
            grantType: "refreshToken",
            refreshToken: refToken
        }),
        customHeaders: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    };
    if(!refToken){
        logEnabled && console.log('Refresh token not found')
        return Promise.reject('Refresh token not found')
    }
    logEnabled && console.log(RefreshToken.startRefreshing);
    if (!RefreshToken.startRefreshing) {
        RefreshToken.startRefreshing = true;
        logEnabled && console.log('start-refreshing-request');
        RefreshToken.promise = request(requestData).then(async (response) => {
            //    console.log(response);
            response && await store.dispatch({
                type: USER_CONSTS.REFRESH_TOKEN,
                payload: response.data
            });
            return response && response.data && response.data.accessToken;
        }).finally(() => {
            logEnabled && console.log('end-refreshing-request-finally');
            RefreshToken.startRefreshing = false;
        });
    }
    logEnabled && console.log('end-refreshing-function');
    return RefreshToken.promise;
};

RefreshToken.startRefreshing = false;
// @ts-ignore
RefreshToken.promise = null;
