// Import packages
import React, {useEffect, useState} from "react";

// Import styles
import "./progress-bar.scss";

type ProgressBarTypes = {
    slideIndex: number;
    sliderCircleEnded: boolean;
    sliderLength: any;
};

export const ProgressBar: React.FC<ProgressBarTypes> = (props) => {
    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        if(props.sliderCircleEnded ){
            setAnimate(false);
        }else{
            setAnimate(true);
        }
    }, [props.sliderCircleEnded ]);

    return <div className="progress-wrapper">
        <span
            className={'slide-index'}>{props.slideIndex < 9 ? 0 : ''}{`${props.slideIndex + 1}`}</span>
        <div className={'progress-bar'}>
            <span className={`stroke ${animate ? 'animate' : ''}`}/>
        </div>
        <span
            className={'slide-index'}>{`${props.slideIndex < 9 ? 0 : ''}${props.sliderLength}`}</span>
    </div>
}
