// import packages
import React, {FC, useEffect, useState} from "react";
import {connect} from "react-redux";

// Import utils
import {CategoriesItemType, PostItemType} from "../../redux/actions/actionTypes";
import {getPropsFromState} from "../../redux/mapStateToProps";
import {GetSimilarPosts} from "../../redux/actions";
import PostSection from "./PostSection";
import {CURRENCY_ENUM} from "../../constants/constants";

type PropsTypes = {
    categoryId?: string;
    selectedCurrency?: CURRENCY_ENUM;
    translation?: any;
    categories?: Array<CategoriesItemType>;
    GetBestOffers?: any
};

const SimilarPostsSection: React.FC<PropsTypes> = (props:any) => {
    const {categoryId, selectedCurrency,translation,post,selectedLanguage} = props;
    const [similarPosts, setSimilarPosts]=useState<PostItemType[]>([])
    useEffect(() => {
        categoryId && props.GetSimilarPosts({
            category: categoryId,
            currency: selectedCurrency,
            type:post.type[0]
        }).then((result: PostItemType[])=>{
            setSimilarPosts(result)
        })
    }, [categoryId, selectedCurrency,selectedLanguage?.code])

    return <PostSection showHeader={true}
                        title={translation?.post_similarSlider_title}
                        seeAllButton={false}
                        posts={similarPosts}/>

}

const mapStateToProps = (state: any) => getPropsFromState(state, [
    // names (type - string) of props
    'translation',
    'selectedCurrency',
    'selectedLanguage',
]);

const mapDispatchToProps = {
    //redux actions
    GetSimilarPosts
};

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(SimilarPostsSection);
