// Import packages
import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";

// Import assets
import "./home-filter.scss"

// Import components
import {InputGroup} from "../../../components/ui-elements/input-group/inputGroup";
import {MainButton} from "../../../components/ui-elements/button/Buttons";
import {
    ClearIcon, EllipseIcon,
    RegionArrIcon,
    RegionCheckIcon,
    RegionMinusIcon,
    RegionPlusIcon,
    SearchIcon
} from "../../../assets/images";
import {Slider, Space, Dropdown, Menu} from "antd";

// Import utils.
import {
    GetPostsInfo,
    SetInitialInfo,
    PriceType,
    GetResidencesList, SetFiltersData,
} from "../../../redux/actions";
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {CategoriesItemType} from "../../../redux/actions/actionTypes";
import {CURRENCIES, CURRENCY_ENUM, POST_ENUM, POST_TYPES} from "../../../constants/constants";
import Filter from "../../search/filter/Filter";
import {useNavigate} from "react-router-dom";

type InputValueType = {
    name: string,
    value: any
}

type FiltersStateType = {
    type?: string,
    category?: string,
    text?: string,
    roomsCount?: undefined | number,
    minPrice?: number | string,
    maxPrice?: number,
    regions?: any,
    residences?: any,
    currency: CURRENCY_ENUM,
}

const roomCountOptions = [
    {id: '1', name: '1'},
    {id: '2', name: '2'},
    {id: '3', name: '3'},
    {id: '4+', name: '4+'},
];

const HomeFilter: React.FC = (props: any) => {
    const {
        postData,
        categories,
        initialPostData,
        selectedCurrency,
        selectedLanguage,
        translation,
        regionsList,
        residencesList
    } = props;
    let MIN_PRICE = initialPostData?.minPrice || 0;
    // let MAX_PRICE = initialPostData?.maxPrice || 10000000;
    let MAX_PRICE = 999999999;

    const filtersInitialValues: FiltersStateType = {
        type: POST_ENUM.SELL,
        category: undefined,
        text: '',
        roomsCount: undefined,
        minPrice: undefined,
        maxPrice: undefined,
        regions: [],
        residences: [],
        currency: selectedCurrency
    }
    const navigate = useNavigate();

    const [filters, setFilters] = useState(filtersInitialValues)
    const [tempData, setTempData] = useState({
        minPrice: MIN_PRICE,
        maxPrice: MAX_PRICE,
    })
    const [openRegionId, setOpenRegionId] = useState<string[]>([])
    const [residenceData, setResidenceData] = useState(false)
    const categoriesOptions = categories?.map((item: CategoriesItemType) => {
        return {
            id: item.id,
            name: item.title
        }
    })

    const [showDropDown, setShowDropDown] = useState(false);

    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setResidenceData(true)
    }, [residencesList])


    useEffect(() => {
        document.addEventListener('click', toggleDropDown, {capture: true});
        return () => {
            document.removeEventListener('click', toggleDropDown)
        }
    }, []);

    const toggleDropDown = (event: any) => {
        const path = event.composedPath ? event.composedPath() : event.path
        if (!path?.includes(ref?.current) && !showDropDown) {
            setShowDropDown(false)
            setOpenRegionId([])
            setResidenceData(false)
        }
    }

    useEffect(() => {
        props.SetInitialInfo()
        setFilters(filtersInitialValues);
        setTempData({
            minPrice: MIN_PRICE,
            maxPrice: MAX_PRICE,
        })
    }, [initialPostData?.minPrice, initialPostData?.maxPrice])

    useEffect(() => {
        props.PriceType(filters.type)
    }, [filters.type])


    //Functions
    const onChangeSliderTempData = (value: Array<number>, type: string) => {
        setTempData({
            ...tempData,
            [`min${type}`]: value[0],
            [`max${type}`]: value[1],
        })
    }

    function onAfterChangePrice(values: [number, number]) {
        const state = {
            ...filters,
            minPrice: values[0] !== MIN_PRICE ? values[0] : undefined,
            maxPrice: values[1] !== MAX_PRICE ? values[1] : undefined
        }
        changeFilterState(state);
    }

    function changeFilter(name: string, value: any) {
        let state = {
            ...filters,
            [name]: value
        };
        changeFilterState(state);
    }

    function changeFilterState(state: FiltersStateType) {
        setFilters(state);
        let reqData = {
            ...state,
            regions: state?.regions.map((region: any) => region?.id),
            residences: state?.residences.map((residence: any) => residence?.id),
        }
        props.GetPostsInfo(reqData)
    }

    function getInputValues({name, value}: InputValueType) {
        if (name === "count" && value && !/^[+\d]\d*$/.test(value)) {
            return
        }
        changeFilter(name, value)
    }

    function getSelectValue(name: string, e: any) {
        changeFilter(name, e)
    }

    function removeFilters() {
        if (postData !== initialPostData) {
            setFilters(filtersInitialValues);
            setTempData({
                minPrice: MIN_PRICE,
                maxPrice: MAX_PRICE,
            })
            changeFilterState({
                ...filtersInitialValues
            })
            props.SetInitialInfo()
        }
    }


    const getSelectedRegion = (region: any) => {

        const checked = filters?.regions?.find((item: any) => item?.id === region?.id)
        if (checked) {
            filters.regions = filters?.regions?.filter((item: any) => item?.id !== region?.id)
        } else {
            filters.regions = [...filters?.regions, region]
            filters.residences = filters.residences?.filter((item: any) => item?.region !== region?.id)
        }
        changeFilterState({
            ...filters,
            regions: filters.regions,
            residences: filters.residences
        })
    }

    const getSelectedResidence = (residence: any, region: any) => {
        const checked = filters?.residences?.find((item: any) => item?.id === residence?.id)
        if (checked) {
            filters.residences = filters?.residences?.filter((item: any) => item?.id !== residence?.id)
        } else {
            filters.residences = [...filters?.residences, {
                id: residence?.id,
                title: residence?.title,
                region: region?.id
            }]
            filters.regions = filters.regions?.filter((regionId: any) => regionId?.id !== region.id)
        }
        changeFilterState({
            ...filters,
            regions: filters.regions,
            residences: filters.residences
        })
    };

    function closeResidences() {
        setOpenRegionId([])
        setResidenceData(false)
    }

    function getResidences(id: any) {
        setOpenRegionId(id)
        props.GetResidencesList(id)
    }


    const items = <div ref={ref}>
        <Menu className={'home_region_block_dropdown'} items={
            regionsList?.length && regionsList.map((region: any, index: number) => {
                return {
                    key: index,
                    label: <div className={'region'}>
                        <div className={'regions-header'}>
                            <div className={'checked-block'} onClick={() => getSelectedRegion(region)}>
                                <p>{filters?.residences.find((item: any) => item?.region === region?.id) ?
                                    <EllipseIcon/> : filters?.regions?.find((item: any) => item?.id === region?.id) ?
                                        <RegionCheckIcon/> : ''}</p>
                                <span>{region?.title[selectedLanguage?.code]}</span>
                            </div>
                            {
                                openRegionId === region?.id ? <p onClick={closeResidences}>
                                        <RegionMinusIcon/>
                                    </p> :
                                    <p onClick={() => getResidences(region?.id)}>
                                        <RegionPlusIcon/>
                                    </p>
                            }
                        </div>
                        {
                            openRegionId === region?.id ? <div className={'residences'}>
                                {
                                    residenceData ? residencesList?.map((residence: any) => {
                                        // @ts-ignore
                                        return <div className={'residences-block'}
                                                    onClick={() => getSelectedResidence(residence, region)}>
                                            <p>{filters?.residences?.find((item: any) => item?.id === residence?.id) ?
                                                <RegionCheckIcon/> : ''}</p>
                                            <span>{residence?.title[selectedLanguage?.code]}</span>
                                        </div>
                                    }) : ''
                                }
                            </div> : ''
                        }
                    </div>,
                }
            })}
        />
    </div>;

    const regionsTitle = filters?.regions?.map((val: any) => val?.title[selectedLanguage?.code])?.join(", ")
    const residenceTitle = filters?.residences?.map((val: any) => val?.title[selectedLanguage?.code])?.join(", ")
    const inputValue = regionsTitle?.length > 1 && residenceTitle?.length !== 0 ?
        regionsTitle.concat(',', residenceTitle) : regionsTitle?.length < 1 && residenceTitle?.length < 1 ?
            translation?.homepage_homeFilter_residence_input : regionsTitle.concat('', residenceTitle)

    return <div className={`home_filter`}>
        <div className="home_filter__form">
            <div className="switch">
                {
                    Object.entries(POST_TYPES)?.map(([key, value]) => {
                        return <span className={`${key === filters.type ? 'active' : ''}`}
                                     key={key}
                                     onClick={() => changeFilter('type', key)}
                        >
                            {translation?.[`filter_type_title_${key}`]}
                        </span>
                    })
                }
            </div>
            <div className={'home_filter_region'}>
                <div className={'home_filter_region_title'}>
                    {translation?.homepage_homeFilter_residence}
                </div>
                <div className={'filter_region_block'}>
                    <Dropdown
                        overlay={items}
                        trigger={['click']}
                        open={showDropDown}
                        onOpenChange={() => setShowDropDown(true)}
                    >
                        <a onClick={(e) => e.preventDefault()}>
                            <Space>
                                <div className={'region-input-text'}>
                                    {inputValue}
                                </div>
                            </Space>
                            <div className={'icon-wrapper'}>
                                <RegionArrIcon/>
                            </div>
                        </a>
                    </Dropdown>
                </div>
            </div>
            <div className="home_filter__form__select">
                <div className="label">{translation?.homepage_homeFilter_building_label}</div>
                <InputGroup
                    showSearch={false}
                    inputType={'select'}
                    name={'category'}
                    value={filters.category}
                    placeholder={translation?.filter_building_placeholder}
                    onChange={getSelectValue}
                    options={categoriesOptions}/>
            </div>
            <div className={'home_filter__form__slider'}>
                <div className="label">{translation?.homepage_homeFilter_price_label}</div>
                <div className={'slider_content'}>
                    <div className={'slider_label'}>
                        <div className={'left-block'}>
                            <span>{translation?.homepage_homeFilter_price_from}</span>{CURRENCIES[selectedCurrency]?.suffix} {tempData.minPrice}
                        </div>
                        <div className={'right-block'}>
                            <span>{translation?.homepage_homeFilter_price_to}</span>{CURRENCIES[selectedCurrency]?.suffix} {tempData.maxPrice}
                        </div>
                    </div>
                    <Slider range
                            min={MIN_PRICE}
                            max={MAX_PRICE}
                            value={[typeof tempData.minPrice === 'number' ? tempData.minPrice : MIN_PRICE, typeof tempData.maxPrice === 'number' ? tempData.maxPrice : MAX_PRICE]}
                            tooltip={{formatter: null}}
                            onChange={(value) => onChangeSliderTempData(value, 'Price')}
                            onAfterChange={onAfterChangePrice}
                    />
                </div>
            </div>
            <div className="home_filter__form__select">
                <div className="label">{translation?.homepage_homeFilter_room_label}</div>
                <InputGroup
                    showSearch={false}
                    inputType={'select'}
                    name={'roomsCount'}
                    value={filters.roomsCount}
                    placeholder={translation?.homepage_homeFilter_room_placeholder}
                    onChange={getSelectValue}
                    options={roomCountOptions}/>
            </div>
        </div>
        <div className="home_filter__search">
            <InputGroup inputType={'input'}
                        type={"text"}
                        value={filters.text}
                        name={'text'}
                        maxLength={100}
                        placeholder={translation?.homepage_homeFilter_search_placeholder}
                        className="search_input"
                        onChange={getInputValues}>
                <SearchIcon className="search_icon"/>
            </InputGroup>

            <MainButton
                click={() => {
                    props.SetFiltersData({filters, fromHomeFilter: true})
                    navigate('/search')
                }}
                // link={'/search'}
                // state={filters}
                title={`${postData?.count} ${translation?.homepage_homeFilter_search_btn}`}
                className="home_filter__search__btn"/>
        </div>

        <div className="home_filter__clear_wrapper">
            <div className={`clear_btn ${postData === initialPostData ? 'disabled' : ''}`}
                 onClick={removeFilters}>
                <ClearIcon/> {translation?.homepage_homeFilter_clear_btn}
            </div>
            {/*
            <div className="refine_btn">{translation?.homepage_homeFilter_refine_btn}</div>
*/}
        </div>
    </div>
}

const mapDispatchToProps = {
    GetPostsInfo,
    SetInitialInfo,
    PriceType,
    GetResidencesList,
    SetFiltersData,
};

const mapStateToProps = (state: any) => {
    return getPropsFromState(state, [
        'postData',
        'initialPostData',
        'categories',
        'selectedCurrency',
        'translation',
        'regionsList',
        'residencesList',
        'selectedLanguage'
    ])
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeFilter);
