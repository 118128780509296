import React from "react";
import './clock.scss'
import {ClockCentreIcon, ClockBgIcon} from "../../../assets/images";

type PropsTypes = {
    hourRatio: number;
    minuteRatio: number;
    secondRatio: number;
    city: string;
    translation:any
};

function Clock({hourRatio, minuteRatio, secondRatio, city,translation}: PropsTypes) {

    return (
        <div className={'clock_item'}>

            <div className="clock_item__content">
                <div className={'hand hour'} style={{transform: `translate(-50%) rotate(${hourRatio * 360}deg)`}}/>
                <div className={'hand minute'} style={{transform: `translate(-50%) rotate(${minuteRatio * 360}deg)`}}/>
                <div className={'hand second'} style={{transform: `translate(-50%) rotate(${secondRatio * 360}deg)`}}/>
                <ClockBgIcon className={'clock_item__bg'}/>
                <ClockCentreIcon className={'clock_item__content__center'}/>
            </div>
            <div className={'city'}>
                {translation[city]}
            </div>
        </div>
    )
}

export default Clock