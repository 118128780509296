// Import packages
import React, {useEffect} from "react";
import {connect} from "react-redux";


// Import assets
import "./faq.scss"
import PageHeader from "../../components/header/page-header/page-header";
import Subscribe from "../homepage/subscribe/Subscribe";
import {FaqCloseIcon, FaqOpenIcon} from "../../assets/images";

// Import components
import {GetFaqList} from "../../redux/actions";
import {getPropsFromState} from "../../redux/mapStateToProps";
import { Collapse } from 'antd';
const { Panel } = Collapse;

// Import utils.


function Faq(props) {
    const {faqList,translation} = props

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        props.GetFaqList()
    },[])

    return <div className={'Faq_wrapper'}>
        <div className={'Faq_wrapper__header'}>
            <PageHeader name={translation?.faq_page_title}/>
        </div>
        <div className={'Faq_wrapper__information'}>
            <div className={'Faq_wrapper__information__title'}>
                {translation?.faq_page_title}
            </div>
                <Collapse
                    expandIconPosition={'end'}
                    className={'Faq_wrapper__information__blocks'}
                    expandIcon={({ isActive }) => isActive ? <FaqOpenIcon/> : <FaqCloseIcon/>}
                >
                    {faqList?.length && faqList?.map(item => {
                        return <Panel header={item?.question}
                                      key={item?.id}
                                      className={'Faq_wrapper__information__blocks__wrapper'}>
                            <div className={'Faq_wrapper__information__blocks__description'}>
                                {item?.answer}
                            </div>
                        </Panel>
                    })

                    }
                </Collapse>
        </div>
        <div className={'Faq_wrapper__subscribe'}>
            <Subscribe/>
        </div>
    </div>
}
const mapStateToProps = (state) => getPropsFromState(state, [
    'faqList',
    'translation',
]);

const mapDispatchToProps = {
    GetFaqList,
};

export default connect(mapStateToProps, mapDispatchToProps) (Faq);