// import packages
import React, {useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";

//import styles
import "./auth-wrapper.scss";
import {ProjectLogo} from "../../assets/images";
import {getPropsFromState} from "../../redux/mapStateToProps";
import {connect} from "react-redux";


function AuthWrapper(props: any) {
    const {children, className, isLoggedIn} = props;
    const navigate = useNavigate();

    useEffect(()=>{
        if(isLoggedIn){
            navigate('/')
        }
    })
    return <div className={`auth_wrapper ${className ? className : ''}`}>
        <Link to={'/'} className={'auth_wrapper__logo'}><ProjectLogo/></Link>
        {children}
    </div>
}
const mapStateToProps = (state: any) => {
    return getPropsFromState(state, [
        'isLoggedIn'
    ])
};

export default connect(mapStateToProps)(AuthWrapper);
