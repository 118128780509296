import React from "react";
import { InputGroup } from "../input-group/inputGroup";
import './single-inputs-group.scss';

export class SingleInputsGroup extends React.Component {
    constructor () {
        super();
        this.state = {
            codes: {},
        };
        this.codeInputRefs = []
        Array.from(Array(6)).forEach((_, index) => {
            this.codeInputRefs[index] = React.createRef()
        })
    }

    componentDidMount () {
        this.codeInputRefs[0]?.current?.focus()
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        if (this.props.value !== prevProps.value && this.props.value === '') {
            // code removed
            this.setState({
                codes: {}
            })
            this.codeInputRefs.forEach((_, i) => {
                this.codeInputRefs[i]?.current?.blur()
            })
        } else if (this.state.codes !== prevState.codes) {
            const code = Object.values(this.state.codes).join("")
            this.props?.onChange?.(code)
        }
    }

    onKeyUp = (index, e) => {
        if (e.key >= 0 && e.key <= 9) {

        } else if (e.key === 'Backspace') {
            this.codeInputRefs[index - 1 < 0 ? 0 : index - 1]?.current?.focus()
        }
    }

    getCodeNumbers = ({ name, value, e }) => {
        const index = Number(name.split('_')[1])
        let singleNumber = true
        // console.log('get index- ', index, ' value- ', value, ' name -', name,)
        if (value && value !== '0' && (!Number(value) || value.includes('.'))) {
            return;
        }
        if (value.length > 1) {
            if (value.length === 2 && Number(value[0]) === Number(this.state.codes[name])) {
                singleNumber = true
                value = value[1]
            } else {
                singleNumber = false
                let codes = {
                    ...this.state.codes
                };
                Array.from(Array(6 - index)).forEach((item, i) => {
                    codes[`code_${index + i}`] = value[i]
                });
                this.setState({
                    codes: codes
                })
                const activeIndex = Number(value.length + index);
                // console.log('activeIndex after change', activeIndex)
                if (activeIndex < 6) {
                    this.codeInputRefs[activeIndex]?.current?.focus()
                } else {
                    this.codeInputRefs[index]?.current?.blur()
                }
            }
        }
        if (singleNumber) {
            this.setState({
                codes: {
                    ...this.state.codes,
                    [name]: value,
                }
            })
            if (value) {
                if (index === 5) {
                    this.codeInputRefs[5]?.current?.blur()
                } else {
                    this.codeInputRefs[index + 1]?.current?.focus()
                }
            } else {
                // this.codeInputRefs[index - 1 < 0 ? 0 : index - 1]?.current?.focus()
            }
        }
    }

    render () {
        const { codes, } = this.state;
        const { loading } = this.props;

        return <div className="code-container">
            {Array.from(Array(6)).map((item, index) => {
                return <React.Fragment key={index}>
                    <InputGroup
                        inputType={'number'}
                        name={`code_${index}`}
                        disabled={loading}
                        value={codes[`code_${index}`]}
                        minValue="0"
                        maxValue="9"
                        onChange={this.getCodeNumbers}
                        onKeyUp={(e) => this.onKeyUp(index, e)}
                        className={'code'}
                        required={true}
                        forwardRef={this.codeInputRefs[index]}
                    />
                </React.Fragment>
            })}
        </div>
    }
}
