import React, {useEffect, useRef} from "react";
import styled from "styled-components";
import {useLocation, useNavigate} from "react-router-dom";
import {
    CompareIcon,
    CompareDarkIcon,
    HeartIcon,
    HeartIconFill,
    ShareIcon,
    PhoneIcon,
    CallIcon, ShareFacebookIcon, ShareLinkedinIcon, CopyIcon, Card_Map, CopiedIcon, VisitIcon
} from "../../../assets/images";
import {MainButton} from "../../ui-elements/button/Buttons";
import {generateImageMediaUrl} from "../../../utils/generateMediaUrl";
import {Dropdown, MenuProps, Popover} from "antd";
import {CONDITION_TYPES, CURRENCY_ENUM, PHONE_TYPES, POST_TYPES, PRICE_TYPES} from "../../../constants/constants";
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {connect} from "react-redux";
import {
    AddPhoneStatistics,
    AddPostToComparedList,
    LikePost,
    RemovePostFromComparedList,
    UnLikePost
} from "../../../redux/actions";
import {priceByCurrency} from "../../../utils/priceByCurrency";
import moment from "moment";
import {PostItemType} from "../../../redux/actions/actionTypes";
import {
    FacebookShareButton,
    LinkedinShareButton,
} from "react-share";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {WEB_PAGE_URL} from "../../../redux/api";
import {priceWithCommas} from "../../../utils/helperFunctions";
import VisitModal from "../../ui-elements/modal/VisitModal";
import {Link} from 'react-router-dom'
import './productCardGrid.scss'


const PostImage = styled.div`
  width: 100%;

  .img_wrapper {
    width: 100%;
    padding-top: 100%;
    position: relative;
    overflow: hidden;
   .badges{
     position: absolute;
     top: 0;
     left: 0;
     z-index: 2;
     display: flex;
     flex-direction: column;
     align-items: flex-start;
     .urgent {
       width: 66px;
       display: flex;
       align-items: center;
       justify-content: center;
       background-color: #B79B7A;
       color: white;
       height: 33px;
       padding: 0 6px;
       text-overflow: ellipsis;
       overflow: hidden;
     }
     .top{
       width: 66px;
       height: 33px;
       min-width: 33px;
       padding: 0 6px;
       background-color: #CDBB99;
       color: white;
       display: flex;
       align-items: center;
       justify-content: center;
       font-size: 12px;
     }
    }
    
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 1s;
      display: block;

      &:hover{
        transform: scale(1.2);
      }
    }

    .icons_wrapper {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
    }
  }
  
`;

const Icon = styled.button`
  width: 32px;
  height: 32px;
  backdrop-filter: blur(2px);
  background-color: rgba(205, 187, 153, 0.7);
  overflow: hidden;
  position: relative;
  margin-bottom: 1px;

  svg {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
  }
`;

const Content = styled.div`
  position: relative;
`;

type MapDispatchPropsType = {
    LikePost: (id: string) => Promise<any>
    UnLikePost: (id: string) => Promise<any>
    AddPostToComparedList: (post: PostItemType) => void
    RemovePostFromComparedList: (id: string) => void
    AddPhoneStatistics: (id: string) => void
}

type MapStatePropsType = {
    isLoggedIn?: boolean
    staticTexts?: any
    comparedPosts?: [PostItemType]
    requestLoading?: boolean
    selectedCurrency?: CURRENCY_ENUM;
}

type Props = MapStatePropsType & MapDispatchPropsType & {
    className?: string;
    bordered?: boolean;
    isLoggedIn?: boolean;
    data?: any;
    slug?:string;
}


const PostCardGrid: React.FC<Props> = (props: any) => {
    const {className, isLoggedIn,slug, data, selectedCurrency, comparedPosts, selectedLanguage, translation,type} = props


    const navigate = useNavigate();
    const location = useLocation()
    const [copyed, setCopyed] = React.useState<boolean>(false);
    const [showPhoneSection, setShowPhoneSection] = React.useState<boolean>(false);
    const [phoneDropDownOpen, setPhoneDropDownOpen] = React.useState<boolean>(false);
    const [visible, setVisible] = React.useState<boolean>(false);
    const isFetching = useRef(false)
    const compared = !!comparedPosts?.find((p: any) => p.id === data?.id);
    const shareUrl = `${WEB_PAGE_URL}/post/${data.slug}?lg=${selectedLanguage?.code}`

    const toggleVisibility = () => {
        setVisible(!visible)
    }


    const items: MenuProps['items'] = [
        {
            label: data?.contacts?.map((item: any) => {
                return  <a onClick={e => e.stopPropagation()}
                           className={"lang_menu_item"}
                           href={`tel:${item?.phone}`}>
                    {PHONE_TYPES.HOME.key === item.type ? <PhoneIcon/> : <CallIcon/>} {item.phone}
                </a>
            }),
            key: '1',
        },
        {
            type: 'divider',
        },
        {
            label: <div onClick={e => e.stopPropagation()}
                        className={"lang_menu_item"}>
                <div onClick={toggleVisibility}>
                    <VisitIcon/> {translation?.homepage_post_visit}
                </div>
            </div>,
            key: '2',
        },
    ]

    useEffect(() => {
        setShowPhoneSection(false);
        setPhoneDropDownOpen(false)
    },[visible])

    function toggleFavorite(e: any) {
        e && e.preventDefault();
        e && e.stopPropagation();
        if (isLoggedIn) {
            if (!isFetching.current) {
                isFetching.current = true;
                const favoritePromise = data.isFavorite ? props.UnLikePost(data.id) : props.LikePost(data.id);
                favoritePromise.finally(() => {
                    isFetching.current = false;
                })
            }
        } else {
            navigate('/auth/sign-in')
        }
    }

    function toggleCompare(e: any) {
        e && e.preventDefault();
        e && e.stopPropagation();
        compared ? props.RemovePostFromComparedList(data.id) : props.AddPostToComparedList(data)
    }

    function toggleShare(e: any) {
        e && e.preventDefault();
        e && e.stopPropagation();
        setCopyed(false)
    }

    function onPhoneDropDownVisibleChanged(open: boolean) {
        setPhoneDropDownOpen(open)
        open && props.AddPhoneStatistics(data.id)
    }

    const shareContent = (
        <div className={'social-media'}>
            {copyed ?
                <div className={'copied-link'} onClick={e => {
                    e.stopPropagation()
                }}>
                    <CopiedIcon/>  {translation?.link_copied}
                </div> :
                <CopyToClipboard text={shareUrl}
                                 onCopy={() => setCopyed(true)}>
                    <div onClick={e => {
                        e.stopPropagation()
                    }}
                         className={'copy-share'}
                    >
                        <CopyIcon/> {translation?.link_copy}
                    </div>
                </CopyToClipboard>
            }
            <FacebookShareButton url={shareUrl} quote={"A&P"}>
                <p><ShareFacebookIcon size={18} round={true}/> Facebook</p>
            </FacebookShareButton>
            <LinkedinShareButton url={shareUrl} title={'A&P'}>
                <p><ShareLinkedinIcon size={18} round={true}/> Linkedin</p>
            </LinkedinShareButton>
        </div>
    );


    // @ts-ignore
    return (
        <Link className={`productCardGrid-wrapper ${className}`}
               to={`/post/${data.slug}`} >
            <Content onMouseEnter={() => {setShowPhoneSection(true)}}
                     onMouseLeave={() => {
                         setShowPhoneSection(false);
                         setPhoneDropDownOpen(false)
                     }}
            >
                <div className="post_content">
                    <PostImage>
                        <div className="img_wrapper">
                            <div className={'badges'}>
                                {data?.isUrgent && <div className={'urgent'}>{translation?.filter_urgent}</div>}
                                {data?.isSpecialOffer && <div className={'top'}>{translation?.homepage_post_special_offer}</div>}
                                {data?.isNew && <div className={'new_badgs'}>{translation?.homepage_post_new}</div>}
                            </div>
                            <img src={generateImageMediaUrl(data?.mediaMain?.path)} alt=""/>
                            <div className="icons_wrapper">
                                <Icon onClick={toggleFavorite}>{data.isFavorite ? <HeartIconFill/> :
                                    <HeartIcon/>}</Icon>
                                <Icon onClick={toggleCompare}>
                                    {compared ? <CompareDarkIcon/> :
                                        <CompareIcon/>}
                                </Icon>
                                <Icon onClick={toggleShare}>
                                    <Popover content={shareContent} trigger="click" placement="bottom">
                                        <ShareIcon/>
                                    </Popover>
                                </Icon>
                            </div>
                        </div>
                    </PostImage>

                    <div className={'bottom-block'}>
                        <div className={'post-description'}>
                            <div className="post_date">{moment(data.updetedAt).format("DD.MM.YYYY")}</div>
                            <div className="post_title">{data?.title}</div>
                            <div className="post_address">
                                <Card_Map/> {data?.location?.region?.title}, {data?.location?.region?.residence?.title}
                            </div>
                            {
                                location.pathname !== '/search'
                                    ? <div className="price">
                                        {
                                            data?.type &&  data?.type?.map((type: any) => {
                                                if (type === POST_TYPES.SELL.key) {
                                                    return <div className="price">{data?.priceSell ?
                                                        priceWithCommas(priceByCurrency(data.priceSell, data.currency)) :
                                                        <div className={'contractual'}>{translation?.homepage_post_price} :<span>{translation?.homepage_post_price_text}</span>
                                                        </div>}
                                                    </div>
                                                }
                                                if(type === POST_TYPES.RENT.key){
                                                    return <div className={`${data?.priceSell ? 'rentPrice' : 'price'}`}>{data?.priceRent ?
                                                        priceWithCommas(priceByCurrency(data.priceRent, data.currency)) :
                                                        <div className={'contractual'}>{translation?.homepage_post_price} :<span>{translation?.homepage_post_price_text}</span></div>}
                                                        {data?.priceRent ? data?.rentPriceType === PRICE_TYPES.MONTHLY ?
                                                            <span className={'rent'}> / {translation?.homepage_post_priceType_mount}</span> :
                                                            <span className={'rent'}> / {translation?.homepage_post_priceType_day}</span> : ""
                                                        }
                                                    </div>
                                                }
                                            })
                                        }</div> : <div className={`${type === POST_TYPES.RENT.key ? 'priceBlock' : 'price'}`}>

                                        {
                                            data?.type &&  data?.type?.map((item: any) => {
                                                if (item === POST_TYPES.SELL.key) {
                                                    return  <div className={`${ type === POST_TYPES.SELL.key ? 'price' : 'rentPrice'}`}>{data?.priceSell ?
                                                        priceWithCommas(priceByCurrency(data.priceSell, data.currency)) :
                                                        <div className={'contractual'}>
                                                            {translation?.homepage_post_price} :<span>{translation?.homepage_post_price_text}</span>
                                                        </div>}
                                                    </div>
                                                }
                                                if(item === POST_TYPES.RENT.key){
                                                    return <div className={`${type === POST_TYPES.RENT.key ? 'price' : 'rentPrice'}`}>{data?.priceRent ?
                                                        priceWithCommas(priceByCurrency(data.priceRent, data.currency)) :
                                                        <div className={'contractual'}>{translation?.homepage_post_price} :<span>{translation?.homepage_post_price_text}</span></div>}
                                                        {data?.priceRent ? data?.rentPriceType === PRICE_TYPES.MONTHLY ?
                                                            <span className={'rent'}> / {translation?.homepage_post_priceType_mount}</span> :
                                                            <span className={'rent'}> / {translation?.homepage_post_priceType_day}</span> : ""
                                                        }
                                                    </div>
                                                }
                                            })
                                        }
                                    </div>
                            }

                            <div className="information">
                                {data?.condition && Object.values(CONDITION_TYPES).map(key => {
                                    if(data?.condition === key){
                                        return <span>{translation[`cards_condition_text_${key}`]}</span>
                                    }
                                })}
                                {data?.condition && <span> • </span>}
                                {data?.floorNumber &&
                                <span>{data?.floorNumber}{translation?.cards_floorNumber_text}</span>}
                                {data.buildingFloorsCount && data?.floorNumber && <span> • </span>}
                                {data.buildingFloorsCount && <span>{data.buildingFloorsCount} {translation?.cards_buildingFloors_text}</span>}
                                {data.buildingFloorsCount && data?.roomsCount && <span> • </span>}
                                {data?.roomsCount && <span>{data.roomsCount} {translation?.cards_roomsCount_text}</span>}
                            </div>
                        </div>
                        <Dropdown menu={{items}}
                                  open={phoneDropDownOpen}
                                  trigger={['click']}
                                  onVisibleChange={onPhoneDropDownVisibleChanged}>
                            <a onClick={e => e.stopPropagation()}>
                                <MainButton title={translation?.post_showNumber_btn}
                                            className={`call_btn ${showPhoneSection ? 'show' : ''}`} light/>
                            </a>
                        </Dropdown>
                    </div>
                </div>
                <VisitModal visible={visible}
                            post={data}
                            toggleVisibility={toggleVisibility}/>
            </Content>
        </Link>
    )
}


const mapDispatchToProps = {
    LikePost,
    UnLikePost,
    AddPostToComparedList,
    RemovePostFromComparedList,
    AddPhoneStatistics,
}
const mapStateToProps = (state: any) => {
    return getPropsFromState(state, [
        'isLoggedIn',
        'selectedCurrency',
        'comparedPosts',
        'selectedLanguage',
        'translation',
    ])
};

export default connect(mapStateToProps, mapDispatchToProps)(PostCardGrid);
