// import packages
import React, {useState} from "react";
import {connect} from "react-redux";
import {DatePicker, Modal, TimePicker} from 'antd';
import moment from "moment";

// Import assets
import "./visitModal.scss"

// Import Components
import {InputGroup} from "../input-group/inputGroup";

// Import utils
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {SendVisitRequest} from "../../../redux/actions";
import {RESERVE_MODAL_TITLE_TYPES} from "../../../constants/constants";
import {CloseModalIcon} from "../../../assets/images";


function VisitModal(props) {
    const { translation,toggleVisibility,post,visible,isLoggedIn, loggedInUser} = props
    const userName = `${loggedInUser?.firstName} ${loggedInUser?.lastName}`
    const initialValues = {
        fullName: isLoggedIn ? userName : '',
        phoneNumber: "",
        time: null,
        date:null,
        post:post?.id,
    }
    const [fieldsData, setFieldsData] = useState(initialValues)
    const [errors, setErrors] = useState({
        fullName: false,
        phoneNumber: false,
        time: false,
        date:false,
    })
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [confirmBtnDisable, setConfirmBtnDisable] = useState(false);
    const [modalTitleType, setModalTitleType] = useState("");

    const confirmVisit = () => {
        if (validate()) {
            setConfirmLoading(true);
            let reqData = {
                ...fieldsData,
                time: moment(fieldsData.time).format('HH:mm')
            }

            props.SendVisitRequest(reqData).then(() => {
                setTimeout(() => {
                    setConfirmLoading(false);
                    setModalTitleType(RESERVE_MODAL_TITLE_TYPES.success);
                    setConfirmBtnDisable(true)
                }, 2000)
                setTimeout(() => {
                    closeModal()
                    setModalTitleType(RESERVE_MODAL_TITLE_TYPES.title);
                    setConfirmBtnDisable(false)
                }, 5000)
            }).catch((err) => {
                // console.log(err.status, 'err');
            })
        }
    };

    const closeModal = () => {
        toggleVisibility()
        setFieldsData(initialValues)
    };


    function getInputValues({name, value}) {
        if (name === "phoneNumber" && value && !/^[+\d]\d*$/.test(value)) {
            return
        }
        setFieldsData({
            ...fieldsData,
            [name]: value
        })

        setErrors({
            ...errors,
            [name]: false,
        })
    }

    function validate() {
        const errors = {};
        let result = true;
        Object.keys(fieldsData).forEach(key => {

            if ((key === 'fullName' && fieldsData[key]?.length === 0)) {
                errors[key] = true;
                result = false;
            }

            if (key === 'phoneNumber' && fieldsData[key]?.length <= 8){
                errors[key] = true;
                result = false;
            }
            if(!fieldsData.time){
                errors.time =true;
                result = false
            }
            if(!fieldsData.date){
                errors.date =true;
                result = false
            }

        })
        setErrors(errors);
        return result;
    }

    function getDisabledHours() {
        let hours = [];
        for (let i = 0; i < 24; i++) {
            if (i < 10 || i > 18) {
                hours.push(i);
            }
        }
        return hours;
    }

    const text = modalTitleType === RESERVE_MODAL_TITLE_TYPES.success
        ? translation?.modal_success_message : (modalTitleType === RESERVE_MODAL_TITLE_TYPES.error
            ? translation?.modal_error_message : translation?.visit_modal_title || ' ')


    return <Modal
        title={text}
        className={`visit-modal ${modalTitleType === RESERVE_MODAL_TITLE_TYPES.success ? "success" :
            (modalTitleType === RESERVE_MODAL_TITLE_TYPES.error ? "error" : "")} ${confirmBtnDisable ? "confirm-btn-disable" : ""}`}
        cancelText={confirmBtnDisable ? translation?.modal_btn_close : translation?.modal_btn_cancel}
        okText={translation?.modal_btn_save}
        visible={visible}
        onOk={!confirmBtnDisable && confirmVisit}
        confirmLoading={confirmLoading}
        closeIcon={<CloseModalIcon/>}
        onCancel={closeModal}>

        <div className={'inputs-block'}>
            <div className={'left-block'}>
                <InputGroup
                    inputType={'input'}
                    type={"text"}
                    value={fieldsData.fullName}
                    error={errors.fullName}
                    placeholder={translation?.modal_name_placeholder}
                    name={'fullName'}
                    maxLength={100}
                    label={translation?.modal_name_label}
                    onChange={getInputValues}
                />

                <InputGroup
                    inputType={'input'}
                    type={"text"}
                    value={fieldsData.phoneNumber}
                    error={errors.phoneNumber}
                    placeholder={translation?.modal_phone_placeholder}
                    name={'phoneNumber'}
                    maxLength={12}
                    label={translation?.modal_phone_label}
                    onChange={getInputValues}
                />
            </div>

            <div className='right-block'>

                { <InputGroup inputType={'wrapper'} label={translation?.visit_date_picker_placeholder}
                              error={errors.date}
                              value={fieldsData.date}>
                    <DatePicker value={fieldsData.date}
                                placeholder={'DD/MM/YYYY'}
                                className={`input-time ${errors.date ? 'error' : ""}`}
                                format={'DD/MM/YY'}
                                onChange={(date) => getInputValues( {name: 'date', value: date})}/>
                </InputGroup>}

                { <InputGroup inputType={'wrapper'} label={translation?.visit_time_picker_placeholder}
                              value={fieldsData.time}>
                    <TimePicker value={fieldsData.time}
                                placeholder={'00:00'}
                                allowClear={false}
                                className={`input-time ${errors.time ? 'error' : ""}`}
                                disabledHours={getDisabledHours}
                                minuteStep={15}
                                format={'HH:mm'}
                                popupClassName="popup-input-time"
                                onSelect={(time) => getInputValues( {name: 'time', value: time})}/>
                </InputGroup>}
            </div>
        </div>
    </Modal>

}

const mapStateToProps = (state) => getPropsFromState(state, [
    'translation',
    'loggedInUser',
    'isLoggedIn',
]);

const mapDispatchToProps = {
    SendVisitRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(VisitModal);
