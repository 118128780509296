// Import packages
import React from "react";

// Import components
import About from "./about/About";
import Banners from "./banners/Banners";
import ContactInfo from "./contact-info/ContactInfo";
import HomeSlider from "./slider/HomeSlider";
import Subscribe from "./subscribe/Subscribe";
import Currency from "./currency/Currency";
import HomeFilter from "./filter/HomeFilter";
import BestOffersSection from "../../components/post-section/BestOffersSection";
import PostSection from "../../components/post-section/PostSection";
import {getPropsFromState} from "../../redux/mapStateToProps";
import {connect} from "react-redux";
import {CategoriesItemType, PostItemType} from "../../redux/actions/actionTypes";

// Import utils.

type Props = {
    translation?:any;
    characterId?: number;
    categories?: Array<CategoriesItemType>;
    recentlyViewed?: Array<PostItemType>;
};

const Homepage: React.FC = (props: Props) => {
    const {translation} = props

    return <div className={`homepage-wrapper`}>
        <HomeSlider/>
        <HomeFilter/>
        <About/>
        <Banners/>
        <ContactInfo/>
        <BestOffersSection/>
        {!!props?.recentlyViewed?.length && <PostSection showHeader={true}
                      title={translation?.homepage_post_section_title}
                      description={translation?.homepage_post_section_description}
                      posts={props.recentlyViewed}/>}
        <Currency/>
        <Subscribe/>
    </div>
}
const mapStateToProps = (state: any) => getPropsFromState(state, [
    // names (type - string) of props
    'categories',
    'recentlyViewed',
    'translation',
]);

const mapDispatchToProps = {
    //redux actions
};
export default connect(mapStateToProps, mapDispatchToProps)(Homepage);
