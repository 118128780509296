// Import packages
import React from "react";

// Import styles
import './compare.scss';
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {connect} from "react-redux";
import {GetBestOffers} from "../../../redux/actions";
import CompareCard from "../../../components/cards/compare-card/Compare-card";
import {BackIcon} from "../../../assets/images";
import {Link} from "react-router-dom";

// Import components

//import utils

function Compare(props){

    const {comparedPosts,translation, windowSizes} = props

    return <div className={'compare_wrapper'}>
        <Link to={'/profile'} className={`${(windowSizes?.width || window.innerWidth) < 801 ? 'mobile-back' : 'back'}`}>
            <BackIcon/> <span>{translation?.profile_back_title}</span>
        </Link>
        <div className={'compare_title'}>
            {translation?.profile_compare_title}
        </div>
        {
            comparedPosts?.length ?
                <div className={'compare-products-wrapper'}>
                    <div className={'compare-products-description'}>
                        <div className={'products-text'}>
                            <div className={'inform_block'}>{translation?.profile_compare_area}</div>
                            <div className={'inform_block'}>{translation?.profile_compare_price}</div>
                            <div className={'inform_block'}>{translation?.profile_compare_rent_price}</div>
                            <div className={'inform_block'}>{translation?.profile_compare_floors}</div>
                            <div className={'inform_block'}>{translation?.profile_compare_roomsCount}</div>
                        </div>
                    </div>
                    <div className={`compare-products ${comparedPosts?.length > 2 ? 'wrappers-width' : ''}`}>
                        {
                            comparedPosts?.map(item => {
                                return <CompareCard data={item} key={item.id}/>
                            })
                        }
                    </div>
                </div> :<div className="compare-empty-view">
                    <p className={'empty-compare'}>
                        {translation?.profile_compare_empty_text}
                    </p>
                </div>
        }
    </div>

}

const mapStateToProps = (state) => getPropsFromState(state, [
    // names (type - string) of props
    'comparedPosts',
    'translation',
    'windowSizes',
]);

const mapDispatchToProps = {
    //redux actions
    GetBestOffers,
};

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(Compare);
