// Import packages
import React from "react";


// Import styles

//Import Images


// Import components
import { Modal } from "antd";

//import utils

export function ViewModal (props) {
    const {  visible,toggleVisibility,post} = props;

    const closeModal = () => {
        toggleVisibility()
    };



    return <Modal
        open={visible}
        className={'view-modal'}
        onCancel={closeModal}
    >
        <div className={"iframe-wrapper"}>
            <iframe src={post?.panoramaView}
                    frameBorder={"0px"}
            />
        </div>
    </Modal>
}
export default ViewModal
