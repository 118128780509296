// Import packages
import React, {useState} from 'react';
import {Link} from 'react-router-dom'

//Import styles
import "./footer.scss";
import {
    FbIcon,
    FooterArrIcon,
    FooterCallIcon,
    FooterPhoneIcon,
    InstagramIcon,
    LinkedinIcon, PhoneDropDownIcon,
    SolidyLogo, ViberIcon, WhatsUppIcon
} from "../../assets/images";
import {getPropsFromState} from "../../redux/mapStateToProps";
import {connect} from "react-redux";
import {Dropdown, Menu} from "antd";

//Import images


//Import utils

const Footer: React.FC = (props: any) => {
    const [visible, setVisible] = useState<boolean>(false);
    const {translation, contact} = props

    const toggleVisibility = () => {
        setVisible(!visible)
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
    const callMenu = (
        <Menu>
            <Menu.Item className={'callDropDown'}>
                <a href={`tel:+${contact.phoneNumber}`}>
                    <PhoneDropDownIcon/>
                    <span>{`+${contact.phoneNumber}`}</span>
                </a>
                <a href={`viber://chat?number=%2B${contact.phoneNumber}`} target={'_blank'}>
                    <ViberIcon/>
                    <span>{`+${contact.phoneNumber}`}</span>
                </a>
                <a href={`https://wa.me/+${contact.phoneNumber}`} target={'_blank'}>
                    <WhatsUppIcon/>
                    <span>{`+${contact.phoneNumber}`}</span>
                </a>
            </Menu.Item>
        </Menu>
    );

    return (
        <footer ref={props.refProp}>
            <div className="footer">
                <div className="footer__content">
                    <div className="footer__links">
                        <Link to={'/about'} className="footer__links__item">{translation?.footer_about}</Link>
                        <Link to={'/search'} className="footer__links__item">{translation?.footer_apartments}</Link>
                        <Link to={'/faq'} className="footer__links__item">{translation?.footer_faq}</Link>
                        <Link to={`/privacy-policy`} className="footer__links__item">{translation?.footer_privacy}</Link>
                        <Link to={'/contact'} className="footer__links__item">{translation?.footer_cooperation}</Link>
                    </div>
                    {/*<div className="footer__links">*/}
                    {/*    <div className="footer__links__title">{translation?.footer_social}</div>*/}
                    {/*    <a className="footer__links__item" href={contact?.facebookUrl}  target={"_blank"}  rel="noopener noreferrer" >*/}
                    {/*        {translation?.footer_social_facebook}</a>*/}
                    {/*    <a className="footer__links__item" href={contact?.instagramUrl} target={"_blank"}  rel="noopener noreferrer" >*/}
                    {/*        {translation?.footer_social_instagram}</a>*/}
                    {/*    <a className="footer__links__item" href={contact?.linkedinUrl}  target={"_blank"}  rel="noopener noreferrer" >*/}
                    {/*        {translation?.footer_social_linkedin}</a>*/}
                    {/*</div>*/}
                    <div className="footer__links">
                        <Link to={'/contact'} className="footer__links__item">{translation?.footer_contact}</Link>
                        {contact?.email && <a className="footer__links__item" href={`mailto:${contact.email}`}>{contact.email}</a>}
                        {contact?.phoneNumber && <Dropdown overlay={callMenu}
                                                           overlayClassName={''}
                                                           trigger={['click']}
                                                           className={'contact-dropDown'}
                                                           placement="bottom">
                            <div className="footer__links__item">
                                <FooterCallIcon/> {contact.phoneNumber}</div>
                        </Dropdown>}
                        {contact?.phoneNumber1 && <a className="footer__links__item"
                            href={`tel:${contact.phoneNumber1}`}><FooterPhoneIcon/> {contact.phoneNumber1}</a>}
                    </div>
                    <div className="social_wrapper">
                        <div className="social_wrapper__title">{translation?.footer_follow_us}</div>
                        <div className="social_items">
                            <a href={contact?.facebookUrl} target='_blank' rel="noopener noreferrer">
                                <FbIcon className={'social_icon'}/>
                            </a>
                            <a href={contact?.instagramUrl} target='_blank' rel="noopener noreferrer">
                                <InstagramIcon className={'social_icon'}/>
                            </a>
                        </div>
                    </div>
                </div>

                <div className="right_part">
                    <button className="call-btn" onClick={toggleVisibility}>
                        <FooterArrIcon/>
                    </button>
                    <div className="develop_wrapper">
                        {translation?.footer_developed_by_text} <a href={'https://solidy.am'} target={"_blank"}><SolidyLogo/></a>
                    </div>
                </div>
            </div>
            <div className="copyright">{translation?.footer_copyright_text}</div>
        </footer>
    );
}
const mapDispatchToProps = {
}
const mapStateToProps = (state: any) => {
    return getPropsFromState(state, [
        'translation',
        'contact'
    ])
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
