import React, {useEffect, useState} from "react";
import './clock.scss'
import Clock from "./Clock";
import {TIME_CITY} from "../../../constants/constants";
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {connect} from "react-redux";

type TimeTypes = {
    hourRatio: any;
    minuteRatio: number;
    secondRatio: number;
    city: string;
    key: string;
};

const ClockBlocks: React.FC = (props: any) => {
    const {translation} = props
    const [times, setTimes] = useState<Array<TimeTypes>>([])
    const cityList = Object.keys(TIME_CITY).filter(x => !(parseInt(x) >= 0));

    useEffect(() => {
        setTime()
        setInterval(() => {
            setTime()
        }, 1000)
    }, [])

    function setTime() {
        const currentData = new Date();
        let value: Array<TimeTypes> = []

        cityList?.forEach(item => {
            let time: TimeTypes | any = {}
            time.secondRatio = currentData.getUTCSeconds() / 60;
            time.minuteRatio = (time.secondRatio + currentData.getUTCMinutes()) / 60;
            // @ts-ignore todo
            time.hourRatio = (time.minuteRatio + currentData.getUTCHours() + TIME_CITY[item]) / 12
            time.key = item
            value.push(time)
        })
        setTimes(value)
    }

    return <div className={'clocks-block'}>
        {times?.map((item: any) => {
            return <Clock key={item.key}
                          translation={translation}
                          secondRatio={item?.secondRatio}
                          minuteRatio={item?.minuteRatio}
                          hourRatio={item?.hourRatio}
                          city={item.key}/>
        })}
    </div>

}
const mapDispatchToProps = {
}
const mapStateToProps = (state: any) => {
    return getPropsFromState(state, [
        'translation'
    ])
};

export default connect(mapStateToProps, mapDispatchToProps)(ClockBlocks);
