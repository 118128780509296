// Import packages
import React from "react";
import {connect} from "react-redux";

// Import assets
import "./banners.scss"
import {RightIcon} from "../../../assets/images";

// Import components
import {MainButton} from "../../../components/ui-elements/button/Buttons";

// Import utils.
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {generateImageMediaUrl} from "../../../utils/generateMediaUrl";
import {Link} from "react-router-dom";

const Banners: React.FC = (props: any) => {
    const {translation, banners} = props

    return <div className={`banners`}>
        <div className="banners__title">{translation?.homepage_banners_title}</div>

        <div className="banners__wrapper">
            <Link target={'_blank'} to={banners?.[0]?.posts?.[0]?.slug ? `/post/${banners?.[0]?.posts?.[0]?.slug}` : ''}
                  className="offer_item">
                <img className="offer_item__bg_img" src={generateImageMediaUrl(banners?.[0]?.mediaMain?.path)} alt=""/>
                <div className="offer_item__content">
                    <div className="offer_item__title">{banners?.[0]?.title}</div>
                    <div className="offer_item__description">
                        {banners?.[0]?.description}
                    </div>
                </div>
            </Link>

            <Link to={banners?.[1]?.posts?.[0]?.slug ? `/post/${banners?.[1]?.posts?.[0]?.slug}` : ''} target={ "_blank"}
                  className="offer_item_more">
                <img src={generateImageMediaUrl(banners?.[1]?.mediaMain?.path)} alt=""/>
                <div className="offer_item_more__content">
                    <div className="offer_item_more__title">{banners?.[1]?.title}</div>
                    <div className="offer_item_more__description">
                        {banners?.[1]?.description}
                    </div>
                    <MainButton
                                title={<>
                                    {translation?.homepage_banners_btn_title} <RightIcon/>
                                </>}
                                className={'offer_item_more__btn'}/>
                </div>
            </Link>
        </div>
    </div>
}

const mapStateToProps = (state: any) => getPropsFromState(state, [
    // names (type - string) of props
    'banners',
    'translation',
]);

const mapDispatchToProps = {
    //redux actions
};

export default connect(mapStateToProps, mapDispatchToProps)(Banners);
