import axios from "axios";
import { store } from "../store";
import { USER_CONSTS, UTIL_CONSTS } from "../constants";
import { RefreshToken } from "../actions";

export const request = ({
                            url,
                            method = "GET",
                            data = null,
                            token = false,
                            customHeaders = null,
                            languageFlag = false,
                            newToken = null
                        }) => {
    const storeAccessToken = store.getState()?.user?.accessToken;
    const accessToken = newToken || storeAccessToken;
    let headers = {
        "Accept": "application/json",
        "Content-Type": "application/json",
    };
    customHeaders && (headers = { ...headers, ...customHeaders });
    token && accessToken && (headers["x-access-token"] = accessToken);
    let language = store.getState()?.general?.selectedLanguage;
    //console.log(language)
    if (languageFlag) {
        headers["Accept-Language"] = language?.code ?? 'hy'
    }
    const requestData = {
        url: url,
        method: method,
        headers: headers,
    };

    data && (requestData.data = data);
    return axios.request(requestData)
};

// log user out when token expires

axios.interceptors.response.use(response => {
        store.dispatch({
            type: UTIL_CONSTS.END_LOADING
        });
        return response;
    },
    async error => {
        const logEnable = false;
        const originalRequest = error.config;
        logEnable && console.log('originalRequest', error);
        if (originalRequest?.url?.slice(-13) === "refresh-token") {
            // if refresh token has been expired
            logEnable && console.log('refresh token has been expired');
        } else if (error.response && error.response.status === 401) {
            logEnable && console.log('start refreshing in interceptor');
            return RefreshToken().then((accessToken) => {
                logEnable && console.log('refresh-success', accessToken);
                if (originalRequest?.url?.includes("authenticate")) {
                    logEnable && console.log('is authenticate request')
                    return;
                }
                // originalRequest.headers['x-access-token'] = accessToken;
                originalRequest.headers = {
                    ...originalRequest.headers.toJSON(),
                    'x-access-token': accessToken
                };
                return axios(originalRequest)
            }).catch((err) => {
                logEnable && console.log('refresh-fulfilled', err, originalRequest);
                // update token fulfilled
                store.dispatch({
                    type: USER_CONSTS.LOG_OUT
                });
                store.dispatch({
                    type: UTIL_CONSTS.END_LOADING
                });
                return Promise.reject(error);
            });
        }
        store.dispatch({
            type: UTIL_CONSTS.END_LOADING
        });
        return Promise.reject(error);
    }
);
