import React, { useRef, useState} from "react";
import styled from "styled-components";
import {useNavigate} from "react-router-dom";
import {
    CompareIcon,
    HeartIcon,
    HeartIconFill,
    ShareIcon, PhoneIcon, CallIcon, Card_Map, ClearIcon,
    CompareDarkIcon, CopyIcon, ShareLinkedinIcon, ShareFacebookIcon, CopiedIcon, VisitIcon
} from "../../../assets/images";
import {MainButton} from "../../ui-elements/button/Buttons";
import {generateImageMediaUrl} from "../../../utils/generateMediaUrl";
import {Dropdown,Popover} from "antd";
import {PHONE_TYPES, POST_TYPES, PRICE_TYPES} from "../../../constants/constants";
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {connect} from "react-redux";
import {AddPostToComparedList, LikePost, RemovePostFromComparedList, UnLikePost} from "../../../redux/actions";
import './compare-card.scss'
import {
    FacebookShareButton,
    LinkedinShareButton,
} from "react-share";
import {WEB_PAGE_URL} from "../../../redux/api";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {priceWithCommas} from "../../../utils/helperFunctions";
import {priceByCurrency} from "../../../utils/priceByCurrency";
import VisitModal from "../../ui-elements/modal/VisitModal";


const ProductImage = styled.div`
  width:100%;

  .img_wrapper {
    width: 100%;
    padding-top: 100%;
    position: relative;
    overflow: hidden;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 1s;
      display: block;

      &:hover {
        transform: scale(1.2);
      }
    }

    .icons_wrapper {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
    }
    .badges{
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .urgent {
        width: 66px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #B79B7A;
        color: white;
        height: 33px;
        padding: 0 6px;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .top {
        width: 66px;
        height: 33px;
        min-width: 33px;
        padding: 0 6px;
        background-color: #CDBB99;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
      }
    }
  }

`;

const Icon = styled.button`
  width: 32px;
  height: 32px;
  backdrop-filter: blur(2px);
  background-color: rgba(205, 187, 153, 0.7);
  overflow: hidden;
  position: relative;
  margin-bottom: 1px;

  svg {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
  }
`;

const Content = styled.div`
  position: relative;
  height: 412px;

  @media (max-width: 1050px) {
    height: unset;
  }
`;


const CompareCard = (props) => {
    const { isLoggedIn, data, comparedPosts,selectedLanguage,translation} = props
    // console.log(data,'data')
    const [copyed, setCopyed] = useState(false);
    const shareUrl = `${WEB_PAGE_URL}/post/${data.slug}?lg=${selectedLanguage?.code}`
    const navigate = useNavigate();
    const isFetching = useRef(false)
    const [visible, setVisible] = useState(false);
    const compared = !!comparedPosts?.find((p) => p.id === data?.id);

    const toggleVisibility = () => {
        setVisible(!visible)
    }

    const items =[
        {
            label: data?.contacts?.map((item) => {
                return  <a onClick={e => e.stopPropagation()}
                           className={"lang_menu_item"}
                           href={`tel:${item?.phone}`}>
                    {PHONE_TYPES.HOME.key === item.type ? <PhoneIcon/> : <CallIcon/>} {item.phone}
                </a>
            }),
            key: '1',
        },
        {
            type: 'divider',
        },
        {
            label: <div onClick={e => e.stopPropagation()}
                        className={"lang_menu_item"}>
                <div onClick={toggleVisibility}>
                    <VisitIcon/> {translation?.homepage_post_visit}
                </div>
            </div>,
            key: '2',
        },
    ]
    
    function toggleFavorite(e) {
        e && e.preventDefault();
        e && e.stopPropagation();
        if (isLoggedIn) {
            if (!isFetching.current) {
                isFetching.current = true;
                const favoritePromise = data.isFavorite ? props.UnLikePost(data.id) : props.LikePost(data.id);
                favoritePromise.finally(() => {
                    isFetching.current = false;
                })
            }
        } else {
            navigate('/auth/sign-in')
        }
    }
    function toggleCompare(e) {
        e && e.preventDefault();
        e && e.stopPropagation();
        compared ? props.RemovePostFromComparedList(data.id) : props.AddPostToComparedList(data)
    }
    function toggleShare(e) {
        e && e.preventDefault();
        e && e.stopPropagation();
        setCopyed(false)
    }

    const shareContent = (
        <div className={'social-media'}>
            {copyed ?
                <div className={'copied-link'} onClick={e => {
                    e.stopPropagation()
                }}>
                    <CopiedIcon/>  {translation?.link_copied}
                </div> :
                <CopyToClipboard text={shareUrl}
                                 onCopy={() => setCopyed(true)}>
                    <div onClick={e => {
                        e.stopPropagation()
                    }}
                         className={'copy-share'}
                    >
                        <CopyIcon/> {translation?.link_copy}
                    </div>
                </CopyToClipboard>
            }
            <FacebookShareButton url={shareUrl} quote={"A&P"}>
                <p><ShareFacebookIcon size={18} round={true}/> Facebook</p>
            </FacebookShareButton>
            <LinkedinShareButton url={shareUrl} title={'A&P'} >
                <p><ShareLinkedinIcon size={18} round={true}/> Linkedin</p>
            </LinkedinShareButton>
        </div>
    );

    const  location = () => {
        if(!visible){
            navigate(`/post/${data.slug}`)
        }
    }

    return (
        <div  className={'compare-card'}
                     onClick={location}
        >
            <Content>
                <div className={'clear_icon'} onClick={toggleCompare}><ClearIcon/></div>
                <div className="product_content">
                    <ProductImage>
                        <div className="img_wrapper">
                            <div className={'badges'}>
                                {data?.isUrgent && <div className={'urgent'}>{translation?.filter_urgent}</div>}
                                {data?.isSpecialOffer && <div className={'top'}>{translation?.homepage_post_special_offer}</div>}
                                {data?.isNew && <div className={'new_badgs'}>{translation?.homepage_post_new}</div>}
                            </div>
                            <img src={generateImageMediaUrl(data?.mediaMain?.path)} alt=""/>
                            <div className="icons_wrapper">
                                <Icon onClick={toggleFavorite}>{data.isFavorite ? <HeartIconFill/> :
                                    <HeartIcon/>}</Icon>
                                <Icon onClick={toggleCompare}>
                                    {compared ? <CompareDarkIcon/> :
                                        <CompareIcon/>}
                                </Icon>
                                <Icon onClick={toggleShare}>
                                    <Popover content={shareContent} trigger="click" placement="bottom">
                                        <ShareIcon/>
                                    </Popover>
                                </Icon>
                            </div>
                        </div>
                    </ProductImage>
                    <div className="product_title">{data?.title}</div>
                    <div className="product_address">
                        <Card_Map/> {data?.location?.region?.title}, {data?.location?.region?.residence?.title}
                    </div>
                    {/*todo change to custom dropdown*/}
                    <Dropdown menu={{items}} trigger={['hover']}>
                        <a onClick={e => e.stopPropagation()}>
                            <MainButton title={translation?.post_showNumber_btn} className="call_btn" light/>
                        </a>
                    </Dropdown>

                </div>
                <div className={'content_description'}>
                    <div className={'inform_block'}>{data?.area} {translation?.filter_measurement_unit_meter}<sup>2</sup></div>
                    <div className={'inform_block'}>
                            {
                                data?.type.length > 1 || data?.type[0] === POST_TYPES.SELL.key ? data?.type.map((type) => {
                                    if(type === POST_TYPES.SELL.key){
                                        return <div className="price">{data?.priceSell ?
                                            priceWithCommas(priceByCurrency(data.priceSell, data.currency)) :
                                            <div className={'contractual'}>
                                                {translation?.homepage_post_price} :<span>{translation?.homepage_post_price_text}</span>
                                            </div>
                                        }</div>
                                    }
                                }) : "-"
                            }
                    </div>
                    <div className={'inform_block'}>
                            {
                                data?.type.length > 1 || data?.type[0] === POST_TYPES.RENT.key ?
                                data?.type.map((type) => {
                                    if(type === POST_TYPES.RENT.key){
                                        return <div className={`${data?.priceSell ? 'rentPrice' : 'price'}`}>{data?.priceRent ?
                                            priceWithCommas(priceByCurrency(data.priceRent, data.currency)) :
                                            <div className={'contractual'}>{translation?.homepage_post_price} :<span>{translation?.homepage_post_price_text}</span></div>}
                                            {data?.priceRent ? data?.rentPriceType === PRICE_TYPES.MONTHLY ?
                                                <span className={'rent'}> / {translation?.homepage_post_priceType_mount}</span> :
                                                <span className={'rent'}> / {translation?.homepage_post_priceType_day}</span> : ""
                                            }
                                        </div>
                                    }
                                }) : "-"
                            }
                    </div>
                    {data?.floorNumber ?
                        <div className={'inform_block'}>{data?.floorNumber} / {data?.buildingFloorsCount}</div> :
                        <div className={'inform_block'}>-</div>
                    }
                    <div className={'inform_block'}>{data?.roomsCount ? data.roomsCount : '-'}</div>
                </div>
                <VisitModal visible={visible}
                            post={data}
                            toggleVisibility={toggleVisibility}/>
            </Content>
        </div>
    )
}


const mapDispatchToProps = {
    LikePost,
    UnLikePost,
    AddPostToComparedList,
    RemovePostFromComparedList
}
const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'isLoggedIn',
        'selectedCurrency',
        'comparedPosts',
        'selectedLanguage',
        'translation'
    ])
};

export default connect(mapStateToProps, mapDispatchToProps)(CompareCard);
