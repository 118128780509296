import {combineReducers} from "redux";

// import reducers
import utils from "./utils";
import user from "./user";
import general from "./general";
import post from "./posts";
import contacts from "./contacts";
import notifications from "./notifications";

export default combineReducers({
    utils,
    user,
    general,
    post,
    contacts,
    notifications,
});
