export const GENERAL_CONSTS = {
    GET_LANGUAGES: "GET_LANGUAGES",
    CHANGE_LANGUAGE: "CHANGE_LANGUAGE",
    CHANGE_CURRENCY: "CHANGE_CURRENCY",
    GET_STATIC_TEXTS: "GET_STATIC_TEXTS",
    GET_CATEGORIES: "GET_CATEGORIES",
    GET_FILTERS: "GET_FILTERS",
    GET_INFORMATION: "GET_INFORMATION",
    GET_RATES: "GET_RATES",
    GET_SLIDER: "GET_SLIDER",
    GET_BANNERS: "GET_BANNERS",
    GET_FAQ: "GET_FAQ",
    PRICE_TYPE: "PRICE_TYPE",
    GET_REGIONS: "GET_REGIONS",
    GET_RESIDENCES: "GET_RESIDENCES",
};
