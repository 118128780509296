// Import packages
import React from "react";


// Import assets
import {AboutIcon_1, AboutIcon_2, AboutIcon_3} from "../../../assets/images";
import "./about.scss"
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {connect} from "react-redux";

// Import components

// Import utils.



const HomeAbout: React.FC = (props: any) => {
    const {translation} = props

    const aboutList = [
        {
            id: 'dssadsafsfsfasda',
            icon: <AboutIcon_1/>,
            title:`${translation?.homepage_aboutList_firstTitle}`,
            description:`${translation?.homepage_aboutList_firstDescription}`,
        }, {
            id: 'fdsfjiewjmlfluriew',
            icon: <AboutIcon_2/>,
            title:`${translation?.homepage_aboutList_secondTitle}`,
            description:`${translation?.homepage_aboutList_secondDescription}`,
        }, {
            id: 'nabduivtisdewpkffjs',
            icon: <AboutIcon_3/>,
            title: `${translation?.homepage_aboutList_thirdTitle}`,
            description:`${translation?.homepage_aboutList_thirdDescription}`,
        },
    ]

    return <div className={`home_about`}>
        <div className="home_about__title">{translation?.homepage_about_title}</div>

        <div className="home_about__list">
            {!!aboutList.length && aboutList.map(item => {
                return <div className="home_about__list__item" key={item?.id}>
                    <div className="item_icon">{item?.icon}</div>
                    <div className="item_content">
                        <div className="item_title">{item?.title}</div>
                        <div className="item_description">{item?.description}</div>
                    </div>
                </div>
            })}
        </div>
    </div>
}
const mapDispatchToProps = {
}
const mapStateToProps = (state: any) => {
    return getPropsFromState(state, [
        'translation'
    ])
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeAbout);
