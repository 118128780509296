import React from 'react';
import { Link, useNavigate } from "react-router-dom";

//assets
import './main-header.scss'
import {
    HeartUserIcon, LogOutUser, LogOutUserIcon,
    NotificationIcon,
    ProjectLogo, ProjectMobileLogo,
    UnionIcon
} from "../../../assets/images";

//components
import {MainButton} from "../../ui-elements/button/Buttons";
import ClockBlocks from "../../ui-elements/clock/ClockBlocks";
import { Dropdown,Menu,Avatar } from 'antd';
import {generateImageMediaUrl} from "../../../utils/generateMediaUrl";
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {connect} from "react-redux";


function MainHeader(props) {
    const {isLoggedIn, loggedInUser,translation,
        comparedPosts, nonOpenedNotificationCount} = props
    const navigate = useNavigate();
    const userName = loggedInUser?.firstName.slice(0,1)
    const onLogOut = ()=>{
        props.LogOut()
        navigate('/')
    }
    const User =(
        <Menu items={[
            {
                key: '0',
                label:<Link to={'/profile/settings'} className={'user'}>
                    <Avatar size={40}
                            src={loggedInUser?.profilePicturePath?.path ? generateImageMediaUrl(loggedInUser?.profilePicturePath?.path) : ''}
                            style={{ color: '#BCA477', backgroundColor: '#CDBB9940'}}>
                        {loggedInUser?.profilePicturePath?.path ? <img src={generateImageMediaUrl(loggedInUser?.profilePicturePath?.path)} alt=""/> : userName}
                    </Avatar>
                    <div className={'user_info'}>
                        <span className={'fullName'}>
                            {loggedInUser?.firstName} {loggedInUser?.lastName}
                        </span>
                        <span className={'email'}>
                            {loggedInUser?.username}
                        </span>
                    </div>
                </Link>
            },
            {
                type: 'divider',
            },
            {
                key: '1',
                label:<div >
                    <Link to={'/profile/compare'} className={'users_tools'}>
                        <span className={'count'}>
                        <UnionIcon/>
                            {comparedPosts?.length > 0 ?
                                <p>{comparedPosts?.length}</p> : ""
                            }
                    </span>
                        <div className={'tools-link'}>
                            {translation?.menu_header_compare}
                        </div>
                    </Link>
                </div>
            },
            {
                key: '2',
                label:<div>
                    <Link to={'/profile/favorites'} className={'users_tools'}>
                        <span className={'count'}>
                        <HeartUserIcon/>
                    </span>
                        <div className={'tools-link'}>
                            {translation?.menu_header_favorite}
                        </div>
                    </Link>
                </div>
            },
            {
                key: '3',
                label:<div>
                    <Link to={'/profile/notifications'} className={'users_tools'}>
                        <span className={'count'}><NotificationIcon/>
                            {nonOpenedNotificationCount > 0 ?
                                <p>{nonOpenedNotificationCount}</p> : ""
                            }
                    </span>
                        <div  className={'tools-link'}>
                            {translation?.menu_header_notification}</div>
                    </Link>
                </div>
            },
            {
                key: '4',
                label:<div className={'users_tools'} onClick={onLogOut}>
                     <span className={'count'}>
                          <LogOutUserIcon/>
                    </span>
                    <span className={'tools-link'}>{translation?.menu_header_log_out}</span>
                </div>
            },

        ]}/>
    );

    return (
        <div className="main_header">
            <Link to={'/'} className="main_header__logo_mobile">
                <ProjectMobileLogo className="main_header__logo_mobile"/>
            </Link>
                <div className={'mobile_block'}>
                    <MainButton link={'/urgent'} className="menu-item" bordered={true} title={translation?.menu_header_urgentBtn_title}/>
                    {!isLoggedIn ?
                        <div className={'sine-in'}>
                            <Link to={'/compare'}><UnionIcon/>
                                {comparedPosts?.length > 0 ?
                                    <p>{comparedPosts?.length}</p> : ""
                                }
                            </Link>
                            <MainButton link={'/auth/sign-in'} title={<LogOutUser/>} className={'mobile-signIn'}/>
                        </div>
                        : <Dropdown overlay={User}
                                    trigger={['click']}
                                    placement="bottomRight">
                            <div className={'user_dropdown'}>
                                <Avatar size={40}
                                        src={loggedInUser?.profilePicturePath?.path ? generateImageMediaUrl(loggedInUser?.profilePicturePath?.path) : ''}
                                        style={{ color: '#BCA477', backgroundColor: '#CDBB9940'}}>
                                    {loggedInUser?.profilePicturePath?.path ? <img src={generateImageMediaUrl(loggedInUser?.profilePicturePath?.path)} alt=""/> : userName}
                                </Avatar>
                            </div>
                        </Dropdown>
                    }
                </div>
            <div className="main_header__left_part">
                <Link to={'/'}>
                    <ProjectLogo className="main_header__logo"/>
                </Link>
                <ClockBlocks/>
            </div>
            <div className="main_header__menu">
                {/*<Dropdown overlay={menuHeader}*/}
                {/*          trigger={['hover']}*/}
                {/*          placement="bottom">*/}
                {/*    <div className={'header_dropdown'}>*/}
                {/*        <span className={'header_dropdown__text'}>{translation?.menu_header_dropdown_title}</span>*/}
                {/*        <HeaderDropdownIcon/>*/}
                {/*    </div>*/}
                {/*</Dropdown>*/}
                <MainButton link={'/urgent'} className="menu-item" bordered={true} title={translation?.menu_header_urgentBtn_title}/>
                {!isLoggedIn ? <div className={'sine-in'}>
                        <Link to={'/compare'}><UnionIcon/>
                            {comparedPosts?.length > 0 ?
                                <p>{comparedPosts?.length}</p> : ""
                            }
                        </Link>
                        <MainButton link={'/auth/sign-in'} title={<LogOutUser/>} className={'mobile-signIn'}/>
                    </div> :
                        <Dropdown overlay={User}
                                  trigger={['hover']}
                                  placement="bottomLeft">
                            <div className={'user_dropdown'}>
                                <Avatar size={40}
                                        src={loggedInUser?.profilePicturePath?.path ? generateImageMediaUrl(loggedInUser?.profilePicturePath?.path) : ''}
                                        style={{ color: '#BCA477', backgroundColor: '#CDBB9940'}}>
                                    {loggedInUser?.profilePicturePath?.path ? generateImageMediaUrl(loggedInUser?.profilePicturePath?.path) : userName}
                                </Avatar>
                                <div className={'user_dropdown__fullName'}>
                                    {loggedInUser?.firstName} {loggedInUser?.lastName}
                                </div>
                            </div>
                        </Dropdown>
                }
            </div>
        </div>
    );
}
const mapStateToProps = (state) => getPropsFromState(state, [
    'translation',
    'nonOpenedNotificationCount',
    'comparedPosts',
]);

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps) (MainHeader);
