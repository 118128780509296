import React from "react";
import styled from "styled-components";
import {useNavigate} from "react-router-dom";
import {MainButton} from "../ui-elements/button/Buttons";

interface Props {
    className?: string;
    display?: string;
    seeAllButton?: boolean;
    flexDirection?: string;
    alignItems?: string;
    maxWidth?: string;
    bordered?: boolean;
    title?: string;
    description?: string;
    translation?:any;
}

const Section = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin-bottom: 46px;

  .section_btn{
    &:hover{
      background-color: #1F2226;
      color: white;
    }
  }

  .section_content {
    width: 60%;
    color: black;

    &__title {
      font-size: 26px;
      line-height: 28px;
      letter-spacing: 0.02em;
      font-weight: 500;
      margin-bottom: 20px;
    }
    
    &__description{
      font-size: 20px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
    }
  }

  @media all and (max-width: 1050px) {
    .section_content {
      width: 100%;
      padding: 0 20px;
    }

    .section_btn {
      display: none;
    }
  }
`;

export const SectionHeader: React.FC<Props> = ({
                                                   className,
                                                   title,
                                                   description,
                                                    translation,
                                                   seeAllButton
                                               }) => {

    const navigate = useNavigate();

    return (
        <Section className={className}>
            <div className="section_content">
                <div className="section_content__title">{title}</div>
                <div className="section_content__description">{description}</div>
            </div>
            {seeAllButton && <MainButton link={'/search'} title={translation?.homepage_seeOll_btnTitle} className="section_btn" bordered/>}
        </Section>
    )
}
