import * as React from "react";
import {default as Slider} from "react-slick";

//import assets
import './slider.scss'
import { ArrowLeftIcon, ArrowRightIcon, defaultFileLogo } from "../../../assets/images";

//import utils
import {generateImageMediaUrl} from "../../../utils/generateMediaUrl";
import {ImagesSlider} from "../../../components/ui-elements/image-slider/ImageSlider";
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {
    AddPhoneStatistics,
    AddPostToComparedList,
    GetPostById,
    LikePost,
    RemovePostFromComparedList,
    UnLikePost
} from "../../../redux/actions";
import {connect} from "react-redux";
import ViewModal from "../../../components/ui-elements/modal/viewModal";

class PostSlider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            slideIndex: 0,
            openModal: false,
            loadedImg: false,
            sliderCircleEnded: false,
            imagesSliderOpen: false,
            modalCurrentImageIndex: 0,
        };
        this.handleImageLoaded = this.handleImageLoaded.bind(this);
        this.openImagesSlider = this.openImagesSlider.bind(this);
        this.closeImagesSlider = this.closeImagesSlider.bind(this);
    }

    toggleOpenModal = () => {
        this.setState({
            openModal: !this.state.openModal
        })
    }

    nextSlide = (index) => {
        const {medias} = this.props;
        this.setState({
            slideIndex: this.state.slideIndex - 1 === index ? 0 : index + 1,
        });
        if (medias?.length - 1 === this.state.slideIndex) {
            this.setState({
                slideIndex: 0,
            });
        }
        this.slider.slickNext();
    };

    prevSlide = (index) => {
        const {medias} = this.props;
        this.setState({
            slideIndex: index === 0 ? medias?.length - 1 : index - 1,
        });
        this.slider.slickPrev();
    };

    handleImageLoaded() {
        if (!this.state.loadedImg) {
            this.setState({loadedImg: true});
        }
    }

    afterChangeSlideIndex = () => {
        this.setState({
            sliderCircleEnded: false,
        })
    };

    beforeChangeSlideIndex = (_, slideIndex) => {
        this.setState({
            slideIndex,
            sliderCircleEnded: true,
        })
    };

    openImagesSlider(imageIndex) {
        this.setState({
            ...this.state,
            imagesSliderOpen: true,
            modalCurrentImageIndex: imageIndex,
        })
    }

    closeImagesSlider() {
        this.setState({
            imagesSliderOpen: false,
        })

        setTimeout(() => {
            this.setState({
                modalCurrentImageIndex: 0,
            })
        }, 150)
    }

    render() {
        const {slideIndex} = this.state;
        const {modalCurrentImageIndex, imagesSliderOpen} = this.state;
        const {medias,translation, loading,post,contact} = this.props;

        const defaultSettings = {
            dots: false,
            arrows: false,
            infinite: true,
            speed: 400,
            lazyLoad: 'progressive',
            swipeToSlide: true,
            swipe: true,
            afterChange: this.afterChangeSlideIndex,
            beforeChange: this.beforeChangeSlideIndex,
            // ref: slider => (this.slider = slider),
            slidesToShow: 1,
            slidesToScroll: 1,
            fade: true,
        };

        return <div className="post_slider">
            <Slider
                {...defaultSettings}
                ref={(component) => {
                    this.slider = component;
                }}
            >
                {!loading && (medias?.length ? medias?.map((item) => {
                    return <div key={item.id} className={`slider-item`}>
                        <img src={generateImageMediaUrl(item?.path)}
                             onClick={() => this.openImagesSlider(slideIndex)}
                             alt={""}/>

                        {medias?.length !== 1 && <>
                            <div className={'slider-btn prev-btn'}
                                 onClick={() => this.prevSlide(slideIndex)}>
                                <ArrowLeftIcon/>
                            </div>
                            <div className={'slider-btn next-btn'}
                                 onClick={() => this.nextSlide(slideIndex)}>
                                <ArrowRightIcon/>
                            </div>
                        </>}
                        <div className="buttons">
                            {medias?.length &&
                                <button className="buttons__all"
                                        onClick={() => this.openImagesSlider(slideIndex)}
                                >{translation?.post_slider_seeOll} {medias?.length}</button>}
                            {post?.panoramaView &&
                                <button className="buttons__view_360" onClick={this.toggleOpenModal}>
                                    360° view
                                </button>
                            }
                        </div>
                    </div>
                })
                : <img src={defaultFileLogo} alt=""/>)}
            </Slider>

            {imagesSliderOpen && <ImagesSlider visible={imagesSliderOpen}
                                               title={'Photos : 58m2 3-room apartment'}
                                               contact={contact}
                                               translation={translation}
                                               modalImagePathList={this.props.medias}
                                               modalCurrentImageIndex={modalCurrentImageIndex}
                                               closeModal={this.closeImagesSlider}/>}
            <ViewModal visible={this.state.openModal}
                       post={post}
                       toggleVisibility={this.toggleOpenModal}/>

        </div>;
    }
}
const mapStateToProps = (state) => getPropsFromState(state, [
    'translation',
    'contact'
]);


const mapDispatchToProps = {
    //redux actions
};

export default connect(mapStateToProps, mapDispatchToProps)(PostSlider);
