import * as React from "react";
import {default as Slider, Settings} from "react-slick";
import { RightSliderIcon,} from "../../../assets/images";

import './home-slider.scss'

import {ProgressBar} from '../../../components/ui-elements/progress-bar/ProgressBar'
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {connect} from "react-redux";
import {generateImageMediaUrl} from "../../../utils/generateMediaUrl";
import {Link} from "react-router-dom";

type PropsTypes = {
    //todo change any type
    mainSlider?: any;
    staticTexts?: any;
    windowSizes?: any;
};

type StateTypes = {
    slideIndex: number,
    loadedImg: boolean,
    sliderCircleEnded: boolean,
};

class HomeSlider extends React.Component<PropsTypes, StateTypes> {
    private slider: Slider | any = null; //todo change any to null
    constructor(props: PropsTypes) {
        super(props);
        this.state = {
            slideIndex: 0,
            loadedImg: false,
            sliderCircleEnded: false,
        };
        this.handleImageLoaded = this.handleImageLoaded.bind(this);
    }

    nextSlide = (index: number) => {
        const {mainSlider} = this.props;
        this.setState({
            slideIndex: this.state.slideIndex - 1 === index ? 0 : index + 1,
        });
        if (mainSlider?.length - 1 === this.state.slideIndex) {
            this.setState({
                slideIndex: 0,
            });
        }
        this.slider.slickNext();
    };

    prevSlide = (index: number) => {
        const {mainSlider} = this.props;
        this.setState({
            slideIndex: index === 0 ? mainSlider?.length - 1 : index - 1,
        });
        this.slider.slickPrev();
    };

    handleImageLoaded() {
        if (!this.state.loadedImg) {
            this.setState({loadedImg: true});
        }
    }

    afterChangeSlideIndex = () => {
        this.setState({
            sliderCircleEnded: false,
        })
    };

    beforeChangeSlideIndex = (_: any, slideIndex: number) => {
        this.setState({
            slideIndex,
            sliderCircleEnded: true,
        })
    };

    render() {
        const {slideIndex, sliderCircleEnded} = this.state;
        const {mainSlider, windowSizes} = this.props;

        const defaultSettings: Settings = {
            dots: false,
            arrows: false,
            infinite: true,
            autoplaySpeed: 5000,
            speed: 400,
            autoplay: true,
            pauseOnHover: false,
            lazyLoad: 'progressive',
            swipeToSlide: true,
            swipe: true,
            afterChange: this.afterChangeSlideIndex,
            beforeChange: this.beforeChangeSlideIndex,
            // ref: slider => (this.slider = slider),
            slidesToShow: 1,
            slidesToScroll: 1,
            fade: true,
        };

        return <div className="home_slider">
            <Slider
                {...defaultSettings}
                ref={(component: Slider | null) => {
                    this.slider = component;
                }}
            >
                {mainSlider?.length && mainSlider?.map((item:any) => {
                    const orientation = window.screen?.orientation?.type;
                    return <div key={item.id} className={`slider-item`}>
                        {
                            ((windowSizes?.width || window.innerWidth) > 800 && orientation === "landscape-primary") ?
                                <Link target={'_blank'} to={item?.post ? `/post/${item?.post?.slug}` : ''}>
                                    <img src={generateImageMediaUrl(item?.mediaMain?.path)} alt={""}
                                         onLoad={() => this.handleImageLoaded()}/>
                                    <div className="slider-info-wrapper">
                                        <div className="info-content">
                                            <div className="slide-title">{item?.title}</div>
                                            <div className="slide-description">{item?.description}</div>
                                        </div>
                                    </div>
                                </Link>
                                :
                                <Link target={'_blank'} to={item?.post?.slug ? `/post/${item?.posts?.slug}` : ''}>
                                    <img src={generateImageMediaUrl(item?.mediaMain?.path)}
                                        // onError={(e) => {
                                        //     e.target.onerror = null;
                                        //     e.target.src = generateSliderMediaUrl(item?.mediaMain?.path)
                                        // }}
                                         onLoad={() => this.handleImageLoaded()}/>
                                    <div className="slider-info-wrapper">
                                        <div className="info-content">
                                            <div className="slide-title">{item?.title}</div>
                                            <div className="slide-description">{item?.description}</div>
                                        </div>
                                    </div>
                                </Link>}
                    </div>
                })}
            </Slider>

            {
                !!mainSlider && !!mainSlider.length &&
                <div className={'slider-bottom-content'}>
                    {mainSlider?.length !== 1 && <div className={'slider-arrows'}>
                        <div className={'slider-btn prev-btn'}
                             onClick={() => this.prevSlide(slideIndex)}>
                            <RightSliderIcon/>
                        </div>
                        <ProgressBar slideIndex={slideIndex}
                                     sliderCircleEnded={sliderCircleEnded}
                                     sliderLength={mainSlider?.length}/>
                        <div className={'slider-btn next-btn'}
                             onClick={() => this.nextSlide(slideIndex)}>
                            <RightSliderIcon/>
                        </div>
                    </div>}
                </div>
            }
        </div>;
    }
}

const mapDispatchToProps = {}

const mapStateToProps = (state: any) => {
    return getPropsFromState(state, [
        'mainSlider',
        'windowSizes',
    ])
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeSlider)
