// import packages
import React, { useEffect, useMemo, useRef, useState } from "react";
import { connect } from "react-redux";
import { Link, useLocation, useNavigate, useNavigationType } from "react-router-dom";

// Import assets
import "./search.scss"
import { BlackArrowRightIcon, FilterIcon } from "../../assets/images";


//Components
import { SearchMap } from "./map/SearchMap";
import Filter from "./filter/Filter";
import ProductCardList from "../../components/cards/product-card-list/ProductCardList";
import ProductCardGrid from "../../components/cards/product-card-grid/ProductCardGrid";
import Section from "./section/Section";
import { Breadcrumb, Dropdown, Menu, Pagination } from "antd";

// Import utils
import { getPropsFromState } from "../../redux/mapStateToProps";
import { PER_PAGE_POSTS_COUNT, POST_TYPES, POSTS_SORT_TYPES } from "../../constants/constants";
import { GetPostSearch, LikePost, SetFiltersData } from "../../redux/actions";


const Search = (props) => {
    const location = useLocation()
    const navType = useNavigationType();
    console.log('navType', navType)
    // console.log('filtersData', props.filtersData)
    const navigate = useNavigate();

    const { posts, bestOffers, selectedCurrency, translation, selectedLanguage, filtersData } = props;
    const sortOptions = [
        { name: `${translation?.search_page_sort_by_update_reduction || "Update"}`, id: POSTS_SORT_TYPES.UPDATE_DOWN },
        { name: `${translation?.search_page_sort_by_price_increase}`, id: POSTS_SORT_TYPES.PRICE_UP },
        { name: `${translation?.search_page_sort_by_price_reduction}`, id: POSTS_SORT_TYPES.PRICE_DOWN },
        { name: `${translation?.search_page_sort_by_area_increase}`, id: POSTS_SORT_TYPES.AREA_UP },
        { name: `${translation?.search_page_sort_by_area_reduction}`, id: POSTS_SORT_TYPES.AREA_DOWN },
        { name: `${translation?.search_page_sort_by_rooms_count_increase}`, id: POSTS_SORT_TYPES.ROOM_COUNTS_UP },
        { name: `${translation?.search_page_sort_by_rooms_count_reduction}`, id: POSTS_SORT_TYPES.ROOM_COUNTS_DOWN },
    ]

    const IS_URGENT = location.pathname === '/urgent';
    const prevData = useRef({
        isUrgent: IS_URGENT,
        languageCode: selectedLanguage?.code
    })

    const filtersInitialValues = {
        text: '',
        type: POST_TYPES.SELL.key,
        category: undefined,
        minPrice: undefined,
        maxPrice: undefined,
        roomsCount: undefined,
        minArea: undefined,
        maxArea: undefined,
        subCategory: undefined,
        bathroomsCount: undefined,
        floorNumber: undefined,
        condition: undefined,
        filterValues: [],
        regions: [],
        residences: [], // { residence: "id", region: "id", title: ""}
    }
    const POST_VIEW_TYPES = {
        MAP: {
            key: "MAP",
            title: `${translation?.search_page_dropdownMap}`
        },
        LIST: {
            key: "LIST",
            title: `${translation?.search_page_dropdownList}`,
        },
        GRID: {
            key: "GRID",
            title: `${translation?.search_page_dropdownGrid}`,
        },
    };

    const [mounted, setMounted] = useState(false)
    const [showFilterMenu, setShowFilterMenu] = useState(false)
    const [viewType, setViewType] = useState(POST_VIEW_TYPES.LIST.key)
    const [sortType, setSortType] = useState(POSTS_SORT_TYPES.UPDATE_DOWN)
    const [currentPage, setCurrentPage] = useState(1);
    const [filters, setFilters] = useState(filtersInitialValues)
    const isFetching = useRef(false)

    //hooks
    useMemo(() => {
        // console.log('useMemo')
        window.scrollTo({
            top: 0
        });
        // console.log('location?.state', location?.state);
        // const { minPrice, maxPrice, roomsCount, type, text, category, regions, residences,  } = location?.state || {}
        const resourceData = (filtersData?.fromHomeFilter || navType === "POP") ? filtersData?.filters : {};
        console.log('resource', resourceData);
        const {
            minPrice, maxPrice, roomsCount, type, text, category, regions, residences,
            minArea, maxArea, subCategory, bathroomsCount, floorNumber, condition, filterValues
        } = resourceData

        console.log('fromHomeFilter', filtersData?.fromHomeFilter);
        // console.log('regions', regions);
        // console.log('residences', residences);
        const _filters = {}
        // console.log('type', type);
        text && (_filters.text = text);
        type && (_filters.type = type);
        category && (_filters.category = category);
        minPrice && (_filters.minPrice = minPrice);
        maxPrice && (_filters.maxPrice = maxPrice);
        roomsCount && (_filters.roomsCount = roomsCount);
        minArea && (_filters.minArea = minArea);
        maxArea && (_filters.maxArea = maxArea);
        subCategory && (_filters.subCategory = subCategory);
        bathroomsCount && (_filters.bathroomsCount = bathroomsCount);
        floorNumber && (_filters.floorNumber = floorNumber);
        condition && (_filters.condition = condition);
        filterValues && (_filters.filterValues = filterValues);
        regions && (_filters.regions = regions);
        residences && (_filters.residences = residences);
        const newFilters = {
            ...filters,
            ..._filters,
        }
        setFilters(newFilters)
        // console.log('mounted', location.state)
        // navigate(location.pathname, { replace:true }); // clear location state (after refreshing bug)
        const _sortType = filtersData?.sortType || POSTS_SORT_TYPES.UPDATE_DOWN
        const _viewType = filtersData?.viewType || POST_VIEW_TYPES.LIST.key
        const _currentPage = filtersData?.currentPage || 1
        setViewType(_viewType)
        setSortType(_sortType)
        setCurrentPage(_currentPage)
        props.SetFiltersData({
            filters: resourceData,
            sortType: _sortType,
            viewType: _viewType,
            currentPage: currentPage,
            fromHomeFilter: false,
        })

        setMounted(true)
        navType !== "POP" && requestNewPostData({ filters: newFilters })
    }, [])

    useEffect(() => {
        // console.log('update', IS_URGENT, prevData.current?.isUrgent)
        if (IS_URGENT !== prevData.current?.isUrgent) {
            prevData.current.isUrgent = IS_URGENT
            console.log('isUrgent')
            requestNewPostData()
        }
    }, [IS_URGENT])

    useEffect(() => {
        if (mounted && prevData.current?.languageCode !== selectedLanguage?.code) {
            prevData.current.languageCode = selectedLanguage.code
            requestNewPostData()
        }
    }, [selectedLanguage?.code])

    //functions
    function changeFilter (changedFilters = {}) { // object of changed filters => {key1: value, key2: value}
        console.log('changedFilters', changedFilters);
        // console.log('changedFilters', changedFilters);
        let newFilters = {
            ...filters,
            ...changedFilters
        };
        setFilters(newFilters);
        setCurrentPage(1);
        console.log('changeFilter')
        requestNewPostData({ filters: newFilters })
    }

    function clearFilters () {
        setFilters(filtersInitialValues);
        console.log('clearFilters')
        requestNewPostData({ filters: filtersInitialValues })
    }

    async function onPageChanged (page) {
        // console.log(page,'Element')
        isFetching.current = true;
        const configInfo = {
            reset: true,
            offset: (page - 1) * PER_PAGE_POSTS_COUNT,
            limit: PER_PAGE_POSTS_COUNT,
        }
        console.log('onPageChanged')
        await requestNewPostData({ config: configInfo, page })
        setCurrentPage(page);
        window.scrollTo({
            top: 0
        });
    }

    function changeViewType (e) {
        setViewType(e.key)
    }

    function changeSortType (type) {
        setSortType(type)
        console.log('changeSortType')
        requestNewPostData({ sortType: type })
    }

    function requestNewPostData (payload) {
        // console.log('payload', payload)
        const requestFilters = payload?.filters || filters;
        const requestSort = payload?.sortType || sortType
        const requestConfig = payload?.config || {}
        const reqData = {
            ...requestFilters,
            sort: requestSort,
            currency: selectedCurrency,
            offset: 0,
            limit: PER_PAGE_POSTS_COUNT,
            regions: requestFilters?.regions?.map(region => region?.id),
            residences: requestFilters.residences?.map(residence => residence?.id),
            ...requestConfig,
        }
        IS_URGENT && (reqData.isUrgent = IS_URGENT)
        props.SetFiltersData({
            filters: requestFilters,
            sortType: requestSort,
            viewType: viewType,
            currentPage: payload?.page || currentPage,
            fromHomeFilter: false,
        })
        return props.GetPostSearch(reqData)
    }

    const viewTypesMenu = (
        <Menu
            items={Object.keys(POST_VIEW_TYPES).map(key => {
                return {
                    key,
                    label: <div className={'view_type_item'}>
                        {POST_VIEW_TYPES?.[key]?.title}
                    </div>,
                    onClick: (e) => changeViewType(e)
                }
            })}
        />
    )
    const sortTypesMenu = (
        <Menu
            items={sortOptions.map(item => {
                return {
                    key: item.id,
                    label: <div className={'view_type_item'}>
                        {item.name}
                    </div>,
                    onClick: (e) => changeSortType(e.key)
                }
            })}
        />
    )

    const itemRender = (_, type, originalElement) => {
        // console.log(originalElement,'Element')
        if (type === 'prev') {
            return <a className={'prev_btn'}><BlackArrowRightIcon
                className={'arrow_icon'}/>{translation?.search_page_prevBtn}</a>;
        }
        if (type === 'next') {
            return <a className={'next_btn'}>{translation?.search_page_nextBtn} <BlackArrowRightIcon
                className={'arrow_icon'}/></a>;
        }
        return originalElement;
    };

    let title = (translation?.search_page_posts_list_title || "%d")?.replace('%d', posts?.count || 0)
    return <div className={`search ${viewType === POST_VIEW_TYPES.MAP.key ? 'map_view' : ''}`}>
        <div className="search__header">
            <Breadcrumb>
                <Breadcrumb.Item><Link to={'/'}>{translation?.search_page_metaTeg_home}</Link></Breadcrumb.Item>
                <Breadcrumb.Item>{translation?.search_page_metaTeg_search}</Breadcrumb.Item>
            </Breadcrumb>

            <div className="search__header__selects">
                <div className="filter_btn" onClick={() => setShowFilterMenu(true)}>
                    {translation?.search_page_filterBtn} <FilterIcon/>
                </div>
                <Dropdown overlay={viewTypesMenu} className="header_dropdown">
                    <a onClick={e => e.preventDefault()}>
                        {POST_VIEW_TYPES[viewType]?.title} <BlackArrowRightIcon className={'arrow_icon'}/>
                    </a>
                </Dropdown>
                <Dropdown overlay={sortTypesMenu} className="header_dropdown">
                    <a onClick={e => e.preventDefault()}>
                        {sortOptions.find(i => i.id === sortType)?.name} <BlackArrowRightIcon className={'arrow_icon'}/>
                    </a>
                </Dropdown>
            </div>
        </div>
        {mounted && <Filter filters={filters}
                            changeFilter={changeFilter}
                            clearFilters={clearFilters}
                            showFilterMenu={showFilterMenu}
                            setShowFilterMenu={setShowFilterMenu}
        />}

        <div className="search__posts_list">
            {viewType === POST_VIEW_TYPES.MAP.key ?
                <SearchMap posts={posts} focusedProject={null} selectedLanguage={null} translation={translation}
                           type={filters.type}/>
                :
                <>
                    <div className="posts_list_title">{title}</div>
                    <div className={`post-items-wrapper ${viewType}`}>
                        {
                            posts?.list?.length ? posts?.list?.map((item) => {
                                return viewType === POST_VIEW_TYPES.GRID.key
                                    ? <ProductCardGrid className={'card_grid'} data={item} key={item.id}
                                                       type={filters.type}/>
                                    : <ProductCardList key={item.id} data={item} LikePost={props.LikePost}
                                                       type={filters.type}/>
                            }) : <div className={'empty_list'}>
                                <span>{translation?.search_page_empty}</span></div>
                        }
                    </div>
                    <Pagination current={currentPage}
                                onChange={onPageChanged}
                                total={posts.count}
                                defaultPageSize={PER_PAGE_POSTS_COUNT}
                                hideOnSinglePage={true}
                                itemRender={itemRender}
                                className={`search__posts_list__pagination ${currentPage === 1 ? 'hide-prev' : ""}`}
                    />
                    <Section showHeader={false} posts={bestOffers}/>
                </>

            }
        </div>
    </div>
}

const mapStateToProps = (state) => getPropsFromState(state, [
    // names (type - string) of props
    'translation',
    'posts',
    'bestOffers',
    'selectedLanguage',
    'filtersData',
]);

const mapDispatchToProps = {
    //redux actions
    GetPostSearch,
    LikePost,
    SetFiltersData
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);
