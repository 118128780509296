// Import packages
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";

// Import assets
import "./filter.scss"

// Import components
import { InputGroup } from "../../../components/ui-elements/input-group/inputGroup";
import {
    ClearIcon, EllipseIcon,
    RegionArrIcon,
    RegionCheckIcon,
    RegionMinusIcon,
    RegionPlusIcon,
    SearchIcon
} from "../../../assets/images";
import { Checkbox, Dropdown, Menu, Slider, Space } from "antd";

// Import utils.
import {
    BATHROOMS_TYPES,
    CURRENCIES,
    POST_TYPES
} from "../../../constants/constants";
import { getPropsFromState } from "../../../redux/mapStateToProps";
import { GetFilters, GetPostSearch, GetResidencesList, PriceType } from "../../../redux/actions";
import Exchange from "../exchange/Exchange";


const roomCountOptions = [
    { id: '1', name: '1' },
    { id: '2', name: '2' },
    { id: '3', name: '3' },
    { id: '4+', name: '4+' },
];

const floorsOptions = [...Array(25).keys()].map(i => {
    return {
        id: i + 1,
        name: i + 1
    }
});
// console.log(floorsOptions, 'floorsOptions');

//todo fix many request without change, when search by text and reset filters


const Filter = (props) => {
    const {
        filters,
        changeFilter,
        clearFilters,
        setShowFilterMenu,
        showFilterMenu,

        //redux props
        ratesList,
        initialPostData,
        categories,
        translation,
        filtersList,
        selectedCurrency,
        regionsList,
        residencesList,
        selectedLanguage,
    } = props

    const POST_CONDITIONS_TYPES = {
        NEW_HOME: {
            key: "NEW_HOME",
            title: `${translation.filter_new_home}`
        },
        GOOD_CONDITION: {
            key: "GOOD_CONDITION",
            title: `${translation.filter_good_condition}`,
        },
        NEED_RENOVATION: {
            key: "NEED_RENOVATION",
            title: `${translation.filter_need_renovation}`,
        },
    };

    const conditionOptions = Object.keys(POST_CONDITIONS_TYPES).map(type => {
        return {
            id: POST_CONDITIONS_TYPES[type]?.key,
            name: [POST_CONDITIONS_TYPES?.[type]?.title]
        }
    })


    const MIN_PRICE = initialPostData?.minPrice || 0;
    const MAX_PRICE = 999999999;
    const MIN_AREA = initialPostData?.minArea || 0;
    const MAX_AREA = initialPostData?.maxArea || 10000;

    const categoriesOptions = categories?.map((item) => {
        return {
            id: item.id,
            name: item.title
        }
    })
    const subCategoryOptions = categories.find(category => category.id === filters.category)?.children?.map(sub => {
        return {
            id: sub?.id,
            name: sub?.title || ' '
        }
    });
    const [mounted, setMounted] = useState(false)
    const [openRegionId, setOpenRegionId] = useState('')
    const [residenceData, setResidenceData] = useState(false)
    const [tempData, setTempData] = useState({
        text: filters.text || '',
        minPrice: filters.minPrice || MIN_PRICE,
        maxPrice: filters.maxPrice || MAX_PRICE,
        minArea: filters.minArea || MIN_AREA,
        maxArea: filters.maxArea || MAX_AREA,
    })
    const [showDropDown, setShowDropDown] = useState(false);

    const ref = useRef();


    useEffect(() => {
        document.addEventListener('click', toggleDropDown, { capture: true });
        return () => {
            document.removeEventListener('click', toggleDropDown)
        }
    }, []);

    const toggleDropDown = (event) => {
        const path = event.composedPath ? event.composedPath() : event.path
        if (!path?.includes(ref?.current)) {
            setShowDropDown(false)
            setOpenRegionId('')
            setResidenceData(false)
        }
    }
    useEffect(() => {
        props.GetFilters()
        setMounted(true)
    }, [])


    useEffect(() => {
        setResidenceData(true)
    }, [residencesList])

    useEffect(() => {
        props.PriceType(filters.type)
    }, [filters.type])

    useEffect(() => {
        if (mounted) { //skip initial render
            //remove filter by price after currency change
            setTempData({
                ...tempData,
                minPrice: MIN_PRICE,
                maxPrice: MAX_PRICE,
            })
            changeFilter({
                minPrice: undefined,
                maxPrice: undefined
            })
        }
    }, [initialPostData?.minPrice, initialPostData?.maxPrice])

    const onChangeSliderTempData = (value, type) => {
        setTempData({
            ...tempData,
            [`min${type}`]: value[0],
            [`max${type}`]: value[1],
        })
    }

    function onAfterChangePrice (values) {
        changeFilter({
            minPrice: values[0] !== MIN_PRICE ? values[0] : undefined,
            maxPrice: values[1] !== MAX_PRICE ? values[1] : undefined
        })
    }

    function onAfterChangeArea (values) {
        changeFilter({
            minArea: values[0] !== MIN_AREA ? values[0] : undefined,
            maxArea: values[1] !== MAX_AREA ? values[1] : undefined
        })
    }

    function getSelectValue (name, value) {
        // console.log('name', name, 'value', value)
        const changedFilters = {
            [name]: value
        }
        if (name === 'category') {
            changedFilters.subCategory = undefined
        }

        changeFilter(changedFilters)
    }

    function getInputValues ({ name, value }) {
        setTempData({
            ...tempData,
            [name]: value,
        })
    }

    function searchByText () {
        changeFilter({ 'text': tempData.text })
    }

    function onFilterValueChanged (id, checked) {
        const filterValues = checked ? [...filters.filterValues, id] : filters.filterValues.filter(i => i !== id)
        changeFilter({ 'filterValues': filterValues })
    }

    function resetFilters () {
        setTempData({
            minPrice: MIN_PRICE,
            maxPrice: MAX_PRICE,
            minArea: MIN_AREA,
            maxArea: MAX_AREA,
        })
        clearFilters()
    }

    const getSelectedRegion = (region) => {

        const checked = filters?.regions?.find(item => item?.id === region?.id)
        if (checked) {
            filters.regions = filters?.regions?.filter(item => item?.id !== region?.id)
        } else {
            filters.regions = [...filters?.regions, region]
            filters.residences = filters.residences?.filter(item => item?.region !== region?.id)
        }
        changeFilter({
            ...filters,
            regions: filters.regions,
            residences: filters.residences
        })
    }

    const getSelectedResidence = (residence, region) => {
        const checked = filters?.residences?.find(item => item?.id === residence?.id)
        if (checked) {
            filters.residences = filters?.residences?.filter(item => item?.id !== residence?.id)
        } else {
            filters.residences = [...filters?.residences, {
                id: residence?.id,
                title: residence?.title,
                region: region?.id
            }]
            filters.regions = filters.regions?.filter(regionId => regionId?.id !== region.id)
        }
        changeFilter({
            ...filters,
            regions: filters.regions,
            residences: filters.residences
        })
    };

    function closeResidences () {
        setOpenRegionId('')
        setResidenceData(false)
    }

    function getResidences (id) {
        setOpenRegionId(id)
        props.GetResidencesList(id)
    }

    const items = <div ref={ref}>
        <Menu className={'region_block_dropdown'} items={
            regionsList?.length && regionsList.map((region, index) => {
                return {
                    key: index,
                    label: <div className={'region'}>
                        <div className={'regions-header'}>
                            <div className={'checked-block'} onClick={() => getSelectedRegion(region)}>
                                <p>{filters?.residences.find(item => item?.region === region?.id) ?
                                    <EllipseIcon/> : filters?.regions?.find(item => item?.id === region?.id) ?
                                        <RegionCheckIcon/> : ''}</p>
                                <span>{region?.title[selectedLanguage?.code]}</span>
                            </div>
                            {
                                openRegionId === region?.id ? <p onClick={closeResidences}>
                                        <RegionMinusIcon/>
                                    </p> :
                                    <p onClick={() => getResidences(region?.id)}>
                                        <RegionPlusIcon/>
                                    </p>
                            }
                        </div>
                        {
                            openRegionId === region?.id ? <div className={'residences'}>
                                {
                                    residenceData ? residencesList?.map(residence => {
                                        return <div className={'residences-block'}
                                                    onClick={() => getSelectedResidence(residence, region)}>
                                            <p>{filters?.residences?.find(item => item?.id === residence?.id) ?
                                                <RegionCheckIcon/> : ''}</p>
                                            <span>{residence?.title[selectedLanguage?.code]}</span>
                                        </div>
                                    }) : ''
                                }
                            </div> : ''
                        }
                    </div>,
                }
            })}
        />
    </div>;

    const regionsTitle = filters?.regions?.map(val => val?.title[selectedLanguage?.code])?.join(", ")
    const residenceTitle = filters?.residences?.map(val => val?.title[selectedLanguage?.code])?.join(", ")
    const inputValue = regionsTitle?.length > 1 && residenceTitle?.length !== 0 ?
        regionsTitle.concat(',', residenceTitle) : regionsTitle?.length < 1 && residenceTitle?.length < 1 ?
            translation?.homepage_homeFilter_residence_input : regionsTitle.concat('', residenceTitle)

    return <div className={`filter ${showFilterMenu ? 'showMenu' : ''}`}>
        <ClearIcon className={'close_menu'}
                   onClick={() => setShowFilterMenu(false)}/>
        <div className="filter__search">
            <InputGroup inputType={'input'}
                        type={"text"}
                        value={tempData.text}
                        name={'text'}
                        maxLength={100}
                        onKeyDown={(e) => {
                            e.key === 'Enter' && searchByText()
                        }}
                        placeholder={translation?.filter_search_placeholder}
                        className="search_input"
                        onChange={getInputValues}>
            </InputGroup>

            <button className="filter__search__btn" onClick={searchByText}>
                <SearchIcon/>
            </button>
        </div>

        <div className="switch_wrapper">
            <div className="switch">
                {
                    Object.keys(POST_TYPES).map((type) => {
                        return <span className={`${POST_TYPES[type].key === filters.type ? 'active' : ''}`}
                                     key={type}
                                     onClick={() => getSelectValue('type', type)}>{translation[`filter_type_title_${type}`]}</span>
                    })
                }
            </div>
            <div className="clear_btn" onClick={resetFilters}><ClearIcon/>{translation?.filter_clear_btn}</div>
        </div>

        <div className={'filter_region'}>
            <div className={'filter_region_title'}>
                {translation?.homepage_homeFilter_residence}
            </div>
            <div className={'region_block'}>
                <Dropdown
                    overlay={items}
                    trigger={['click']}
                    open={showDropDown}
                    onClick={() => setShowDropDown(true)}
                >
                    <a onClick={(e) => e.preventDefault()}>
                        <Space>
                            <div className={'region-input-text'}>
                                {inputValue}
                            </div>
                        </Space>
                        <div className={'icon-wrapper'}>
                            <RegionArrIcon/>
                        </div>
                    </a>
                </Dropdown>
            </div>
        </div>

        <InputGroup
            showSearch={false}
            value={filters.category}
            inputType={'select'}
            name={'category'}
            placeholder={translation?.filter_building_placeholder}
            label={translation?.homepage_homeFilter_building_label}
            onChange={getSelectValue}
            className="filter__select"
            options={categoriesOptions}/>

        <div className={'filter__slider'}>
            <div className="label">{translation?.filter_price_label}</div>
            <div className={'slider_content'}>
                <div className={'slider_label'}>
                    <div className={'left-block'}>
                        <span>{translation?.filter_from}</span>{CURRENCIES[selectedCurrency]?.suffix} {tempData.minPrice}
                    </div>
                    <div className={'right-block'}>
                        <span>{translation?.filter_to}</span>{CURRENCIES[selectedCurrency]?.suffix} {tempData.maxPrice}
                    </div>
                </div>
                <Slider range
                        min={MIN_PRICE}
                        max={MAX_PRICE}
                    // step={100}
                        value={[typeof tempData.minPrice === 'number' ? tempData.minPrice : MIN_PRICE, typeof tempData.maxPrice === 'number' ? tempData.maxPrice : MAX_PRICE]}
                        tooltip={{ formatter: null }}
                        onChange={(value) => onChangeSliderTempData(value, 'Price')}
                        onAfterChange={onAfterChangePrice}
                />
            </div>
        </div>

        <InputGroup
            showSearch={false}
            inputType={'select'}
            name={'roomsCount'}
            placeholder={translation?.filter_room_placeholder}
            label={translation?.filter_room_label}
            value={filters.roomsCount}
            onChange={getSelectValue}
            options={roomCountOptions}/>

        <div className={'filter__slider'}>
            <div className="label">{translation?.filter_size_label}</div>
            <div className={'slider_content'}>
                <div className={'slider_label'}>
                    <div className={'left-block'}>
                        <span>{translation?.filter_from}</span>{tempData.minArea} {translation?.filter_measurement_unit_meter}<sup>2</sup>
                    </div>
                    <div className={'right-block'}>
                        <span>{translation?.filter_to}</span>{tempData.maxArea} {translation?.filter_measurement_unit_meter}<sup>2</sup>
                    </div>
                </div>
                <Slider range
                        min={MIN_AREA}
                        max={MAX_AREA}
                        value={[typeof tempData.minArea === 'number' ? tempData.minArea : MIN_PRICE, typeof tempData.maxArea === 'number' ? tempData.maxArea : MAX_PRICE]}
                        tooltip={{ formatter: null }}
                        onChange={(value) => onChangeSliderTempData(value, 'Area')}
                        onAfterChange={onAfterChangeArea}
                />
            </div>
        </div>

        <InputGroup
            value={filters.subCategory}
            showSearch={false}
            inputType={'select'}
            name={'subCategory'}
            placeholder={translation?.filter_apartment_placeholder}
            label={translation?.filter_apartment_label}
            disabled={!filters?.category}
            onChange={getSelectValue}
            options={subCategoryOptions}/>

        <div className={'filter__rooms'}>
            <div className="label">{translation?.filter_bathrooms_label}</div>

            <div className="filter__rooms__list">
                {
                    Object.keys(BATHROOMS_TYPES).map((item) => {
                        return <div className={`room ${item === filters.bathroomsCount ? 'active' : ''}`} key={item}
                                    onClick={() => getSelectValue('bathroomsCount', item)}>{BATHROOMS_TYPES[item]}</div>
                    })
                }
            </div>
        </div>

        <InputGroup
            value={filters.condition}
            showSearch={false}
            inputType={'select'}
            name={'condition'}
            placeholder={translation?.filter_condition_placeholder}
            label={translation?.filter_condition_label}
            onChange={getSelectValue}
            options={conditionOptions}/>

        <div className={'filter__filters'}>
            {
                !!filtersList?.length && filtersList?.map(item => {
                    return <div key={item.id} className={'filter__filters__item'}>
                        <div className="label">{item?.title}</div>
                        {
                            item?.values?.map(value => {
                                return <Checkbox checked={filters?.filterValues?.includes(value?.id)}
                                                 onChange={(e) => onFilterValueChanged(value?.id, e.target.checked)}>
                                    {value?.title}
                                </Checkbox>
                            })
                        }
                    </div>
                })
            }
        </div>

        <InputGroup
            value={filters.floorNumber}
            showSearch={false}
            inputType={'select'}
            name={'floorNumber'}
            placeholder={translation?.filter_floors_placeholder}
            label={translation?.filter_floor_label}
            onChange={getSelectValue}
            options={floorsOptions}/>
        <Exchange ratesList={ratesList}/>
    </div>
}

const mapStateToProps = (state) => getPropsFromState(state, [
    // names (type - string) of props
    'translation',
    'categories',
    'filtersList',
    'posts',
    'ratesList',
    'initialPostData',
    'selectedCurrency',
    'regionsList',
    'residencesList',
    'selectedLanguage'
]);

const mapDispatchToProps = {
    //redux actions
    GetPostSearch,
    GetFilters,
    PriceType,
    GetResidencesList,
};

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
