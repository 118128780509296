import {_hostMedia} from "../redux/api";
import {defaultFileLogo} from "../assets/images";

export function generateImageMediaUrl(path: string) {
    if (path?.startsWith('http')) {
        return path;
    }
    return path ? `${_hostMedia}/images${path}` : defaultFileLogo
}

export function generateFileMediaUrl(path: string) {
    if (path?.startsWith('http')) {
        return path;
    }
    return path ? `${_hostMedia}/files${path}` : defaultFileLogo;
}

export function generateSliderMediaUrl(path: string) {
    if (path?.startsWith('http')) {
        return path;
    }
    return `${_hostMedia}/files${path}`;
}

export function generateFileMediaUrlWithoutDefault(path: string) {
    if (path?.startsWith('http')) {
        return path;
    }
    return path && `${_hostMedia}/files${path}`
}
