export const POSTS_CONSTS = {
    GET_POSTS_INFO: "GET_POSTS_INFO",
    GET_POSTS_INITIAL_INFO: "GET_POSTS_INITIAL_INFO",
    GET_POST_SEARCH: "GET_POST_SEARCH",
    GET_BEST_OFFERS: "GET_BEST_OFFERS",
    ADD_POST_TO_RECENTLY_VIEWED: "ADD_POST_TO_RECENTLY_VIEWED",
    RESET_RECENTLY_VIEWED_POSTS: "RESET_RECENTLY_VIEWED_POSTS",
    SET_POSTS_INITIAL_INFO_AS_CURRENT: "SET_POSTS_INITIAL_INFO_AS_CURRENT",
    TOGGLE_POST_LIKE: "TOGGLE_POST_LIKE",
    GET_FAVORITE_POSTS: "GET_FAVORITE_POSTS",
    ADD_POST_TO_COMPARED_LIST: "ADD_POST_TO_COMPARED_LIST",
    REMOVE_POST_FROM_COMPARED_LIST: "REMOVE_POST_FROM_COMPARED_LIST",

    SET_FILTERS_DATA: "SET_FILTERS_DATA"
};
