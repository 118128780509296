import { socketNotificationConnection } from "../api/socketNotificationConnection";
import { NOTIFICATION_TYPES } from "../constants";
import { NOTIFICATION_CONSTS } from "../../redux/constants";
import { store } from "../../redux/store";
import { isNotificationsConnected } from "../api/isConnected";

export const GetNonOpenedNotificationsCount = () => {
    isNotificationsConnected() &&
    socketNotificationConnection.instance.emit(NOTIFICATION_TYPES.GET_NON_OPENED_NOTIFICATIONS_COUNT,
        (error, data) => {
            // console.log('non-opened-notif-count...', error, data)
            data && store.dispatch({
                type: NOTIFICATION_CONSTS.GET_NON_OPENED_NOTIFICATIONS_COUNT,
                payload: data.count
            });
        })
};


export const SetAllNotificationsAsOpened = () => {

    isNotificationsConnected() &&
    socketNotificationConnection.instance.emit(NOTIFICATION_TYPES.SET_ALL_NOTIFICATIONS_OPENED,
        (error, data) => {
            //  console.log('set-notificationes-opened...', error, data.success)
            data && store.dispatch({
                type: NOTIFICATION_CONSTS.SET_ALL_NOTIFICATIONS_OPENED
            })
        })
};
export const SetNotificationAsOpened = (notificationId) => {
    const payload = { notificationId };
    isNotificationsConnected() &&
    socketNotificationConnection.instance.emit(NOTIFICATION_TYPES.SET_NOTIFICATION_OPENED, payload,
        (error, data) => {
            //  console.log('set-notificatione-opened...', error, data.success)
            data && store.dispatch({
                type: NOTIFICATION_CONSTS.SET_ALL_NOTIFICATIONS_OPENED
            })
        })
};

export const DeleteAllNotifications = () => {
    isNotificationsConnected() &&
    socketNotificationConnection.instance.emit(NOTIFICATION_TYPES.REMOVE_ALL_NOTIFICATIONS,
        (error, data) => {
            //console.log('Delete-all-notifications...', error, data.success)
            store.dispatch({
                type: NOTIFICATION_CONSTS.REMOVE_ALL_NOTIFICATIONS,
            });
        })
};

export const DeleteNotification = (notificationId) => {
    const payload = { notificationId };

    isNotificationsConnected() &&
    socketNotificationConnection.instance.emit(NOTIFICATION_TYPES.REMOVE_NOTIFICATION, payload,
        (error, data) => {

            data && store.dispatch({
                type: NOTIFICATION_CONSTS.REMOVE_NOTIFICATION,
                payload: notificationId
            });
            // console.log('Delete-notification...', error, data)
        })
};
