// Import packages
import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';

// Import styles
import './settings.scss';

// Import components
import { InputGroup } from "../../../components/ui-elements/input-group/inputGroup";
import { MainButton } from "../../../components/ui-elements/button/Buttons";

//import utils
import {
    UpdateCurrentUser,
    GetCurrentUser,
    UpdateCurrentUserPassword,
    UploadUserProfilePicture
} from "../../../redux/actions";
import { getPropsFromState } from "../../../redux/mapStateToProps";
import PasswordChangeBox from "../passwordChange/PasswordChangeBox";
import { BackIcon } from "../../../assets/images";
import { Link } from "react-router-dom";

function Settings (props) {
    const { loggedInUser, translation, profilePictureFile, windowSizes } = props;
    const birthday = loggedInUser?.birthday && new Date(loggedInUser.birthday)

    const user = loggedInUser ?? {};
    const day = birthday && birthday.getDate();
    const month = birthday && (birthday.getMonth() + 1);
    const year = birthday && birthday.getFullYear();

    const initialValues = {
        firstName: user?.firstName || '',
        lastName: user?.lastName || '',
        day: day ? (day <= 9 ? '0' + day : day.toString()) : '',
        month: month ? (month <= 9 ? '0' + month : month.toString()) : '',
        year: year ? year.toString() : '',
        username: user?.username || '',
    }
    const [userInfo, setUserInfo] = useState({
        ...initialValues
    });
    const [errors, setErrors] = useState({});
    const updatedIsActive =
        initialValues.firstName !== userInfo.firstName ||
        initialValues.lastName !== userInfo.lastName ||
        initialValues.day !== userInfo.day ||
        initialValues.month !== userInfo.month ||
        initialValues.year !== userInfo.year ||
        initialValues.username !== userInfo.username ||
        profilePictureFile

    const getInputValues = ({ name, value }) => {
        // console.log('value', value, typeof value)
        setUserInfo({
            ...userInfo,
            [name]: value.trimStart(),
        })
        // @ts-ignore
        if (errors?.[name]) {
            setErrors({
                ...errors,
                [name]: false
            })
        }
    };

    const updateUser = async() => {
        const requiredFields = ['firstName'];
        let isValid = true;
        requiredFields.forEach(key => {
            if (!userInfo[key]) {
                isValid = false;
                setErrors({
                    ...errors,
                    [key]: true,
                })
            }
        });
        if (userInfo.day || userInfo.month || userInfo.year) {
            if (!userInfo.day || !userInfo.month || !userInfo.year) {
                setErrors({
                    ...errors,
                    day: !userInfo.day,
                    month: !userInfo.month,
                    year: !userInfo.year
                })
                isValid = false
                return;
            }
            const newBirthDay = new Date(`${userInfo.year}-${userInfo.month}-${userInfo.day}`)
            const currentDate = new Date();
            if (newBirthDay > currentDate) {
                isValid = false
                setErrors({
                    ...errors,
                    day: userInfo.day > currentDate.getDate(),
                    month: userInfo.month > currentDate.getMonth() + 1
                })
                return;
            }
        }
        if (isValid) {
            if (profilePictureFile) {
                let formData = new FormData();
                formData.append('profilePicture', profilePictureFile);
                let newProfilePicture = await props.UploadUserProfilePicture(formData);
                // console.log('newProfilePicture', newProfilePicture);
                // if (newProfilePicture) {
                //     reqData.profilePicturePath = newProfilePicture?.id;
                // }
            }
            const reqData = {
                firstName: userInfo.firstName,
                lastName: userInfo.lastName || ""
            }
            if (userInfo.day) {
                reqData.birthday = `${userInfo.year}-${userInfo.month}-${userInfo.day}`
            }
            props.UpdateCurrentUser(reqData).then(() => {
                setErrors({});
                // props.GetCurrentUser()
            }).catch(() => {

            });
        }
    };

    return <section className='settings-wrapper'>
        <Link to={'/profile'} className={`${(windowSizes?.width || window.innerWidth) < 801 ? 'mobile-back' : 'back'}`}>
            <BackIcon/> <span>{translation?.profile_back_title}</span>
        </Link>
        <div className="fields-wrapper">
            <div className={'top-title'}>{translation?.profile_settings_title}</div>
            <div className="top-wrapper">
                <div className={'user-info'}>
                    <InputGroup
                        inputType={'input'}
                        type={"text"}
                        name={'firstName'}
                        label={translation?.profile_settings_firstName_label}
                        placeholder={translation?.profile_settings_firstName_placeholder}
                        value={userInfo.firstName}
                        error={errors.firstName}
                        maxLength={50}
                        onChange={getInputValues}
                    />

                    <InputGroup
                        inputType={'input'}
                        type={"text"}
                        name={'lastName'}
                        label={translation?.profile_settings_lastName_label}
                        value={userInfo.lastName}
                        placeholder={translation?.profile_settings_lastName_placeholder}
                        error={errors.lastName}
                        maxLength={50}
                        onChange={getInputValues}
                    />
                </div>
                <div className="date_wrapper">
                    <div className={'data-inputs'}>
                        <label>{translation?.profile_settings_data_label}</label>
                        <div className={'inputs'}>
                            <InputGroup
                                showSearch={false}
                                inputType={'select'}
                                value={userInfo.day}
                                name={'day'}
                                error={errors.day}
                                placeholder={'Day'}
                                onChange={(name, value) => getInputValues({ name, value })}
                                options={Array.from(Array(31)).map((_, i) => {
                                    return {
                                        id: `${i <= 8 ? '0' : ''}${i + 1}`, // i + 1,
                                        name: `${i <= 8 ? '0' : ''}${i + 1}`
                                    }
                                })}/>
                            <InputGroup
                                showSearch={false}
                                inputType={'select'}
                                value={userInfo.month}
                                error={errors.month}
                                name={'month'}
                                placeholder={'Month'}
                                onChange={(name, value) => getInputValues({ name, value })}
                                options={Array.from(Array(12)).map((_, i) => {
                                    return {
                                        id: `${i <= 8 ? '0' : ''}${i + 1}`, // i + 1,
                                        name: `${i <= 8 ? '0' : ''}${i + 1}`
                                    }
                                })}/>
                            <InputGroup
                                showSearch={false}
                                inputType={'select'}
                                value={userInfo.year}
                                error={errors.year}
                                name={'year'}
                                placeholder={'Year'}
                                onChange={(name, value) => getInputValues({ name, value })}
                                options={Array.from(Array(100)).map((_, i) => {
                                    const date = new Date().getFullYear()
                                    return {
                                        id: (date - 100 + i + 1).toString(),
                                        name: date - 100 + i + 1
                                    }
                                })}/>
                        </div>
                    </div>
                    <MainButton title={translation?.profile_settings_btnSave}
                                className={"save-btn"}
                                disabled={!updatedIsActive}
                                loading={false}
                                click={updateUser}/>
                </div>
            </div>
            <div className="bottom-wrapper">
                <div className={'bottom-title'}>{translation?.profile_settings_changePassword_title}</div>
                <PasswordChangeBox UpdateCurrentUserPassword={props.UpdateCurrentUserPassword}
                                   loggedInUser={loggedInUser}
                                   translation={translation}/>
            </div>
        </div>
    </section>
}

const mapStateToProps = (state) => getPropsFromState(state, [
    'loggedInUser',
    'requestLoading',
    'selectedLanguage',
    'translation',
    'windowSizes',
]);

const mapDispatchToProps = {
    UpdateCurrentUser,
    GetCurrentUser,
    UpdateCurrentUserPassword,
    UploadUserProfilePicture
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
