// import packages
import React, { useEffect} from "react";
import {connect} from "react-redux";

// Import utils
import {CategoriesItemType, PostItemType} from "../../redux/actions/actionTypes";
import {getPropsFromState} from "../../redux/mapStateToProps";
import {GetBestOffers} from "../../redux/actions";
import PostSection from "./PostSection";
import {CURRENCY_ENUM} from "../../constants/constants";

type PropsTypes = {
    staticTexts?: any;
    seeAllButton?: boolean;
    selectedCurrency?: CURRENCY_ENUM;
    bestOffers?: Array<PostItemType>;
    categories?: Array<CategoriesItemType>;
    GetBestOffers?: any
};

const BestOffersSection: React.FC<PropsTypes> = (props:any) => {
    const {bestOffers, categories,translation, selectedCurrency, seeAllButton = true} = props;
    useEffect(() => {
        categories?.[0]?.id && props.GetBestOffers({
            category: categories?.[0]?.id,
            currency: selectedCurrency,
        })
    }, [categories?.[0]?.id, selectedCurrency])
    const getByCategories = (id: string) => {
        props.GetBestOffers({
            category: id,
            currency: selectedCurrency,
        })
    };

    return <PostSection showHeader={true}
                        seeAllButton={seeAllButton}
                        title={translation?.homepage_bestOffers_title}
                        description={translation?.homepage_bestOffers_description}
                        posts={bestOffers}
                        withTabs={true}
                        tabs={categories}
                        onTabChange={getByCategories}/>

}

const mapStateToProps = (state: any) => getPropsFromState(state, [
    // names (type - string) of props
    'bestOffers',
    'categories',
    'translation',
    'selectedCurrency',

]);

const mapDispatchToProps = {
    //redux actions
    GetBestOffers
};

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(BestOffersSection);
