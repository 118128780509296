// Import packages
import React, { useState, useEffect, useRef } from "react";
import { connect } from 'react-redux';
import moment from "moment";

// Import styles
import './notifications.scss';

import {BackIcon, ClearIcon, BlackArrowRightIcon} from "../../../assets/images";

// Import components

//import utils
import { getPropsFromState } from "../../../redux/mapStateToProps";
import { GetNotifications, /*ToggleNotification*/ } from "../../../redux/actions";
import { DeleteNotification, SetNotificationAsOpened } from "../../../socket/emitters";
import { PER_PAGE_NOTIFICATIONS_COUNT } from "../../../constants/constants";
import NotificationDetails from "./notificationDetails/NotificationDetails";
import {Link} from "react-router-dom";
import { Pagination } from "antd";

function Notifications (props) {
    const { notifications,  selectedLanguage,translation, windowSizes} = props;
    const [detailsData, setDetailsData] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const isFetching = useRef(false)
    useEffect(() => {
        props.GetNotifications({
            offset: 0,
            limit: PER_PAGE_NOTIFICATIONS_COUNT,
        });
        notifications?.list?.forEach(n=>{
            SetNotificationAsOpened(n.id)
        })
    }, [selectedLanguage]);
    useEffect(() => {
        if(notifications?.count > 0 &&  !notifications?.list?.length
            && currentPage > 1 && currentPage * PER_PAGE_NOTIFICATIONS_COUNT > notifications?.count ){
            // case: in last page removing all items
            onPageChanged(currentPage-1)
        }
    }, [notifications?.count])



    async function onPageChanged (page) {
        if (!isFetching.current) {
            isFetching.current = true;
            setCurrentPage(page);
            window.scrollTo({
                top: 0
            });
            await props.GetNotifications({
                offset: (page - 1) * PER_PAGE_NOTIFICATIONS_COUNT,
                limit: PER_PAGE_NOTIFICATIONS_COUNT,
            })
            isFetching.current = false;
        }
    }

    const itemRender = (_, type, originalElement) => {
        if (type === 'prev') {
            return <a className={'prev_btn'}><BlackArrowRightIcon className={'arrow_icon'}/>{translation?.search_page_prevBtn}</a>;
        }
        if (type === 'next') {
            return <a className={'next_btn'}>{translation?.search_page_nextBtn}<BlackArrowRightIcon className={'arrow_icon'}/></a>;
        }
        return originalElement;
    };


    function deleteNotification (e, id) {
        e && e.preventDefault();
        e && e.stopPropagation();
        DeleteNotification(id)
        currentPage * PER_PAGE_NOTIFICATIONS_COUNT < notifications?.count &&
        props.GetNotifications({
            offset: notifications.offset,
            limit: PER_PAGE_NOTIFICATIONS_COUNT,
        });
    }

    function toggleNotificationDetails (data) {
        setDetailsData(data)
    }

    return <section className='notifications-wrapper'>
        <Link to={'/profile'} className={`${(windowSizes?.width || window.innerWidth) < 801 ? 'mobile-back' : 'back'}`}>
            <BackIcon/> <span>{translation?.profile_back_title}</span>
        </Link>
        <div className={'notifications-title'}>Notification</div>
        {/*<div className="notifications-switch-wrapper">*/}
        {/*    {staticTexts?.notifications_turn_off_text}*/}
        {/*    <Switch checked={loggedInUser?.isNotificationsEnable}*/}
        {/*            onClick={() => {*/}
        {/*                // props.ToggleNotification({isNotificationsEnable: !loggedInUser?.isNotificationsEnable})*/}
        {/*            }}/>*/}
        {/*</div>*/}
        {!detailsData?.id ?
            <div className={'notifications'}>
                {!!notifications?.list?.length ? notifications.list.map((item, index) => {
                        return <div className={'notification-item-wrapper'}>
                            <div
                                className={`notification-item-card`}
                                key={item.id}
                                // onClick={() => item?.type === NOTIFICATION_TYPES?.CHANGED_ORDER_STATUS ? openNotificationLink(item?.data?.orderNumber) : toggleNotificationDetails(item)}
                            >
                                <div className="left-part">
                                    <div className="notification-content">
                                        <div className="title">{item.title}</div>
                                        <div className="description-wrapper">
                                            <div className={`description`} dangerouslySetInnerHTML={{__html: item?.description}}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="delete-content" onClick={(e) => deleteNotification(e, item.id)}>
                                    <ClearIcon/></div>
                            </div>
                            <div className="right-part">
                                <div className="notification-date">{moment(item.createdAt).format("DD.MM.YYYY")}</div>
                            </div>
                        </div>
                    }) :
                    <div className="notifications-empty-view">
                        <p>You don't have any notification properties at the moment</p>
                    </div>
                }
            </div>
            : <NotificationDetails data={detailsData}/>}
        <div className={'pagination'}>
            <Pagination current={currentPage}
                        onChange={onPageChanged}
                        total={notifications.count}
                        defaultPageSize={PER_PAGE_NOTIFICATIONS_COUNT}
                        hideOnSinglePage={true}
                        itemRender={itemRender}
                        className={`search__posts_list__pagination ${currentPage===1? 'hide-prev' : ""}`}
            />
        </div>
    </section>
}

const mapStateToProps = (state) => getPropsFromState(state, [
    'staticTexts',
    'selectedLanguage',
    'notifications',
    'loggedInUser',
    'requestLoading',
    'orderStatuses',
    'translation',
    'windowSizes',
]);

const mapDispatchToProps = {
    GetNotifications,
    /*ToggleNotification*/
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
