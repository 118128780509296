// import packages
import React, {useState} from "react";
import {Link, useNavigate} from "react-router-dom";

// Import assets
import "./sign-up.scss"

//Import components

// Import utils
import AuthWrapper from "../../components/auth-wrapper/AuthWrapper";
import {InputGroup} from "../../components/ui-elements/input-group/inputGroup";
import {MainButton} from "../../components/ui-elements/button/Buttons";
import {validateSignUpData} from "../../utils/validateData";
import {AddUser} from "../../redux/actions";
import {getPropsFromState} from "../../redux/mapStateToProps";
import {connect} from "react-redux";
import {Checkbox} from "antd";
import { USER_ERROR_TYPES} from "../../constants/errorTypes";

type MapDispatchPropsType = {
    ClearUser: () => void
    AddUser: (signUpData: any) => Promise<any>
    GetCurrentUser: () => Promise<any>
}

type MapStatePropsType = {
    isLoggedIn: boolean
    staticTexts: any
    requestLoading: boolean
}

type OwnPropsType = {
    location: {
        state: {
            username: string
        }
    }
}

type PropsType = OwnPropsType & MapStatePropsType & MapDispatchPropsType

type SignUpDataType = {
    username?: string,
    password?: string,
    firstName?: string,
    lastName?: string,
    day?: string,
    month?: string,
    year?: string,
}

type InputValueType = {
    name: string,
    value: any
}

const SignUp: React.FC<PropsType> = (props:any) => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(false)
    const [signUpData, setSignUpData] = useState<SignUpDataType>({
        username: '',
        password: '',
        firstName: '',
        lastName: '',
        day: undefined,
        month: undefined,
        year: undefined,
    })
    const [errors, setErrors] = useState({
        username: false,
        password: false,
        firstName: false,
        lastName: false,
        day: false,
        month: false,
        year: false,
        acceptPrivacyPolicy: false,
    })
    const [acceptPrivacyPolicy, setAcceptPrivacyPolicy] = useState(false)
    const [usernameErrorMessage, setUsernameErrorMessage] = useState('')
    const [defaultErrorMessage, setDefaultErrorMessage] = useState('')
    const {translation} = props

    //functions
    function getInputValues({name, value}: InputValueType) {
        if (name==='username'){
            value= value.toLowerCase()
            if (usernameErrorMessage) {
                setUsernameErrorMessage('')
            }
        }
        setSignUpData({
            ...signUpData,
            [name]: value
        })
        if (defaultErrorMessage) {
            setDefaultErrorMessage('')
        }
        // @ts-ignore
        if (errors?.[name]) {
            setErrors({
                ...errors,
                [name]: false
            })
        }
    }

    function handleSignUpErrors(slug: USER_ERROR_TYPES) {
        const updatedErrors = {
            username: false,
            password: false,
            firstName: false,
            lastName: false,
            day: false,
            month: false,
            year: false,
            acceptPrivacyPolicy: false,
        }
        switch (slug) {
            case USER_ERROR_TYPES.USER_ALREADY_EXISTS:
                setUsernameErrorMessage(`${translation?.ERROR_ALREADY_EXISTS}`);
                updatedErrors.username = true
                break;
            default:
                setDefaultErrorMessage(`${translation?.ERROR_VALIDATION}`)
        }
        setErrors(updatedErrors)
    }

    function signUpUser() {
        // console.log('signUpData', signUpData);
        setLoading(true)
        let validationErrors = {
            username: false,
            password: false,
            firstName: false,
            lastName: false,
            day: false,
            month: false,
            year: false,
            acceptPrivacyPolicy: false,
        };
        const valid = validateSignUpData({...signUpData, acceptPrivacyPolicy}, validationErrors);
        // console.log('validationErrors', validationErrors, valid);

        if (valid) {
            const reqData = {
                username: signUpData.username,
                password: signUpData.password,
                firstName: signUpData.firstName,
                lastName: signUpData.lastName
            }
            if(!signUpData.lastName){
                delete reqData.lastName;
            }
            if (signUpData.day) {
                // @ts-ignore
                reqData.birthday = `${signUpData.year}-${signUpData.month}-${signUpData.day}`
            }
            props.AddUser(reqData).then(() => {
                navigate('/auth/confirm-email', {
                    state: {
                        redirectedFromSignUp: true,
                        username: signUpData.username
                    }
                })
            }).catch((error: any) => {
                const slug = error && error.response && error.response.data && error.response.data.slug;
                // console.log("error.slug", slug);
                handleSignUpErrors(slug);
            }).finally(()=>{
                setLoading(false)
            })
        } else {
            setLoading(false)
            setErrors({...errors, ...validationErrors});
        }
    }

    return <AuthWrapper className={'sign_up'}>
            <div className="title">{translation?.signUp_page_title}</div>

            <InputGroup
                inputType={'input'}
                type={"text"}
                name={'username'}
                value={signUpData.username}
                error={errors.username}
                disabled={loading}
                required
                placeholder={translation?.signUp_page_placeholder_user}
                label={translation?.signUp_page_label_user}
                autoComplete={'off'}
                regExp={/^[a-zA-Z0-9@.-]*$/}
                maxLength={50}
                onChange={getInputValues}
            >
                <div className={`error-message ${!!usernameErrorMessage ? 'show' : 'hide'}`}>
                    {usernameErrorMessage}
                </div>
            </InputGroup>

            <InputGroup
                inputType={'input'}
                type={"text"}
                name={'firstName'}
                disabled={loading}
                required
                value={signUpData.firstName}
                error={errors.firstName}
                placeholder={translation?.signUp_page_placeholder_firstName}
                label={translation?.signUp_page_label_firstName}
                maxLength={50}
                onChange={getInputValues}
            />

            <InputGroup
                inputType={'input'}
                type={"text"}
                name={'lastName'}
                disabled={loading}
                value={signUpData.lastName}
                error={errors.lastName}
                placeholder={translation?.signUp_page_placeholder_lastName}
                label={translation?.signUp_page_label_lastName}
                maxLength={50}
                onChange={getInputValues}
            />

            <div className="date_wrapper">
                <label>{translation?.signUp_page_label_date}</label>
                <div className={'inputs'}>
                    <InputGroup
                        showSearch={false}
                        inputType={'select'}
                        value={signUpData.day}
                        disabled={loading}
                        name={'day'}
                        error={errors.day}
                        placeholder={translation?.signUp_page_placeholder_day}
                        onChange={(name: string, value: string) => getInputValues({name, value})}
                        options={Array.from(Array(31)).map((_, i) => {
                            return {
                                id: `${i <= 8 ? '0' : ''}${i + 1}`, // i + 1,
                                name: `${i <= 8 ? '0' : ''}${i + 1}`
                            }
                        })}/>
                    <InputGroup
                        showSearch={false}
                        inputType={'select'}
                        value={signUpData.month}
                        error={errors.month}
                        disabled={loading}
                        name={'month'}
                        placeholder={translation?.signUp_page_placeholder_month}
                        onChange={(name: string, value: string) => getInputValues({name, value})}
                        options={Array.from(Array(12)).map((_, i) => {
                            return {
                                id: `${i <= 8 ? '0' : ''}${i + 1}`, // i + 1,
                                name: `${i <= 8 ? '0' : ''}${i + 1}`
                            }
                        })}/>
                    <InputGroup
                        showSearch={false}
                        inputType={'select'}
                        value={signUpData.year}
                        error={errors.year}
                        disabled={loading}
                        name={'year'}
                        placeholder={translation?.signUp_page_placeholder_year}
                        onChange={(name: string, value: string) => getInputValues({name, value})}
                        options={Array.from(Array(100)).map((_, i) => {
                            const date = new Date().getFullYear()
                            return {
                                id: date - 100 + i + 1,
                                name: date - 100 + i + 1
                            }
                        })}/>
                </div>
            </div>

            <InputGroup
                inputType={'input'}
                type={`password`}
                name={'password'}
                disabled={loading}
                required
                label={translation?.signUp_page_label_password}
                placeholder={translation?.signUp_page_placeholder_password}
                value={signUpData.password}
                error={errors.password}
                maxLength={20}
                autoComplete={'off'}
                onKeyDown={(e: any) => {
                    e.key === 'Enter' && signUpUser()
                }}
                onChange={getInputValues}
            >
            </InputGroup>
        <div className={`warning-text ${errors.password ? 'error' : ''}`}>
            {translation?.signUp_page_password_warning}
        </div>
            <div className="accept-privacy-policy">
                <Checkbox
                    className={`privacy-policy-checkbox ${errors.acceptPrivacyPolicy ? 'error' : ''}`}
                    checked={acceptPrivacyPolicy}
                    onChange={() => setAcceptPrivacyPolicy(!acceptPrivacyPolicy)}/>
                <div className={'privacy-policy-text'}>
                    <Link to={'/privacy-policy'} className="privacy-policy-link">
                        {translation?.signUp_page_privacy_policy}
                    </Link>
                </div>
            </div>
            <div className={`error-message ${!!defaultErrorMessage ? 'show' : 'hide'}`}>
                {defaultErrorMessage}
            </div>
            <MainButton title={translation?.signUp_page_btn_title}
                        loading={loading}
                        click={signUpUser}
                        className="button"/>
            <MainButton title={translation?.signUp_page_signIn_btn_title}
                        className="button"
                        lightBordered
                        link={'/auth/sign-in'}/>


{/*            <MainButton title={'Navigate'}
                        lightBordered
                        click={()=>{
                            navigate('/auth/confirm-email', {
                                state: {
                                    redirectedFromSignUp: true,
                                    username: 'test@gmail.com'
                                }
                            })
                        }}
                        className="button"/>*/}
    </AuthWrapper>
}

const mapDispatchToProps = {
    AddUser
}
const mapStateToProps = (state: any) => {
    return getPropsFromState(state, [
        'translation'
    ])
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
