// Import packages
import React, {useState,useEffect} from "react";


// Import components
import {InputGroup} from "../../../components/ui-elements/input-group/inputGroup";
import { DecreaseIcon, IncreaseIcon, SwapIcon} from "../../../assets/images";

// Import utils.
import { CURRENCY_VALUE} from "../../../constants/constants";
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {connect} from "react-redux";


const CurrencyTypes = {
    AMD: 'AMD',
    USD: 'USD',
    RUB: 'RUB',
}

const currencyOptions = [
    {id: 'AMD', name: 'AMD'},
    {id: 'USD', name: 'USD'},
    {id: 'RUB', name: 'RUB'},
];


const Exchange = (props) => {
    const { ratesList ,translation} = props
    const [date,setDate] = useState()

    const [calculatorData, setCalculatorData] = useState({
        currencyFrom: CurrencyTypes.AMD,
        currencyTo: CurrencyTypes.USD,
        currencyFromAmount: "",
        currencyToAmount: "",
    })

    useEffect(() => {
        let data = new Date()
        const today = data?.toLocaleDateString('da-DK')
        setDate(today)
    }, []);

    function getCurrencyValues({name, value}) {
        const data = {
            ...calculatorData,
            [name]: value
        }

        if (name.startsWith('currencyFrom')) {
            const currValue = name.includes('Amount') ? +value : calculatorData.currencyFromAmount
            const curr = name.includes('Amount') ? calculatorData.currencyFrom : value
            const rate = `${curr}_${calculatorData.currencyTo}`

            data.currencyToAmount = Math.floor(currValue * (ratesList.find(item => item.currency.includes(rate))?.rate || 1) * 100) / 100
        } else if (name.startsWith('currencyTo')) {
            const currValue = name.includes('Amount') ? +value : calculatorData.currencyToAmount
            const curr = name.includes('Amount') ? calculatorData.currencyFrom : value
            const rate = `${calculatorData.currencyTo}_${curr}`

            data.currencyFromAmount = Math.floor(currValue * (ratesList.find(item => item.currency.includes(rate))?.rate || 1) * 100) / 100
        }
        setCalculatorData(data)
    }

    function currencyInverse() {
        setCalculatorData({
            ...calculatorData,
            currencyFrom: calculatorData.currencyTo,
            currencyTo: calculatorData.currencyFrom,
            currencyToAmount: calculatorData.currencyFromAmount,
            currencyFromAmount: calculatorData.currencyToAmount,
        })
    }

    return <>
        <table className={'filter__currency_table'}>
            <tbody>
            <tr>
                <td>{translation?.homepage_currency_exchange}</td>
                <td/>
                <td/>
            </tr>
            {
                ratesList?.filter((item/*: CurrencyItemType*/) => CURRENCY_VALUE.includes(item?.currency)).map(item => {
                    return <tr key={item?.currency}>
                        <td>{item?.currency?.replace('_', ' / ')}</td>
                        <td>{item?.rate}</td>
                        {
                            String(item?.difference)?.startsWith('-') ? <td>
                                    <DecreaseIcon/>{Math.abs(item?.difference || 0)?.toFixed(2)}
                                </td>
                                : <td><IncreaseIcon/>{item?.difference?.toFixed(2)}</td>
                        }
                    </tr>
                })
            }
            </tbody>
        </table>

        <div className="filter__currency_title">
            {translation?.homepage_currency_change_title}
        </div>

        <div className="filter__change_currency_wrapper">

            <div className="from">
                <InputGroup
                    showSearch={false}
                    inputType={'select'}
                    value={calculatorData.currencyFrom}
                    name={'currencyFrom'}
                    placeholder={'From'}
                    onChange={(name, value) => getCurrencyValues({name, value})}
                    options={currencyOptions}/>
                <InputGroup
                    inputType={'input'}
                    type={"text"}
                    value={calculatorData.currencyFromAmount}
                    name={'currencyFromAmount'}
                    maxLength={50}
                    regExp={/^[\.0-9]*$/}
                    placeholder={translation?.homepage_currency_placeholder_amount}
                    onChange={getCurrencyValues}
                />
            </div>

            <SwapIcon className="swap_icon" onClick={currencyInverse}/>

            <div className="to">
                <InputGroup
                    showSearch={false}
                    inputType={'select'}
                    value={calculatorData.currencyTo}
                    name={'currencyTo'}
                    placeholder={'To'}
                    onChange={(name, value) => getCurrencyValues({name, value})}
                    options={currencyOptions}/>
                <InputGroup
                    inputType={'input'}
                    type={"text"}
                    value={calculatorData.currencyToAmount}
                    name={'currencyToAmount'}
                    maxLength={50}
                    regExp={/^[\.0-9]*$/}
                    placeholder={translation?.homepage_currency_placeholder_amount}
                    onChange={getCurrencyValues}
                />
            </div>
        </div>
        <div className="filter__currency_text">{translation?.homepage_currency_last_update} {date}</div>
    </>
}
const mapStateToProps = (state) => getPropsFromState(state, [
    'translation',
]);

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(Exchange);
