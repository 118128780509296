// Import packages
import React, {useEffect} from "react";
import {connect} from 'react-redux';

// Import styles
import './privacy-policy.scss';

// Import components

//import utils
import {getPropsFromState} from "../../redux/mapStateToProps";
import {ImgPrivacyPolicy} from "../../assets/images";

function PrivacyPolicy(props) {
    const {contact} = props;
    useEffect(  () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    })

    return <section className='PrivacyWrapper'>
        <img src={ImgPrivacyPolicy} alt=""/>
        <div className={'text'}>
            <div className="privacy-policy-text" dangerouslySetInnerHTML={{__html: contact?.privacyPolicy}}/>
        </div>
    </section>
}

const mapStateToProps = (state) => getPropsFromState(state, [
    'translation',
    'contact',
]);

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy);