// import packages
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';

//Import assets
import './profile.scss';

//Import Components
import Settings from "./settings/Settings";

// Import utils
import { getPropsFromState } from "../../redux/mapStateToProps";
import { GetCurrentUser, LogOut, UploadUserProfilePicture } from "../../redux/actions";

import Favorites from "./favorites/Favorites";
import Notifications from "./notifications/Notifications";
import { ImageUploader } from "../../components/profileImageUploader/profileImageUploader";
import Compare from "./compare/Compare";
import PostSection from "../../components/post-section/PostSection";


const Profile = (props) => {
    const params = useParams();
    const navigate = useNavigate();
    let location = useLocation()

    const { pageName } = params;
    const { translation, loggedInUser, windowSizes } = props;
    const isMobile = windowSizes?.width <= 800
    const [profilePictureFile, setProfilePictureFile] = useState(null);
    useEffect(() => {
        if (!props.isLoggedIn) {
            navigate('/')
        }
        if (!pageName) {
            // history.push("/profile/settings");
        }
    }, [pageName]);

    const getSelectedImageFile = async (file) => {
        if (isMobile && file) {
            let formData = new FormData();
            formData.append('profilePicture', file);
            await props.UploadUserProfilePicture(formData)
            await props.GetCurrentUser()
        } else {
            setProfilePictureFile(file);
        }
    };

    return <div className="user-details-wrapper">
        <div className={'user-details-content'}>
            <div className={`left-side-menu ${location.pathname === '/profile' ? 'mobile-menu' : 'menu-none'}`}>
                <div className="left-side-menu-fixed">
                    <div className="left-side-menu-header">
                        <div className="uploader-wrapper">
                            <ImageUploader
                                disabled={(!isMobile && pageName !== 'settings')}
                                loggedInUser={loggedInUser}
                                translation={translation}
                                onChange={getSelectedImageFile}/>
                        </div>
                        <div className="menu-header-content">
                            <div
                                className="header-name">{props?.loggedInUser?.firstName + ' ' + props?.loggedInUser?.lastName}</div>
                            <div className="header-email">{props?.loggedInUser?.username}</div>
                        </div>
                    </div>
                    <div className={`aside-menu-list`}>
                        <li>
                            <NavLink to="/profile/settings" activeClassName='active'>
                                <span>{translation?.profile_linkSettings_title}</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/profile/compare" activeClassName='active'>
                                <span>{translation?.profile_linkCompare_title}</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/profile/favorites" activeClassName='active'>
                                <span>{translation?.profile_linkFavorite_title}</span>
                            </NavLink>
                        </li>

                        <li className="menu-notification">
                            <NavLink to="/profile/notifications" activeClassName='active'>
                                <span>{translation?.profile_linkNotification_title}</span>
                            </NavLink>
                            {!!props.nonOpenedNotificationCount && <div className="badge">
                                {props?.nonOpenedNotificationCount < 10 ? props?.nonOpenedNotificationCount : '9+'}
                            </div>}
                        </li>
                        <li onClick={() => {
                            props.LogOut()
                            navigate('/')
                        }
                        } className="logout-wrapper">
                            <span>{translation?.profile_linkLogOut_title}</span>
                        </li>
                    </div>
                </div>
            </div>
            <div className={`profile-content-wrapper ${location.pathname === '/profile' ? 'mobile-profile' : ''}`}>
                <div className="content-wrapper">
                    {pageName === 'settings' && <Settings profilePictureFile={profilePictureFile}/>}
                    {pageName === 'compare' && <Compare/>}
                    {pageName === 'favorites' && <Favorites/>}
                    {pageName === 'notifications' && <Notifications/>}
                </div>
            </div>
        </div>
        {!!props?.recentlyViewed?.length && <PostSection showHeader={true}
                                                         title={translation?.homepage_post_section_title}
                                                         description={translation?.homepage_post_section_description}
                                                         posts={props.recentlyViewed}/>}
    </div>
}

const mapStateToProps = (state) => getPropsFromState(state, [
    'translation',
    'isLoggedIn',
    'loggedInUser',
    'recentlyViewed',
    'nonOpenedNotificationCount',
    'windowSizes',
]);

const mapDispatchToProps = {
    LogOut,
    GetCurrentUser,
    UploadUserProfilePicture
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);


