// import packages
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {connect} from "react-redux";

// Import assets
import "./passord.scss"

//Import components
import AuthWrapper from "../../components/auth-wrapper/AuthWrapper";
import {InputGroup} from "../../components/ui-elements/input-group/inputGroup";
import {MainButton} from "../../components/ui-elements/button/Buttons";

// Import utils
import {USER_ERROR_TYPES} from "../../constants/errorTypes";
import {getPropsFromState} from "../../redux/mapStateToProps";
import {ResetUserPassword} from "../../redux/actions";

type MapDispatchPropsType = {
    ResetUserPassword: (data: any) => Promise<any>
}

type MapStatePropsType = {
    isLoggedIn: boolean
    staticTexts: any
}

type PropsType = MapStatePropsType & MapDispatchPropsType


type InputValueType = {
    name: string,
    value: any
}

const ResetPassword: React.FC<PropsType> = (props:any) => {
    const {isLoggedIn,translation} = props
    const [loading, setLoading] = useState<boolean>(false)
    const [password, setPassword] = useState<string>('')
    const [errorMessages, setErrorMessages] = useState<string>('')
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!location.state.code || !location.state.username) {
            navigate('/')
        }
    }, [])

    //functions
    function getInputValues({value}: InputValueType) {
        setPassword(value)
        setErrorMessages('')
    }

    function handleResetPassErrors(slug: string) {
        // console.log('handleSignInErrors, slug: ', slug, errorMessages)
        switch (slug) {
            case USER_ERROR_TYPES.USER_NOT_FOUND:
                setErrorMessages(`${translation?.ERROR_USER_NOT_FOUND}`)
                break;
            case USER_ERROR_TYPES.USER_NOT_ACTIVATED:
                setErrorMessages(`${translation?.ERROR_USER_NOT_ACTIVATED}`)
                break;
        }
    }

    async function resetPassword() {
        setLoading(true)
        setErrorMessages('')
        const reqData = {
            username: location.state.username,
            code: location.state.code,
            password: password,
        }
        await props.ResetUserPassword(reqData).then(() => {
            navigate('/')
        }).catch((error: any) => {
            const slug = error && error.response && error.response.data && error.response.data.slug;
            // console.log("error.response", error.response);
            if (slug) {
                handleResetPassErrors(slug);
            }
        }).finally(()=>{
            setLoading(false)
        })
    }


    return <AuthWrapper className={'password-form'}>
        <div className="title">{translation?.reset_password}</div>
        <div className={'text'}>{translation?.reset_password_description}</div>
        <InputGroup
            inputType={'input'}
            type={`password`}
            name={'password'}
            label={translation?.reset_password_label}
            disabled={loading}
            placeholder={translation?.reset_password_placeholder}
            value={password}
            maxLength={20}
            autoComplete={'off'}
            onKeyDown={(e: any) => {
                e.key === 'Enter' && password.length >= 8 && resetPassword()
            }}
            onChange={getInputValues}
        />
        <div className={`error-message ${!!errorMessages ? 'show' : 'hide'}`}>
            {errorMessages}
        </div>
        <MainButton title={'Save'}
                    loading={loading}
                    disabled={!password || (password.length < 8)}
                    className="button"
                    click={resetPassword}/>
    </AuthWrapper>
}

const mapDispatchToProps = {
    ResetUserPassword
}
const mapStateToProps = (state: any) => {
    return getPropsFromState(state, [
        'isLoggedIn',
        'translation'
    ])
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
