// Import packages
import React, {useEffect, useState} from "react";

// Import assets
import "./currency.scss"

// Import components
import {InputGroup} from "../../../components/ui-elements/input-group/inputGroup";
import {DecreaseIcon, IncreaseIcon, SwapIcon} from "../../../assets/images";
import {CurrencyItemType} from "../../../redux/actions/actionTypes";
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {connect} from "react-redux";
import {CURRENCY_ENUM, CURRENCY_VALUE} from "../../../constants/constants";

// Import utils.

type InputValueType = {
    name: string,
    value: any
}

type InputStateType = {
    currencyFrom: undefined | string,
    currencyTo: undefined | string,
    currencyFromAmount: string | number,
    currencyToAmount: string | number,
}

const currencyOptions = [
    {id: 'AMD', name: 'AMD'},
    {id: 'USD', name: 'USD'},
    {id: 'RUB', name: 'RUB'},
];

const Currency: React.FC = (props: any) => {
    const{ratesList,translation} = props;
    const [date,setDate] = React.useState<string>()
    const [fieldsData, setFieldsData] = useState<InputStateType>({
        currencyFrom: CURRENCY_ENUM.AMD,
        currencyTo: CURRENCY_ENUM.USD,
        currencyFromAmount: "",
        currencyToAmount: "",
    })

    useEffect(() => {
        let data = new Date()
        const today = data?.toLocaleDateString('da-DK')
        setDate(today)
    }, []);

    function getInputValues({name, value}: InputValueType) {
        const data: InputStateType = {
            ...fieldsData,
            [name]: value
        }

        if (name.startsWith('currencyFrom')) {
            const currAmount: any = name.includes('Amount') ? +value : fieldsData.currencyFromAmount
            const curr = name.includes('Amount') ? fieldsData.currencyFrom : value
            const rate = `${curr}_${fieldsData.currencyTo}`

            data.currencyToAmount = (Math.floor(currAmount * (ratesList.find((item: CurrencyItemType) => item.currency.includes(rate))?.rate || 1) * 100) / 100) || "0.00"
        } else if (name.startsWith('currencyTo')) {
            if(name.includes('Amount')){
                const rate = `${fieldsData.currencyTo}_${fieldsData.currencyFrom}`
                data.currencyFromAmount = (Math.floor( +value * (ratesList.find((item: CurrencyItemType) => item.currency === rate)?.rate || 1) * 100) / 100) || "0.00"
            }else{
                const rate = `${fieldsData.currencyFrom}_${value}`
                data.currencyToAmount = (Math.floor(+fieldsData.currencyFromAmount * (ratesList.find((item: CurrencyItemType) => item.currency === rate )?.rate || 1) * 100) / 100) || "0.00"
            }
        }
        // console.log(data, 'data');
        setFieldsData(data)
    }

    function currencyInverse() {
        setFieldsData({
            ...fieldsData,
            currencyFrom: fieldsData.currencyTo,
            currencyTo: fieldsData.currencyFrom,
            currencyToAmount: fieldsData.currencyFromAmount,
            currencyFromAmount: fieldsData.currencyToAmount,
        })
    }

    return <div className={`home_currency`}>
        <div className="home_currency__table_wrapper">
            <div className="home_currency__table_wrapper__title">{translation?.homepage_currency_title}</div>
            <table>
                <tbody>
                <tr>
                    <td>{translation?.homepage_currency_exchange}</td>
                    <td/>
                    <td/>
                </tr>
                {
                    ratesList?.filter((item: CurrencyItemType) => CURRENCY_VALUE.includes(item?.currency)).map((item: CurrencyItemType) => {
                        return <tr key={item?.currency}>
                            <td>{item?.currency?.replace('_', ' / ')}</td>
                            <td>{item?.rate}</td>
                            {
                                String(item?.difference)?.startsWith('-') ?
                                    <td><DecreaseIcon/>{Math.abs(item?.difference || 0)?.toFixed(2)}</td>
                                    : <td><IncreaseIcon/>{item?.difference?.toFixed(2)}</td>
                            }
                        </tr>
                    })
                }
                </tbody>
            </table>
        </div>

        <div className="home_currency__change_currency_wrapper">
            <div className="home_currency__change_currency_wrapper__title">
                {translation?.homepage_currency_change_title}
            </div>

            <div className="home_currency__change_currency_wrapper__form">

                <div className="from">
                    <InputGroup
                        showSearch={false}
                        inputType={'select'}
                        value={fieldsData.currencyFrom}
                        name={'currencyFrom'}
                        placeholder={'From'}
                        onChange={(name:string, value: string) => getInputValues({name, value})}
                        options={currencyOptions}/>
                    <InputGroup
                        inputType={'input'}
                        type={"text"}
                        value={fieldsData.currencyFromAmount}
                        name={'currencyFromAmount'}
                        maxLength={50}
                        regExp={/^[\.0-9]*$/}
                        placeholder={translation?.homepage_currency_placeholder_amount}
                        onChange={getInputValues}
                    />
                </div>

                <SwapIcon className="swap_icon" onClick={currencyInverse}/>

                <div className="to">
                    <InputGroup
                        showSearch={false}
                        inputType={'select'}
                        value={fieldsData.currencyTo}
                        name={'currencyTo'}
                        placeholder={'To'}
                        onChange={(name:string, value: string) => getInputValues({name, value})}
                        options={currencyOptions}/>
                    <InputGroup
                        inputType={'input'}
                        type={"text"}
                        value={fieldsData.currencyToAmount}
                        name={'currencyToAmount'}
                        maxLength={50}
                        regExp={/^[\.0-9]*$/}
                        placeholder={translation?.homepage_currency_placeholder_amount}
                        onChange={getInputValues}
                    />
                </div>
            </div>

            <div className="home_currency__change_currency_wrapper__text">{translation?.homepage_currency_last_update} {date}</div>
        </div>
    </div>
}
const mapStateToProps = (state: any) => getPropsFromState(state, [
    // names (type - string) of props
    'ratesList',
    'translation',
]);

const mapDispatchToProps = {
    //redux actions
};

export default connect(mapStateToProps, mapDispatchToProps)(Currency);
