// Import packages
import React, {Component} from "react";
import {Route, Routes} from "react-router-dom";

// Import utils

// Import pages
import Layout from "../containers/layout/Layout";
import Homepage from "../containers/homepage/Homepage";
import Search from "../containers/search/Search";
import PostDetails from "../containers/post/PostDetails";
import SignIn from "../containers/signIn/SignIn";
import SignUp from "../containers/signUp/SignUp";
import ForgotPassword from "../containers/forgot-password/ForgotPassword";
import ResetPassword from "../containers/forgot-password/ResetPassword";
import Profile from "../containers/profile/Profile";
import About from "../containers/about/About";
import Contact from "../containers/contact/Contact";
import VerifyEmail from "../containers/verifyEmail/VerifyEmail";
import Faq from "../containers/faq/Faq";
import Compare from "../containers/compare/Compare";
import PrivacyPolicy from "../containers/privacy/PrivacyPolicy";


export default class RoutesWrap extends Component {
    render() {
        return <Layout>
            <Routes >
                <Route path="/" element={<Homepage/>}/>
                <Route path="/auth/sign-in" element={<SignIn/>}/>
                <Route path="/auth/sign-up" element={<SignUp/>}/>
                <Route path="/auth/confirm-email" element={<VerifyEmail/>}/>
                <Route path="/auth/forgot-password" element={<ForgotPassword/>}/>
                <Route path="/auth/reset-password" element={<ResetPassword/>}/>
                <Route path="/compare" element={<Compare/>}/>
                <Route path="/about" element={<About/>}/>
                <Route path="/contact" element={<Contact/>}/>
                <Route path="/search" element={<Search/>}/>
                <Route path="/urgent" element={<Search/>}/>
                <Route path="/profile/:pageName" element={<Profile/>}/>
                <Route path="/profile" element={<Profile/>}/>
                <Route path="/post/:slug" element={<PostDetails/>}/>
                 <Route path="/faq" element={<Faq/>}/>
                <Route  path={`/privacy-policy`} element={<PrivacyPolicy/>}/>
            </Routes>
        </Layout>
    }
}
