// import packages
import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { useLocation, useNavigate } from "react-router-dom";

//Import styles
import "./verifyEmail.scss"
import { withRouter } from '../../components/withRouter'
//Import Components
import AuthWrapper from "../../components/auth-wrapper/AuthWrapper";
import { Timer} from "../../components/hooks";
import { MainButton } from "../../components/ui-elements/button/Buttons";
import { SingleInputsGroup } from "../../components/ui-elements/single-input-group/SingleInputsGroup";

// Import utils
import {
    ResendActivation,
    ActivateUser, ForgotUserPassword, CheckResetPasswordCode
} from "../../redux/actions";
import {getPropsFromState} from "../../redux/mapStateToProps";
import { USER_ERROR_TYPES } from "../../constants/errorTypes";

const NOTIFICATION_RESEND_TIMER_SECOND = 59;

function VerifyEmail (props) {
    const { translation } = props;
    const location = useLocation();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false)
    const [state, setState] = useState({
        canReSendEmail: false,
        username: location?.state?.username,
        redirectedFromForgotPassword: !!location?.state?.redirectedFromForgotPassword,
        redirectedFromSignUp: !!location?.state?.redirectedFromSignUp,
        code: ''
    })
    const [timerVisible, setTimerVisible] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const { canReSendEmail, username, redirectedFromSignUp, redirectedFromForgotPassword, code } = state;
    useEffect(() => {
        if (!location?.state?.username || !(state.redirectedFromForgotPassword || state.redirectedFromSignUp)) {
            navigate('/')
        } else {
            setTimerVisible(true)
        }
    }, [])

    function timeOutCallback () {
        setState({
            ...state,
            canReSendEmail: true,
        })
        setTimerVisible(false)
    }

    function getCodeNumbers (code) {
        if (errorMessage) {
            setErrorMessage('')
        }
        setState({
            ...state,
            code,
        })
    }

    function handleErrors (slug) {
        switch ( slug ) {
            case USER_ERROR_TYPES.USER_ALREADY_ACTIVATED:
                setErrorMessage(`${translation?.ERROR_USER_ALREADY_ACTIVATED}`);
                break;
            case USER_ERROR_TYPES.USER_NOT_FOUND:
                setErrorMessage(`${translation?.ERROR_USER_NOT_FOUND}`);
                break;
            case USER_ERROR_TYPES.USER_NOT_ACTIVATED:
                setErrorMessage(`${translation?.ERROR_USER_NOT_ACTIVATED}`);
                break;
            case USER_ERROR_TYPES.CODE_INVALID:
                setErrorMessage(`${translation?.ERROR_CODE_INVALID}`);
                break;
            case USER_ERROR_TYPES.CODE_NOT_FOUND:
                setErrorMessage(`${translation?.ERROR_CODE_INVALID}`);
                break;
            default:
                setErrorMessage(`${translation?.ERROR_SOMETHING_WRONG}`)
        }
    }

    function Confirm () {
        const reqData = {}
        reqData.username = username
        reqData.code = code;
        setLoading(true);
        let promise = null;
        if (redirectedFromSignUp) {
            promise = props.ActivateUser(reqData).then((res) => {
                navigate('/')
            }).catch((error) => {
                const slug = error && error.response && error.response.data && error.response.data.slug;
                // console.log("error.response",slug);
                handleErrors(slug)
            })
        }
        if (redirectedFromForgotPassword) {
            promise = props.CheckResetPasswordCode(reqData).then((res) => {
                navigate('/auth/reset-password', {
                    state: {
                        username: username,
                        code: code
                    }
                })
            }).catch((error) => {
                const slug = error && error.response && error.response.data && error.response.data.slug;
                handleErrors(slug)
            })
        }
        promise.finally(() => {
            setLoading(false)
        })
    }

    async function reSendEmail () {
        setState({
            ...state,
            canReSendEmail: false,
        })
        // console.log('redirectedFromSignUp', redirectedFromSignUp);
        // console.log('redirectedFromForgotPassword', redirectedFromForgotPassword);
        if (redirectedFromSignUp) {
            props.ResendActivation(username).then(() => {
                setTimerVisible(true)
            }).catch(error => {
                const slug = error && error.response && error.response.data && error.response.data.slug;
                handleErrors(slug)
            })
        }
        if (redirectedFromForgotPassword) {
            props.ForgotUserPassword(username).then(() => {
                setTimerVisible(true)
            }).catch(error => {
                // console.log('ForgotUserPassword, slug')

                const slug = error && error.response && error.response.data && error.response.data.slug;
                handleErrors(slug)
            })
        }
    }


    return <AuthWrapper className={'verify'}>
        <div className="title">{translation?.verifyEmail_page_title}</div>
        {/*<h1>{'staticTexts?.verify_email_title'}</h1>*/}
        <div className="text">
            {translation?.verifyEmail_page_description}
            <span className="email"> &nbsp; {username} &nbsp;</span>
        </div>

        <label className={'confirmation-code'}>{translation?.verifyEmail_confirm_label}</label>
        <div className="code-container">
            <SingleInputsGroup value={code}
                               loading={loading}
                               onChange={getCodeNumbers}/>
        </div>
        <div className={`error-message ${!!errorMessage ? 'show' : 'hide'}`}>
            {errorMessage}
        </div>
        <MainButton title={translation?.verifyEmail_page_confirm} className="send-btn"
                    disabled={code?.length !== 6}
                    loading={loading}
                    click={Confirm}
        />

        <div className="seconds">
                        <span className={`resend-btn ${canReSendEmail ? 'active' : ''}`}
                              onClick={() => {
                                  canReSendEmail && reSendEmail()
                              }}>&nbsp;{translation?.verifyEmail_page_send}</span>
            {
                timerVisible && <div className="timer">
                    <Timer startTime={NOTIFICATION_RESEND_TIMER_SECOND}
                           timeOutCallback={timeOutCallback}
                    />
                </div>
            }
        </div>
    </AuthWrapper>

}

const mapDispatchToProps = {
    ActivateUser,
    ResendActivation,
    ForgotUserPassword,
    CheckResetPasswordCode,
};
const mapStateToProps = (state) => getPropsFromState(state, [
    // names (type - string) of props
    'translation',
]);
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(VerifyEmail));
