//----------------------------------4Steps-server---------------------------------------------------

const TESTING_URLS = {
    AUTH_URL: "http://173.249.20.192:8800/api/v1",
    USERS_URL: "http://173.249.20.192:8801/api/v1",
    POST_URL: "http://173.249.20.192:8804/api/v1",
    MEDIA_URL: "http://173.249.20.192:8803/api/v1",
    HOST_MEDIA_URL: "http://173.249.20.192:8803",
    ADMIN_URL: "http://173.249.20.192:8802/api/v1",
    NOTIFICATION_URL: "http://173.249.20.192:8805/api/v1",
    SOCKET_NOTIFICATION_URL: "http://173.249.20.192:8805",
    WEB_PAGE_HOST: "http://ap-reality.4steps.am",
    EXCHANGE_URL: 'http://173.212.233.220:3333/api/v1',
}

//----------------------------------Official---------------------------------------------------
const OFFICIAL_URLS = {
    AUTH_URL: "https://app.aprealty.am/auth/api/v1",
    USERS_URL: "https://app.aprealty.am/user/api/v1",
    POST_URL: "https://app.aprealty.am/post/api/v1",
    ADMIN_URL: "https://app.aprealty.am/admin/api/v1",
    MEDIA_URL: "https://app.aprealty.am/resources/api/v1",
    HOST_MEDIA_URL: "https://app.aprealty.am/resources",
    NOTIFICATION_URL: "https://app.aprealty.am/notification/api/v1",
    SOCKET_NOTIFICATION_URL: "https://app.aprealty.am",
    EXCHANGE_URL: "https://app.aprealty.am/exchange/api/v1",

    WEB_PAGE_HOST: "https://aprealty.am"
}

const {
    ADMIN_URL, AUTH_URL, USERS_URL, MEDIA_URL, POST_URL, HOST_MEDIA_URL, SOCKET_NOTIFICATION_URL,
    NOTIFICATION_URL, WEB_PAGE_HOST, EXCHANGE_URL
} = process.env.REACT_APP_ENVIRONMENT === 'production'
    ? OFFICIAL_URLS
    : TESTING_URLS;

export const _urlUsers = USERS_URL + "/users";
export const _urlStaticTexts = ADMIN_URL + "/static-texts";
export const _urlOauth = AUTH_URL + "/jwt";

export const _urlPost = POST_URL + "/posts";
export const _urlCategories = POST_URL + "/categories";
export const _urlSlider = POST_URL + "/sliders";
export const _urlFilters = POST_URL + "/filters";
export const _urlBanners = POST_URL + "/banners";
export const _urlSubscribe = USERS_URL + "/subscribe";
export const _urlInformation = POST_URL + "/information";
export const _urlLanguage = POST_URL + "/languages";
export const _urlPostRegions = POST_URL + "/regions";
export const _urlStatistics = POST_URL + "/statistics";
export const _urlVisit = POST_URL + "/visit-request";

export const _urlMedia = MEDIA_URL + "/media";
export const _hostMedia = HOST_MEDIA_URL;

export const _urlRegions = USERS_URL + "/regions";
export const _urlContacts = USERS_URL + "/contacts";
export const _urlFaq = USERS_URL + "/faq";
export const _urlExchange = EXCHANGE_URL;


export const _urlNotification = NOTIFICATION_URL;
export const _urlSocketNotification = SOCKET_NOTIFICATION_URL

export const WEB_PAGE_URL = WEB_PAGE_HOST
