import {CurrencyObjectType} from "../types/types";

export const PER_PAGE_POSTS_COUNT = 9
export const PER_PAGE_NOTIFICATIONS_COUNT = 10
export const ACCEPT_TYPES = ['.jpg', '.jpeg', '.png','.svg','.webp']; // '.gif', '.bpm',
export const ACCEPT_IMAGE_SIZE = 10485760; // 10mb

export enum TIME_CITY {
    'menu_header_clock_city_Yerevan' = 4,
    'menu_header_clock_city_Moscow' = 3,
    'menu_header_clock_city_Paris' = 2,
    'menu_header_clock_city_Los_Angelos' = 28,
}

export enum POST_ENUM {
    SELL = 'SELL',
    RENT = "RENT"
}


export const POST_TYPES = {
    SELL: {
        key: "SELL",
        title: 'filter_type_title_SELL'
    },
    RENT: {
        key: "RENT",
        title: "filter_type_title_RENT",
    },
};
export const CONDITION_TYPES = {
    NEW_HOME:'NEW_HOME',
    GOOD_CONDITION:'GOOD_CONDITION',
    NEED_RENOVATION:'NEED_RENOVATION'
};


export const POSTS_SORT_TYPES ={
    UPDATE_DOWN: "-updatedAt",
    PRICE_UP: "price",
    PRICE_DOWN: "-price",
    AREA_UP: "area",
    AREA_DOWN: "-area",
    ROOM_COUNTS_UP: "roomsCount",
    ROOM_COUNTS_DOWN: "-roomsCount",
}

export const BATHROOMS_TYPES = {
    1: '1',
    2: '2',
    3: '3+',
}


export const CURRENCY_VALUE = ['USD_AMD', 'EUR_AMD', 'RUB_AMD']


export const PHONE_TYPES = {
    MOBILE: {
        key: 'MOBILE',
        name: 'Բջջային'
    },
    OFFICE: {
        key: 'OFFICE',
        name: 'Աշխատանքային'
    },
    HOME: {
        key: 'HOME',
        name: 'Քաղաքային'
    },
};


export enum CURRENCY_ENUM {
    AMD = 'AMD',
    USD = 'USD',
    RUB = 'RUB',
}

export const RESERVE_MODAL_TITLE_TYPES = {
    success: "success",
    error: "error",
    title: "title",
}

export const CURRENCIES: {
    [key: string]: CurrencyObjectType
} = {
    AMD: {
        key: CURRENCY_ENUM.AMD,
        suffix: '֏',
    },
    USD: {
        key: CURRENCY_ENUM.USD,
        suffix: '$',
    },
    RUB: {
        key: CURRENCY_ENUM.RUB,
        suffix: '₽',
    },
};

export const PRICE_TYPES = {
    MONTHLY: 'MONTHLY', //Ամսական
    DAILY: 'DAILY', //Օրական
};

export const NOTIFICATION_TYPES = {
    INFORMATION: "INFORMATION"
}
