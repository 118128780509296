// Import packages
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

// Import assets
import "./compare.scss"

// Import components
import PostSection from "../../components/post-section/PostSection";

// Import utils.
import { getPropsFromState } from "../../redux/mapStateToProps";
import CompareCard from "../../components/cards/compare-card/Compare-card";

function Compare (props) {
    const { isLoggedIn, comparedPosts, translation } = props
    const navigate = useNavigate();
    useEffect(() => {
        if (isLoggedIn) {
            navigate('/profile/compare')
        } else {
            window.scrollTo({ top: 0 });
        }
    }, [])
    return <div className={'compare_page_wrapper'}>
        <div className={'page-title'}>
            {translation?.profile_compare_title}
        </div>
        {
            comparedPosts?.length ?
                <div className={'compare-products-wrapper'}>
                    <div className={'compare-products-description'}>
                        <div className={'products-text'}>
                            <div className={'inform_block'}>{translation?.profile_compare_area}</div>
                            <div className={'inform_block'}>{translation?.profile_compare_price}</div>
                            <div className={'inform_block'}>{translation?.profile_compare_rent_price}</div>
                            <div className={'inform_block'}>{translation?.profile_compare_floors}</div>
                            <div className={'inform_block'}>{translation?.profile_compare_roomsCount}</div>
                        </div>
                    </div>
                    <div className={`compare-products ${comparedPosts?.length > 4 ? 'wrappers-width' : ''}`}>
                        {
                            comparedPosts?.map(item => {
                                return <CompareCard data={item} key={item.id}/>
                            })
                        }
                    </div>
                </div> :<div className="compare-empty-view">
                    <p className={'empty-compare'}>
                        {translation?.profile_compare_empty_text}
                    </p>
                </div>
        }
        {!!props?.recentlyViewed?.length && <PostSection showHeader={true}
                                                         title={translation?.homepage_post_section_title}
                                                         description={translation?.homepage_post_section_description}
                                                         posts={props.recentlyViewed}/>}
    </div>
}

const mapStateToProps = (state) => getPropsFromState(state, [
    'comparedPosts',
    'translation',
    'isLoggedIn',
]);

export default connect(mapStateToProps)(Compare);
