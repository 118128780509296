// Import packages
import React from "react";

// Import assets
import "./contact-info.scss"
import {
    ContactCallIcon,
    ContactPhoneIcon,
    PhoneDropDownIcon,
    TickCircleIcon, ViberIcon,
    WhatsUppIcon
} from "../../../assets/images";
import {MainButton} from "../../../components/ui-elements/button/Buttons";
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {connect} from "react-redux";
import {generateImageMediaUrl} from "../../../utils/generateMediaUrl";

// Import components
import {Collapse, Dropdown, Menu} from "antd";

// Import utils.

const ContactInfo: React.FC = (props: any) => {
    const {translation,information,contact} = props

    const callMenu = (
        <Menu>
            <Menu.Item className={'callDropDown'}>
                <a href={`tel:+${contact.phoneNumber}`}>
                    <PhoneDropDownIcon/>
                    <span>{`+${contact.phoneNumber}`}</span>
                </a>
                <a href={`viber://chat?number=%2B${contact.phoneNumber}`} target={'_blank'}>
                    <ViberIcon/>
                    <span>{`+${contact.phoneNumber}`}</span>
                </a>
                <a href={`https://wa.me/+${contact.phoneNumber}`} target={'_blank'}>
                    <WhatsUppIcon/>
                    <span>{`+${contact.phoneNumber}`}</span>
                </a>
            </Menu.Item>
        </Menu>
    );

    return <div className={`contact_info`}>
        <div className="contact_info__content">
            <div className="contact_info__content__title">{translation?.homepage_contact_info_title}</div>
            <div className="contact_info__content__description">
                {translation?.homepage_contact_info_description}
            </div>

            <MainButton bordered title={translation?.homepage_contact_info_btn_title}
                        link={'/contact'}
                        className={'home_contact_btn'}/>

            <div className="contact_info__content__subtitle">{translation?.homepage_contact_info_subtitle}</div>

            <div className="contact_info__content__tick_item">
                <div className="tick_item_text">
                    {translation?.homepage_contact_info_description_firth}
                </div>
            </div>

            <div className="contact_info__content__tick_item">
                <div className="tick_item_text">
                    {translation?.homepage_contact_info_description_second}
                </div>
            </div>

            <div className="contact_info__content__tick_item">
                <div className="tick_item_text">
                    {translation?.homepage_contact_info_description_third}
                </div>
            </div>

            <div className="question">{translation?.homepage_contact_info_questions_title}</div>

            {contact?.phoneNumber && <Dropdown overlay={callMenu}
                                               overlayClassName={''}
                                               trigger={['click']}
                                               className={'contact-dropDown'}
                                               placement="bottom">
                <div className="contact_item">
                    <ContactCallIcon/> {`+${contact.phoneNumber}`}</div>
            </Dropdown>}
            {contact?.phoneNumber1 && <a className="contact_item"
                                         href={`tel:${contact.phoneNumber1}`}>
                <ContactPhoneIcon/> {contact.phoneNumber1}</a>}
        </div>
        <div className="contact_info__gallery">
            <div className="contact_info__gallery__images">
                <div className={'first-img-wrapper'}>
                    <img src={generateImageMediaUrl(information?.mediaArray?.[0]?.path)} alt="" />
                </div>
                <div className={'bottom-block'}>
                    <div className={'second-img-wrapper'}>
                        <img src={generateImageMediaUrl(information?.mediaArray?.[1]?.path)} alt="" />
                    </div>
                    <div className={'second-img-wrapper'}>
                        <img src={generateImageMediaUrl(information?.mediaArray?.[2]?.path)} alt="" />
                    </div>
                </div>
            </div>
            <div className="contact_info__gallery__counter">
                <div className="counter_item">
                    <div className="counter_item__count">{information?.apartmentsCount}K</div>
                    <div className="counter_item__text">{translation?.homepage_contact_info_apartmentsCount_text}</div>
                </div>
                <div className="counter_item">
                    <div className="counter_item__count">{information?.commercialsCount}+</div>
                    <div className="counter_item__text">{translation?.homepage_contact_info_commercialsCount_text}</div>
                </div>
                <div className="counter_item">
                    <div className="counter_item__count">{information?.realEstateCount}+</div>
                    <div className="counter_item__text">{translation?.homepage_contact_info_realEstateCount_text}</div>
                </div>
                <div className="counter_item">
                    <div className="counter_item__count">{information?.workersCount}</div>
                    <div className="counter_item__text">{translation?.homepage_contact_info_workersCount_text}</div>
                </div>
            </div>
        </div>

        <div className="contact_info__content mobile">
            <div className="contact_info__content__title">{translation?.homepage_contact_info_title}</div>
            <div className="contact_info__content__description">
                {translation?.homepage_contact_info_description}
            </div>

            <MainButton bordered title={translation?.homepage_contact_info_btn_title} className={'home_contact_btn'}/>
        </div>
    </div>
}

const mapDispatchToProps = {}

const mapStateToProps = (state: any) => {
    return getPropsFromState(state, [
        'information',
        'translation',
        'contact',
    ])
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactInfo);
