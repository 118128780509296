// import packages
import React, {useState} from "react";
import {Link, useNavigate} from "react-router-dom";

// Import assets
import "./sign-in.scss"

//Import components
import AuthWrapper from "../../components/auth-wrapper/AuthWrapper";
import {InputGroup} from "../../components/ui-elements/input-group/inputGroup";
import {MainButton} from "../../components/ui-elements/button/Buttons";

// Import utils
import {LOGIN_ERROR_TYPES} from "../../constants/errorTypes";
import {validateAuth} from "../../utils/validateData";
import {getPropsFromState} from "../../redux/mapStateToProps";
import {connect} from "react-redux";
import {LoginUser} from "../../redux/actions";

type MapDispatchPropsType = {
    ClearUser: () => void
    LoginUser: (loginData: any) => Promise<any>
    GetCurrentUser: () => Promise<any>
    GetUserCompany: () => Promise<any>
}

type MapStatePropsType = {
    isLoggedIn: boolean
    staticTexts: any
    requestLoading: boolean
}

type OwnPropsType = {
    location: {
        state: {
            username: string
        }
    }
}

type PropsType = OwnPropsType & MapStatePropsType & MapDispatchPropsType

type LoginDataType = {
    username?: string,
    password?: string,
    grantType?: string
}

type InputValueType = {
    name: string,
    value: any
}

const SignIn: React.FC<PropsType> = (props:any) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [loginData, setLoginData] = useState<LoginDataType>({
        username: '',
        password: '',
        grantType: "password"
    })
    const [errors, setErrors] = useState({
        username: false,
        password: false,
        isBlocked: false,
        isVerified: false,
        default: false
    })
    const [errorMessages, setErrorMessages] = useState({
        username: '',
        password: '',
        default: '',
    })
    const navigate = useNavigate();
    const {translation} = props

    //functions
    function getInputValues({name, value}: InputValueType) {
        if (name==='username'){
            value= value.toLowerCase()
        }
        setLoginData({
            ...loginData,
            [name]: value
        })
        // @ts-ignore
        if (errorMessages?.[name]) {
            setErrorMessages({
                ...errorMessages,
                [name]: ''
            })
        }
        // @ts-ignore
        if (errors?.[name]) {
            setErrors({
                ...errors,
                [name]: false
            })
        }
    }

    function handleSignInErrors(slug: string) {
        // console.log('handleSignInErrors, slug: ', slug, errorMessages)
        switch (slug) {
            case LOGIN_ERROR_TYPES.INVALID_PASSWORD:
                setErrorMessages({
                    ...errorMessages,
                    password: `${translation?.ERROR_INVALID_PASSWORD}`
                })
                break;
            case LOGIN_ERROR_TYPES.INVALID_USERNAME:
            case LOGIN_ERROR_TYPES.UNVERIFIED_ACCOUNT:
                setErrorMessages({
                    ...errorMessages,
                    username: `${translation?.ERROR_UNVERIFIED_ACCOUNT}`
                })
                break;
            case LOGIN_ERROR_TYPES.BLOCKED_ACCOUNT:
                setErrorMessages({
                    ...errorMessages,
                    username: `${translation?.ERROR_ALREADY_EXISTS}`
                })
                break;
            case LOGIN_ERROR_TYPES.VALIDATION:
                setErrorMessages({
                    ...errorMessages,
                    default: `${translation?.ERROR_VALIDATION}`
                })
                break;
        }

        setErrors({
            username: slug === LOGIN_ERROR_TYPES.INVALID_USERNAME,
            password: slug === LOGIN_ERROR_TYPES.INVALID_PASSWORD,
            isBlocked: slug === LOGIN_ERROR_TYPES.BLOCKED_ACCOUNT,
            isVerified: slug === LOGIN_ERROR_TYPES.UNVERIFIED_ACCOUNT,
            default: slug === LOGIN_ERROR_TYPES.VALIDATION,
        });
    }

    async function loginUser() {
        setLoading(true)
        setErrors({
            username: false,
            password: false,
            isBlocked: false,
            isVerified: false,
            default: false
        })
        setErrorMessages({
            username: '',
            password: '',
            default: '',
        })
        const validation = validateAuth(loginData);
        if (validation.result) {
            await props.LoginUser(loginData).then(() => {
                navigate('/')
            }).catch((error: any) => {
                const slug = error && error.response && error.response.data && error.response.data.slug;
                // console.log("error.response", error.response);
                if (slug) {
                    handleSignInErrors(slug);
                } else {
                    setErrors(errors);
                }
            }).finally(()=>{
                setLoading(false)
            })
        } else {
            setLoading(false)
            setErrors({...errors, ...validation.errors});
        }
    }


    return <AuthWrapper className={'sign_in'}>
        <div className="title">{translation?.signIn_page_title}</div>

        <InputGroup
            inputType={'input'}
            type={"text"}
            name={'username'}
            disabled={loading}
            value={loginData.username}
            error={errors.username}
            placeholder={translation?.signIn_page_placeholder_user}
            label={translation?.signIn_page_label_user}
            autoComplete={'off'}
            regExp={/^[a-zA-Z0-9@.-]*$/}
            maxLength={50}
            onKeyDown={(e: any) => {
                e.key === 'Enter' && loginUser()
            }}
            onChange={getInputValues}
        >
            <div className={`error-message ${!!errorMessages?.username ? 'show' : 'hide'}`}>
                {errorMessages.username}
            </div>
        </InputGroup>

        <InputGroup
            inputType={'input'}
            type={`password`}
            name={'password'}
            disabled={loading}
            label={translation?.signIn_page_label_password}
            placeholder={translation?.signIn_page_placeholder_password}
            value={loginData.password}
            error={errors.password}
            maxLength={20}
            autoComplete={'off'}
            onKeyDown={(e: any) => {
                e.key === 'Enter' && loginUser()
            }}
            onChange={getInputValues}
        >
            <div
                className={`error-message ${!!(errorMessages?.password || errorMessages.default) ? 'show' : 'hide'}`}>
                {(errorMessages.password || errorMessages.default)}
            </div>
        </InputGroup>

        <Link to={'/auth/forgot-password'} className="forgot_pass">
            {translation?.signIn_page_forgot_password}
        </Link>

        <MainButton title={translation?.signIn_page_btn_title} className="button"
                    loading={loading}
                    click={loginUser}/>
        <MainButton title={translation?.signIn_page_signUp_btn_title}
                    lightBordered
                    link={'/auth/sign-up'}
                    className="button"/>
    </AuthWrapper>
}

const mapDispatchToProps = {
    LoginUser
}
const mapStateToProps = (state: any) => {
    return getPropsFromState(state, [
        'translation'
    ])
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
