// Import packages
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";


// Import assets
import "./contact.scss"
import {
    ContactCallIcon,
    ContactEmailIcon,
    ContactMapIcon,
    ContactPhoneIcon,
    PhoneDropDownIcon,
    TickIcon, ViberIcon, WhatsUppIcon
} from "../../assets/images";

// Import components
import {getPropsFromState} from "../../redux/mapStateToProps";
import PageHeader from "../../components/header/page-header/page-header";
import {InputGroup} from "../../components/ui-elements/input-group/inputGroup";
import {MainButton} from "../../components/ui-elements/button/Buttons";
import {isEmail} from "validator";
import {GetContact, SendMessage} from "../../redux/actions";
import {LoadingOutlined} from "@ant-design/icons";
import Subscribe from "../homepage/subscribe/Subscribe";
import {Dropdown, Menu} from "antd";

// Import utils.


function Contact(props){
    const [fieldsData, setFieldsData] = useState({
        name: "",
        email: "",
        message: "",
    })

    const [errors, setErrors] = useState({
        name: "",
        email: "",
        message: "",
    })
    const [loading, setLoading] = useState(false)
    const [tick, setTick] = useState(false)
    const {contact,translation} = props

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        props.GetContact()
    },[])

    const showTick = () => {
        setTimeout(
            () => {
                setTick(false)
                setFieldsData({
                    name: "",
                    email: "",
                    message: "",
                })
                setErrors({
                    name: false,
                    email: false,
                    message: false,
                })
            },
            2000,
        )

    }

    function getInputValues({name, value}) {
        setFieldsData({
            ...fieldsData,
            [name]: value
        })
        setErrors({
            ...errors,
            [name]: false
        })
    }
    function validate() {
        const errors = {};
        let result = true;
        Object.keys(fieldsData).forEach(key => {
            if (!fieldsData[key] || (key === 'email' && !isEmail(fieldsData?.email))
            ) {
                errors[key] = true;
                result = false;
            }

        })
        setErrors(errors);
        return result;
    }

    function Send() {
        if(validate()){
            setLoading(true)
            props.SendMessage(fieldsData).then(() => {
                setTick(true)
                setLoading(false)
                showTick()
                setFieldsData({
                    name: "",
                    email: "",
                    message: "",
                })
            })
        }
    }
    const callMenu = (
        <Menu>
            <Menu.Item className={'callDropDown'}>
                <a href={`tel:+${contact.phoneNumber}`}>
                    <PhoneDropDownIcon/>
                    <span>{`+${contact.phoneNumber}`}</span>
                </a>
                <a href={`viber://chat?number=%2B${contact.phoneNumber}`} target={'_blank'}>
                    <ViberIcon/>
                    <span>{`+${contact.phoneNumber}`}</span>
                </a>
                <a href={`https://wa.me/+${contact.phoneNumber}`} target={'_blank'}>
                    <WhatsUppIcon/>
                    <span>{`+${contact.phoneNumber}`}</span>
                </a>
            </Menu.Item>
        </Menu>
    );
    return <div className={'contact_wrapper'}>
        <div className={'contact_wrapper__header'}>
           <PageHeader name={translation?.footer_contact}/>
        </div>
        <div className={'contact_wrapper__components'}>
            <div className={'components__leftBlock'}>
                <div className={'components__leftBlock__title'}>{translation?.contacts_page_title}</div>
                <div className={'components__leftBlock__description'}>
                    {translation?.contacts_page_description}
                </div>
                    <div className={'inputs_wrapper__top'}>
                        <InputGroup inputType={'input'}
                                    type={"text"}
                                    value={fieldsData?.name}
                                    error={errors?.name}
                                    name={'name'}
                                    maxLength={100}
                                    label={translation?.contacts_page_fullName}
                                    placeholder={translation?.contacts_page_placeholder_fullName}
                                    onChange={getInputValues}/>
                        <InputGroup inputType={'input'}
                                    type={"text"}
                                    value={fieldsData?.email}
                                    error={errors?.email}
                                    name={'email'}
                                    label={translation?.contacts_page_email}
                                    maxLength={100}
                                    placeholder={translation?.contacts_page_placeholder_email}
                                    onChange={getInputValues}/>
                    </div>
                    <div className={'inputs_wrapper__bottom'}>
                        <InputGroup inputType={'textarea'}
                                    type={"text"}
                                    value={fieldsData?.message}
                                    error={errors?.message}
                                    name={'message'}
                                    maxLength={100}
                                    label={translation?.contacts_page_message}
                                    placeholder={translation?.contacts_page_placeholder_message}
                                    onChange={getInputValues}/>
                        <MainButton  title={<>
                            {translation?.homepage_subscribe_btn_title}
                            {
                                loading ? <LoadingOutlined style={{fontSize: 12, color: "#BCA477"}}/>
                                    : <TickIcon title={''} className={`tick-icon ${tick ? 'show-tick' : 'hide-tick'}`}/>
                            }
                            </>
                        }
                                     className={'inputs_wrapper__bottom__send_btn'}
                                     click={!loading && Send}/>
                    </div>
            </div>
            <span className={'line'}/>
            <div className={'components__rightBlock'}>
                <div className={'info_blocks'}>
                    <div className={'info_blocks__icon'}><ContactCallIcon/></div>
                    <div className={'info_blocks__items'}>
                        <div className={'info_blocks__items__title'}>{translation?.contacts_page_phone_title}</div>
                        {/*<a className={'info_blocks__items__descriptions'} href={`tel:${contact?.phoneNumber}`}>{contact?.phoneNumber}</a>*/}
                        {contact?.phoneNumber && <Dropdown overlay={callMenu}
                                                           overlayClassName={''}
                                                           trigger={['click']}
                                                           className={'contact-dropDown'}
                                                           placement="bottom">
                            <div className="info_blocks__items__descriptions">
                            {`+${contact.phoneNumber}`}</div>
                        </Dropdown>}
                    </div>
                </div>
                <div className={'info_blocks'}>
                    <div className={'info_blocks__icon'}><ContactPhoneIcon/></div>
                    <div className={'info_blocks__items'}>
                        <div className={'info_blocks__items__title'}>{translation?.contacts_page_telephone_title}</div>
                        <div className={'info_blocks__items__descriptions'} href={`tel:${contact?.phoneNumber1}`}>{contact?.phoneNumber1}</div>
                    </div>
                </div>
                <div className={'info_blocks'}>
                    <div className={'info_blocks__icon'}><ContactMapIcon/></div>
                    <div className={'info_blocks__items'}>
                        <div className={'info_blocks__items__title'}>{translation?.contacts_page_address_title}</div>
                        <div className={'info_blocks__items__descriptions'}>{contact?.address}</div>
                    </div>
                </div>
                <div className={'info_blocks'}>
                    <div className={'info_blocks__icon'}><ContactEmailIcon/></div>
                    <div className={'info_blocks__items'}>
                        <div className={'info_blocks__items__title'}>{translation?.contacts_page_email_title}</div>
                        <a className={'info_blocks__items__descriptions'} href={`mailto:${contact?.email}`}>{contact?.email}</a>
                    </div>
                </div>
            </div>
        </div>
        <div className={'contact_wrapper__subscribe'}>
            <Subscribe/>
        </div>
    </div>

}
const mapStateToProps = (state) => getPropsFromState(state, [
    'contact',
    'translation',
]);

const mapDispatchToProps = {
    SendMessage,
    GetContact,
};

export default connect(mapStateToProps, mapDispatchToProps) (Contact);