import {
    _urlBanners,
    _urlCategories, _urlExchange, _urlFaq,
    _urlFilters,
    _urlInformation,
    _urlLanguage, _urlPostRegions,
    _urlSlider,
    _urlStaticTexts, _urlStatistics, _urlVisit,
    request
} from "../api";
import {GENERAL_CONSTS} from "../constants";
import {RequestDataType} from "./actionTypes";

export const GetLanguages = () => {
    const requestData = {
        url: `${_urlLanguage}`,
        method: "GET",
    };

    return (dispatch: any) => {
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;
                await dispatch({
                    type: GENERAL_CONSTS.GET_LANGUAGES,
                    payload: data
                })
            })
    };
};

export const ChangeLanguage = (data: any) => {
    return (dispatch: any) => {
        dispatch({
            type: GENERAL_CONSTS.CHANGE_LANGUAGE,
            payload: data
        })
    }
};

export const GetStaticTexts = () => {
    const requestData: RequestDataType = {
        url: `${_urlStaticTexts}`,
        method: "GET",
        scope: false
    };

    return (dispatch: any) => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_STATIC_TEXTS,
                    payload: data
                })
            })
    };
};

export const GetCategories = () => {
    const requestData: RequestDataType = {
        url: `${_urlCategories}`,
        method: "GET",
        languageFlag: true,
        scope: false
    };

    return (dispatch: any) => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_CATEGORIES,
                    payload: data
                })
            })
    };
};

export const GetSlider = () => {
    const requestData: RequestDataType = {
        url: `${_urlSlider}`,
        method: "GET",
        languageFlag: true,
        scope: false
    };

    return (dispatch: any) => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_SLIDER,
                    payload: data
                })
            })
    };
};

export const GetBanners = () => {
    const requestData: RequestDataType = {
        url: `${_urlBanners}`,
        method: "GET",
        languageFlag: true,
        scope: false
    };

    return (dispatch: any) => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_BANNERS,
                    payload: data
                })
            })
    };
};

export const GetFilters = () => {
    const requestData = {
        url: `${_urlFilters}`,
        method: "GET",
        languageFlag: true
    };

    return (dispatch: any) => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_FILTERS,
                    payload: data
                })
            })
    };
};

export const GetInformation = () => {
    const requestData = {
        url: `${_urlInformation}`,
        method: "GET",
        languageFlag: true
    };

    return (dispatch: any) => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_INFORMATION,
                    payload: data
                })
            })
    };
};

export const GetRates = () => {
    const requestData = {
        url: `${_urlExchange}/rates-list`,
        method: "GET",
        scope: false
    };

    return (dispatch: any) => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_RATES,
                    payload: data
                })
            })
    };
};

export const ChangeCurrency = (currency: string) => {
    return (dispatch: any) => {
        dispatch({
            type: GENERAL_CONSTS.CHANGE_CURRENCY,
            payload: currency
        })
    }
};

export const GetFaqList = () => {
    const requestData: RequestDataType = {
        url: `${_urlFaq}`,
        method: "GET",
        languageFlag: true,
    };

    return (dispatch: any) => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_FAQ,
                    payload: data
                })
            })
    };
};
export const GetRegionList = () => {
    const requestData: RequestDataType = {
        url: `${_urlPostRegions}`,
        method: "GET",
        languageFlag: true,
    };

    return (dispatch: any) => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_REGIONS,
                    payload: data
                })
            })
    };
};
export const GetResidencesList = (id:any) => {
    const requestData: RequestDataType = {
        url: `${_urlPostRegions}/${id}/residences`,
        method: "GET",
        languageFlag: true,
    };

    return (dispatch: any) => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: GENERAL_CONSTS.GET_RESIDENCES,
                    payload: data
                })
            })
    };
};

export const AddPhoneStatistics = (postId:string) => {
    const requestData: RequestDataType = {
        url: `${_urlStatistics}/phone/${postId}`,
        method: "POST",
        token: true
    };

    return (dispatch: any) => {
        return request(requestData)
    };
};

export const SendVisitRequest = (data:any) => {
    const requestData = {
        url: `${_urlVisit}`,
        token: true,
        method: "POST",
        data
    };
    return (dispatch:any) => {
        return request(requestData)
    }
};

export const PriceType = (data:any) => {
    return {
        type:GENERAL_CONSTS.PRICE_TYPE,
        payload:data,
    }
}


