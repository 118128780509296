// Import packages
import React, {useState} from "react";
import isEmail from 'validator/lib/isEmail';
import {connect} from "react-redux";

// Import assets
import "./subscribe.scss"
import {LoadingOutlined} from "@ant-design/icons";

// Import components
import {InputGroup} from "../../../components/ui-elements/input-group/inputGroup";
import {MainButton} from "../../../components/ui-elements/button/Buttons";

// Import utils.
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {SendSubscribe} from "../../../redux/actions";
import {TickIcon} from "../../../assets/images";

type InputValueType = {
    name: string,
    value: any
}
const Subscribe: React.FC = (props: any) => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState(false);
    const [tick, setTick] = useState(false)
    const [loading, setLoading] = useState(false)
    const {translation} = props

    function getInputValues({value}: InputValueType) {
        setEmail(value)
        setError(false)
    }

    const showTick = () => {
        setTimeout(
            () => {
                setTick(false)
                setEmail("")
                setError(false)
                setError(false)
            },
            2000,
        )

    }

    function addSubscribers() {
        if (email && isEmail(email)) {
            setLoading(true)
            props.SendSubscribe({email: email})
                .then(() => {
                    setEmail(email)
                    setTick(true)
                    setLoading(false)
                    showTick()
                }, (error:any) => setError(!error))
        } else {
            setError(true)
        }
    }

    return <div className={`subscribe_info`}>
        <div className="subscribe_info__title">
            {translation?.homepage_subscribe_title}
        </div>
        <div className="subscribe_info__form">
            <InputGroup inputType={'input'}
                        type={"text"}
                        value={email}
                        error={error}
                        name={'name'}
                        maxLength={100}
                        placeholder={translation?.homepage_subscribe_input_placeholder}
                        onChange={getInputValues}/>

            <MainButton title={
                <>
                {translation?.homepage_subscribe_btn_title}
                    {
                        loading ? <LoadingOutlined style={{fontSize: 12, color: "#fff"}}/>
                            : <TickIcon title={''} className={`tick-icon ${tick ? 'show-tick' : 'hide-tick'}`}/>
                    }
                </>}
                        click={!loading && addSubscribers} className={'subscribe_btn'}/>
        </div>
    </div>
}
const mapDispatchToProps = {
    SendSubscribe
}

const mapStateToProps = (state: any) => {
    return getPropsFromState(state, [
        'translation'
    ])
};

export default connect(mapStateToProps, mapDispatchToProps)(Subscribe);
