// Import packages
import React, { useState } from "react"

// Import components
import { InputGroup } from "../../../components/ui-elements/input-group/inputGroup";
import { MainButton } from "../../../components/ui-elements/button/Buttons";

//import utils
import { LOGIN_ERROR_TYPES, USER_ERROR_TYPES } from "../../../constants/errorTypes";


export function PasswordChangeBox (props) {
    const { translation} = props;

    const [password, setPassword] = useState({
        oldPassword: '',
        newPassword: '',
        repeatPassword: '',
    });
    const [passwordVisible, _setPasswordVisible] = useState({
        oldPassword: false,
        newPassword: false,
        repeatPassword: false,
    });
    const [errors, setErrors] = useState({});
    const [oldPasswordErrorMessage, setOldPasswordErrorMessage] = useState('')
    const [newPasswordErrorMessage, setNewPasswordErrorMessage] = useState('')
    const [loading, setLoading] = useState(false);

    const { oldPassword, newPassword, repeatPassword } = password;

    function setPasswordVisible (type) {
        _setPasswordVisible({
            ...passwordVisible,
            [type]: !passwordVisible[type]
        })
    }

    function getPasswordInputValues ({ name, value }) {
        setPassword({
            ...password,
            [name]: value.trim(),
        })
    }

    function validateData () {
        const requiredFields = ['oldPassword', 'newPassword', 'repeatPassword'];
        let validationResult = true;
        const errorState = {};
        requiredFields.forEach(key => {
            if (!password[key]) {
                validationResult = false;
                errorState[key] = true;
            }
        });
        if (newPassword !== repeatPassword) {
            errorState.repeatPassword = true;
            validationResult = false;
        }
        if (newPassword?.length < 8) {
            errorState.newPassword = true;
            validationResult = false;
        }
        setErrors(errorState);
        return validationResult;
    }

    function updatePassword () {
        setOldPasswordErrorMessage('')
        setNewPasswordErrorMessage('')
        if (validateData()) {
            setLoading(true);
            const reqData = {
                oldPassword,
                newPassword,
            };
            props.UpdateCurrentUserPassword(reqData).then(() => {
                setPassword({
                    oldPassword: '',
                    newPassword: '',
                    repeatPassword: '',
                });
            }).catch((error) => {
                const slug = error && error.response && error.response.data && error.response.data.slug;
                // console.log("error.slug", slug);
                handleChangePasswordErrors(slug);
            }).finally(() => {
                setLoading(false);
            });
        }
    }

    function handleChangePasswordErrors (slug) {
        const updatedErrors = {
            oldPassword: false,
            newPassword: false,
            repeatPassword: false,
        }
        switch ( slug ) {
            case LOGIN_ERROR_TYPES.INVALID_PASSWORD:
                setOldPasswordErrorMessage(`${translation?.INVALID_PASSWORD}`);
                updatedErrors.oldPassword = true
                break;
            case USER_ERROR_TYPES.PASSWORD_REPEATED:
                setNewPasswordErrorMessage(`${translation?.PASSWORD_REPEATED}`);
                updatedErrors.newPassword = true
                break;
        }
        setErrors(updatedErrors)
    }

    return <div className="password-wrapper">
            <div className={'left-wrapper'}>
                <InputGroup
                    inputType={'input'}
                    type={`${passwordVisible.oldPassword ? "text" : "password"}`}
                    value={password.oldPassword}
                    className={'input-group'}
                    autocomplete={"new-password"}
                    placeholder={translation?.profile_settings_oldPassword_placeholder}
                    label={translation?.profile_settings_oldPassword_label}
                    name={'oldPassword'}
                    error={errors.oldPassword}
                    onChange={getPasswordInputValues}>
                                <span className={'visibility-icon'}
                                      onClick={setPasswordVisible.bind(this, 'oldPassword')}>
                              {/*{passwordVisible.oldPassword ? <EyeInvisibleOutlined/> : <EyeOutlined/>}*/}
                       </span>
                    <div className={`error-message ${!!oldPasswordErrorMessage ? 'show' : 'hide'}`}>
                        {oldPasswordErrorMessage}
                    </div>
                </InputGroup>
                <InputGroup
                    inputType={'input'}
                    type={`${passwordVisible.newPassword ? "text" : "password"}`}
                    value={password.newPassword}
                    className={'input-group'}
                    placeholder={translation?.profile_settings_newPassword_placeholder}
                    label={translation?.profile_settings_newPassword_label}
                    name={'newPassword'}
                    error={errors.newPassword}
                    onChange={getPasswordInputValues}>
                                  <span className={'visibility-icon'}
                                        onClick={setPasswordVisible.bind(this, 'newPassword')}>
                               {/*{passwordVisible.newPassword ? <EyeInvisibleOutlined/> : <EyeOutlined/>}*/}
                       </span>
                    <div className={`error-message ${!!newPasswordErrorMessage ? 'show' : 'hide'}`}>
                        {newPasswordErrorMessage}
                    </div>
                </InputGroup>
            </div>
            <div className={'right-wrapper'}>
                <InputGroup
                    inputType={'input'}
                    type={`${passwordVisible.repeatPassword ? "text" : "password"}`}
                    value={password.repeatPassword}
                    className={'input-group'}
                    placeholder={translation?.profile_settings_repeatPassword_placeholder}
                    label={translation?.profile_settings_repeatPassword_label}
                    name={'repeatPassword'}
                    error={errors.repeatPassword}
                    onChange={getPasswordInputValues}>
                                    <span className={'visibility-icon'}
                                          onClick={setPasswordVisible.bind(this, 'repeatPassword')}>
                               {/*{passwordVisible.repeatPassword ? <EyeInvisibleOutlined/> : <EyeOutlined/>}*/}
                       </span>
                </InputGroup>
                <MainButton
                    loading={loading}
                    title={translation?.profile_settings_btnSave}
                    disabled={!(password.oldPassword && password.repeatPassword && password.newPassword)}
                    className={'changePassword'}
                    click={updatePassword}
                />
            </div>
    </div>

}

export default PasswordChangeBox;
