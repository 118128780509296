import {UTIL_CONSTS} from "../constants";

interface MetaTagsType {
    title: string,
    description: string,
    image: string
}

export interface PageOffsetType {
    pageName: string,
    offset: number
}

export interface WindowSizesType {
    width: number,
    height: number
}

export type UtilsStateType = {
    requestLoading: boolean,
    leftSideMenuOpen: boolean,
    metaTags: MetaTagsType,
    pageOffset: PageOffsetType
    windowSizes: WindowSizesType
};

export const initialState: UtilsStateType = {
    requestLoading: false,
    leftSideMenuOpen: window.innerWidth > 800,
    metaTags: {
        title: '',
        description: '',
        image: ''
    },
    pageOffset: {
        pageName: '',
        offset: 0
    },
    windowSizes: {
        width: 0,
        height: 0
    }
};

export default (state: UtilsStateType = initialState, action: any) => {
    switch (action.type) {
        case UTIL_CONSTS.START_LOADING:
            return {
                ...state,
                requestLoading: true
            };
        case UTIL_CONSTS.END_LOADING:
            return {
                ...state,
                requestLoading: false
            };
        case UTIL_CONSTS.TOGGLE_LEFT_SIDE_MENU:
            return {
                ...state,
                leftSideMenuOpen: !state.leftSideMenuOpen
            };
        case UTIL_CONSTS.SET_PAGE_OFFSET:
            return {
                ...state,
                pageOffset: action.payload,
            };
        case UTIL_CONSTS.SET_WINDOW_SIZES:
            return {
                ...state,
                windowSizes: action.payload,
            };
        default:
            return state;
    }
}
