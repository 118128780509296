import {AboutLogoIcon} from "../../../assets/images";
import React from "react";
import "./page-header.scss"


function PageHeader({name}){
    return <div className={'header'}>
        <div className={'header__block'}>
            <AboutLogoIcon/>
            <div className={'header__block__title'}>
                {name}
            </div>
        </div>
    </div>
}export default PageHeader