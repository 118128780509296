// import packages
import React from "react";
import Slider, {Settings} from "react-slick";

//Import styles
import "./section.scss";
import {ArrowRightIcon} from "../../../assets/images";

//Import Components
import ProductCardGrid from "../../../components/cards/product-card-grid/ProductCardGrid";
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {connect} from "react-redux";

// Import utils

type PropsTypes = {
    //todo change any type
    translation?: any;
    withTabs?: boolean;
    showHeader?: boolean;
    inSearch?: boolean;
    posts?: any;
    windowSizes?: any;
};

type StateTypes = {
    disabledPrev: boolean,
    disabledNext: boolean,
};

class Section extends React.Component<PropsTypes, StateTypes> {
    private section: Slider | any = null; //todo change any to null
    constructor(props: PropsTypes) {
        super(props);
        this.state = {
            disabledPrev: true,
            disabledNext: false,
        };
    }

    componentDidMount() {
        let itemCount = this.getShowSlideCount();
        this.setState({
            ...this.state,
            disabledNext: this.props.posts?.length - 1 < itemCount,
        });
    }

    getShowSlideCount = () => {
        let width = this.props.windowSizes?.width || window.innerWidth;
        if (width > 1680) return 4;
        else if (width > 900) return 3;
        else if (width > 600) return 2;
        else return 1;
    };

    afterSlideChange = (next: any) => {
        let itemCount = this.getShowSlideCount();
        this.setState({
            disabledPrev: next === 0,
            disabledNext: next + itemCount === this.props.posts?.length,
        });
    };

    render() {
        const {posts,translation} = this.props;

        const settings: Settings = {
            dots: false,
            arrows: false,
            infinite: false,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            swipe: true,
            beforeChange: (current, next) => this.afterSlideChange(next),
            responsive: [
                {
                    breakpoint: 1680,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 900,
                    settings: {
                        slidesToShow: 3,
                        centerPadding: "40px 0 0",
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        centerPadding: "40px 0 0",
                    }
                },
                {
                    breakpoint: 400,
                    settings: {
                        slidesToShow: 1,
                        centerMode: true,
                        centerPadding: "40px 0 0",
                    }
                },
            ]
        };

        return !!posts?.length && <div className={`search_section`}>
            <div className="section_title">{translation?.section_title}</div>

            <div className="search_section__slider">
                {this.getShowSlideCount() < posts.length && <button className="search_section__slider__arrow prev"
                         onClick={() => this.section.slickPrev()}>
                    <ArrowRightIcon/>
                </button>}
                <div className="search_section__list slider_list">
                    {
                        <Slider {...settings}
                                ref={(component: Slider | null) => {
                                    this.section = component;
                                }}>
                            {posts?.map((item: any) => {
                                return <ProductCardGrid key={item.id} data={item}/>
                            })}
                        </Slider>
                    }
                </div>
                {this.getShowSlideCount() < posts.length && <button className="search_section__slider__arrow next"
                         onClick={() => this.section.slickNext()}>
                    <ArrowRightIcon/>
                </button>}
            </div>
        </div>
    }
}

const mapDispatchToProps = {
}
const mapStateToProps = (state: any) => {
    return getPropsFromState(state, [
        'translation',
        'windowSizes',
    ])
};

export default connect(mapStateToProps, mapDispatchToProps)(Section);
