import React from 'react';

//assets
import './info-header.scss'
import {BlackArrowRightIcon} from "../../../assets/images";
import {Dropdown, Menu} from "antd";
import {getPropsFromState} from "../../../redux/mapStateToProps";
import {connect} from "react-redux";
import {
    ChangeCurrency,
    ChangeLanguage, UpdateUserLanguage,
} from "../../../redux/actions";
import {generateFileMediaUrl} from "../../../utils/generateMediaUrl";
import {LanguageType} from "../../../types/types";
import {CURRENCIES} from "../../../constants/constants";
import {Link} from 'react-router-dom'

function InfoHeader(props: any) {
    const {languages, selectedLanguage, selectedCurrency,translation,isLoggedIn } = props

    function changeLanguage(item: LanguageType) {
        props.ChangeLanguage(item)
        isLoggedIn &&  props.UpdateUserLanguage(item.id)
    }

    const menuLanguage = <Menu items={
        !!languages?.length && languages.map((item: LanguageType) => {
                return {
                    key: item.id,
                    label: <div className={"lang_menu_item"} onClick={changeLanguage.bind(null, item)}>
                        {item?.icon && <img src={generateFileMediaUrl(item?.icon?.path)} alt=""/>}
                        {item.name}
                    </div>,
                }
            }
        )}
    />;

    const menuCurrency = (
        <Menu items={Object.keys(CURRENCIES).map((key) => {
            let currency = CURRENCIES[key];
            return {
                key: key,
                label: <div className={"currency_menu_item"}
                            onClick={() => {
                                props.ChangeCurrency(key)
                            }}
                >
                    <span>{currency.suffix}&nbsp;</span>
                    {key}
                </div>,
            }
        })}
        />
    );
    return (
        <div className="info_header">
            <div className="info_header__privacy">
                <span>{translation?.info_header_privacy}</span>
                <Link to="/contact" className="info_header__privacy__link">{translation?.info_header_learnMore}</Link>
            </div>
            <div className="info_header__actions">
                <Link to={'/contact'} className={'info_header__actions__text'}>{translation?.info_header_actions_title}</Link>
                <div className={'info_header__actions__dropdowns'}>
                    <Dropdown overlay={menuCurrency}
                              placement="bottomRight">
                        <div className="language_dropdown_item">
                            <span>{CURRENCIES[selectedCurrency]?.suffix}</span>&nbsp;
                            <span>{selectedCurrency}</span>
                            <BlackArrowRightIcon className={'arrow_icon'}/>
                        </div>
                    </Dropdown>
                    <Dropdown overlay={menuLanguage}
                              placement="bottomRight">
                        <div className="language_dropdown_item">
                            {selectedLanguage && <>
                                <img src={generateFileMediaUrl(selectedLanguage?.icon?.path)} alt=""/>
                                <span>{selectedLanguage?.name}</span>
                            </>}
                        </div>
                    </Dropdown>
                </div>
            </div>
        </div>
    );
}


const mapDispatchToProps = {
    ChangeLanguage,
    ChangeCurrency,
    UpdateUserLanguage
};
const mapStateToProps = (state: any) => {
    return getPropsFromState(state, [
        'selectedLanguage',
        'selectedCurrency',
        'languages',
        'translation',
        'isLoggedIn',
    ])
};

export default connect(mapStateToProps, mapDispatchToProps)(InfoHeader);
