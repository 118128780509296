// Import packages
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import Slider from "react-slick";

// Import styles
import "./image-slider.scss";

//Import Images
import {
    ArrowLeftIcon,
    ArrowRightIcon, Border1Icon, Border2Icon, Border3Icon,
    ClearIcon,
    ContactCallIcon,
    ContactPhoneIcon, MinusIcon, PhoneDropDownIcon, PlusIcon,
    ProjectLogo, ViberIcon, WhatsUppIcon
} from "../../../assets/images";
// Import components
import {Dropdown, Menu, Modal} from "antd";

//import utils
import { generateFileMediaUrl } from "../../../utils/generateMediaUrl";
import {TransformComponent,TransformWrapper} from "react-zoom-pan-pinch";

export function ImagesSlider (props) {
    const { closeModal, visible, modalImagePathList, modalCurrentImageIndex, title,translation,contact} = props;
    const [showCloseIcon, setShowCloseIcon] = useState(false);
    const [slideIndex, setSlideIndex] = useState("");
    let sliderRef = useRef(null);


    useEffect(() => {
        sliderRef.slickGoTo?.(modalCurrentImageIndex)
        setSlideIndex(modalCurrentImageIndex)
    }, [modalCurrentImageIndex])

    const nextSlide = (index) => {
        setSlideIndex(slideIndex - 1 === index ? 0 : index + 1)
        if (modalImagePathList?.length - 1 === slideIndex) {
            setSlideIndex(slideIndex)
        }
        sliderRef.slickNext();
    };

    const prevSlide = (index) => {
        setSlideIndex(index === 0 ? 0 : index - 1)
        sliderRef.slickPrev();
    };
    const changeSlide = (index) => {
        setSlideIndex(index)
        sliderRef.slickGoTo?.(index)
    };


    useLayoutEffect(() => {
        if (visible) {
            sliderRef && sliderRef.current && sliderRef.current.slickGoTo(modalCurrentImageIndex);
            setTimeout(() => {
                setShowCloseIcon(true)
            }, 250)
        } else {
            setShowCloseIcon(false);

        }
    }, [visible]);

    const callMenu = (
        <Menu>
            <Menu.Item className={'callDropDown-slider'}>
                <a href={`tel:+${contact.phoneNumber}`}>
                    <PhoneDropDownIcon/>
                    <span>{`+${contact.phoneNumber}`}</span>
                </a>
                <a href={`viber://chat?number=%2B${contact.phoneNumber}`} target={'_blank'}>
                    <ViberIcon/>
                    <span>{`+${contact.phoneNumber}`}</span>
                </a>
                <a href={`https://wa.me/+${contact.phoneNumber}`} target={'_blank'}>
                    <WhatsUppIcon/>
                    <span>{`+${contact.phoneNumber}`}</span>
                </a>
            </Menu.Item>
        </Menu>
    );
    return <Modal
        open={visible}
        wrapClassName={'custom-modal'}
        onCancel={props.closeModal}
        >
        <div className={'slider-modal-wrapper'}>
            <div className="modal-header">
                <div className="modal-title"><ProjectLogo/></div>
                <button className={`dialog-closing-btn ${showCloseIcon ? 'show' : ''}`}
                        onClick={closeModal}>
                    <ClearIcon title={''}/>
                </button>
            </div>
            {visible && !!modalImagePathList?.length &&
            <div className="slider-wrapper">
                <div className="images-list">
                    <div className={'top-border'}>
                        <Border3Icon/>
                    </div>
                    <div className={'right-border'}>
                       <Border2Icon/>
                    </div>
                    <div className={'respons-top-border'}>
                       <Border1Icon/>
                    </div>
                    <div className="list-title">{translation?.post_slider_title}</div>

                    <div className="list-wrapper">
                        {modalImagePathList?.map((item, index) => {
                            return <img key={item.id}
                                        src={generateFileMediaUrl(item?.path)}
                                        className={`list-img ${(slideIndex === index) ? 'active' : ''}`}
                                        onClick={() => changeSlide(index)} alt={"about"}/>
                        })}
                    </div>
                    <div className={'contact-block'}>
                        {contact?.phoneNumber && <Dropdown overlay={callMenu}
                                                           trigger={['click']}
                                                           className={'contact-dropDown'}
                                                           placement="topRight">
                            <div className="contact_item">
                                <ContactCallIcon/> {`+${contact.phoneNumber}`}</div>
                        </Dropdown>}
                        {contact?.phoneNumber1 && <a className="contact_item"
                                                     href={`tel:${contact.phoneNumber1}`}>
                            <ContactPhoneIcon/> {contact.phoneNumber1}</a>}
                    </div>
                </div>
                <div className="main-img-wrapper">
                    <div className={'img-top-border'}>
                        <Border1Icon/>
                    </div>
                    <button className={`slider-btn  prev-btn`}
                            onClick={() => prevSlide(slideIndex)}
                            disabled={slideIndex === 0}
                    >
                        <ArrowRightIcon/>
                    </button>
                    <div className={'zoom-wrapper'}>
                        <TransformWrapper>
                            {({ zoomIn, zoomOut}) => (
                                <React.Fragment>
                                    <div className="tools">
                                        <button onClick={() => zoomIn()} className={'zoom-in'}><PlusIcon/></button>
                                        <button onClick={() => zoomOut()} className={'zoom-out'}><MinusIcon/></button>

                                    </div>
                                    <TransformComponent>
                                        <div className="main-img-content">
                                            {modalImagePathList?.[slideIndex]?.path &&
                                                <img src={generateFileMediaUrl(modalImagePathList[slideIndex]?.path)}
                                                     className={'main-img'} alt={"about"}/>}
                                        </div>
                                    </TransformComponent>
                                </React.Fragment>
                            )}
                        </TransformWrapper>
                    </div>
                    <button
                        className={`slider-btn next-btn`}
                        disabled={slideIndex === modalImagePathList?.length - 1}
                        onClick={() => nextSlide(slideIndex)}>
                        <ArrowRightIcon/>
                    </button>
                </div>
            </div>
            }
        </div>
    </Modal>
}
