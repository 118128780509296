export enum USER_ERROR_TYPES {
    USER_ALREADY_EXISTS = 'USER_ALREADY_EXISTS',
    USER_NOT_FOUND = 'USER_NOT_FOUND',
    USER_ALREADY_ACTIVATED = 'USER_ALREADY_ACTIVATED',
    USER_BLOCKED = 'USER_BLOCKED',
    USER_NOT_ACTIVATED = 'USER_NOT_ACTIVATED',
    CODE_NOT_FOUND = 'CODE_NOT_FOUND',
    CODE_INVALID = 'CODE_INVALID',
    PASSWORD_REPEATED = 'PASSWORD_REPEATED'
}

export enum LOGIN_ERROR_TYPES {
    INVALID_USERNAME = 'INVALID_USERNAME',
    INVALID_PASSWORD = 'INVALID_PASSWORD',
    USER_NOT_FOUND = 'USER_NOT_FOUND',
    UNVERIFIED_ACCOUNT = 'UNVERIFIED_ACCOUNT',
    BLOCKED_ACCOUNT = 'BLOCKED_ACCOUNT',
    UNKNOWN_ERROR = 'UNKNOWN_ERROR',
    VALIDATION = 'validation-error'
}
