import React, {useState} from "react";
import {Select} from "antd";

//Import Assets
import "./inputGroup.scss";
import {BlackArrowRightIcon, SortIcon} from "../../../assets/images";

const {Option} = Select;

interface Props {
    label?: string;
    type?: string;
    inputType?: string;
    name?: string;
    className?: string;
    placeholder?: string;
    autoComplete?: string;
    id?: string;
    errorMsg?: string;
    regExp?: any;
    defValue?: any;
    initValue?: any;
    value?: any;
    forwardRef?: any;
    dropdownRender?: any;
    maxLength?: number;
    maxValue?: number;
    minValue?: number;
    error?: boolean;
    autoFocus?: boolean;
    resizable?: boolean;
    disabled?: boolean;
    integerNumber?: boolean;
    showArrow?: boolean;
    sortIcon?: boolean;
    required?: boolean;
    number?: boolean;
    showSearch?: boolean;
    options?: any;
    onKeyDown?: any;
    onKeyUp?: () => void;
    onSearch?: () => void;
    onFocus?: () => void;
    onPopupScroll?: () => void;
    onClear?: boolean | string;
    notFoundContent?: any;
    onBlur?: any;
    onChange?: any;
    children?: React.ReactElement | React.ReactElement[]
}

export const InputGroup: React.FC<Props> = (props) => {
    const {
        label, type, inputType, name, className, placeholder, error, options, autoComplete = "on",
        onKeyDown, onKeyUp, onSearch, id, defValue, initValue, value, maxLength, autoFocus, resizable,
        disabled, forwardRef, integerNumber, maxValue, minValue, sortIcon, showArrow = true,
        dropdownRender, required, number, regExp, errorMsg
    } = props;
    const [selectOpen, toggleSelectVisibility] = useState(false);
    const [focused, setFocused] = useState(false);

    function onChange(e: any) {
        let {name, value} = e.target;
        if (disabled) {
            return;
        }
        // console.log('Values', value)
        if (number && (isNaN(Number(value)) || value?.split('.')?.[1]?.length > 2)) {
            return;
        }
        if (value && integerNumber) {
            value = parseInt(value)
        }
        if ((integerNumber || number)) {
            if (minValue && Number(value) < minValue && value !== '') {
                return;
            }
            if (maxValue && Number(value) > maxValue && value !== '') {
                return;
            }
        } else {
            value = value.trimStart();
        }
        // console.log('Values', initValue, value, initValue.toString() !== value.toString());
        if (regExp && value && !regExp.test(value)) {
            return;
        }
        props.onChange({
            name,
            value,
            haveChanges: (!initValue && value) || initValue?.toString() !== value?.toString()
        });
    }

    function onChangeSelect(value: string, option: any) {
        props.onChange(name, value, option);
    }

    function onFocus() {
        props.onFocus && props.onFocus();
        setFocused(true)
    }

    function onBlur() {
        props.onFocus && props.onBlur();
        setFocused(false)
    }

    return <div className={`custom-input-group ${focused || value ? 'active' : ''}`} id={name}>

        {label && <label>{label} {required ? <span className={'required-badge'}>*</span> : ''}</label>}
        {inputType === "input" && type !== "number" && type !== "checkbox" && type !== "password" &&
            <>
                <input type={type} name={name} placeholder={placeholder} maxLength={maxLength} ref={forwardRef}
                       value={value || ''} defaultValue={defValue} onChange={onChange} autoComplete={'off'}
                       autoFocus={autoFocus} onKeyDown={onKeyDown} onKeyUp={onKeyUp} onBlur={onBlur} onFocus={onFocus}
                       disabled={!!disabled} id={id}
                       className={`${className} ${error ? "invalid" : ""}`}/>
                {props.children}
                {errorMsg && <div className={`error-message ${!!errorMsg ? 'show' : 'hide'}`}>
                    {errorMsg}
                </div>}
            </>}

        {inputType === "input" && type === "password" &&
            <>
                <input type={type} name={name} placeholder={placeholder} maxLength={maxLength} disabled={!!disabled}
                       value={value || ''} defaultValue={defValue} onChange={onChange}
                       autoFocus={autoFocus} autoComplete={autoComplete} onKeyDown={onKeyDown} onKeyUp={onKeyUp}
                       onBlur={onBlur} onFocus={onFocus} className={`${className} ${error ? "invalid" : ""}`}/>
                {props.children}
                {errorMsg && <div className={`error-message ${!!errorMsg ? 'show' : 'hide'}`}>
                    {errorMsg}
                </div>}
            </>}

        {inputType === "input" && type === "number" &&
            <input type={'text'} name={name} placeholder={placeholder} ref={forwardRef}
                   value={value || ''} onChange={onChange} autoComplete={'off'}
                   autoFocus={autoFocus} onKeyDown={onKeyDown} onKeyUp={onKeyUp} onBlur={onBlur} onFocus={onFocus}
                   disabled={!!disabled} id={id} required={required}
                   className={`${className} ${error ? "invalid" : ""}`}/>}
        {inputType === "number" &&
            <input type={'number'} name={name} placeholder={placeholder} ref={forwardRef} pattern="\d*"
                   value={value || ''} onChange={onChange} autoComplete={'off'}
                   autoFocus={autoFocus} onKeyDown={onKeyDown} onKeyUp={onKeyUp} onBlur={onBlur}
                   disabled={!!disabled} id={id} required={required}
                   className={`${className} ${error ? "invalid" : ""}`}/>}

        {inputType === "input" && type === "checkbox" &&
            <input type={type} name={name} checked={value} onChange={onChange}/>}

        {inputType === "textarea" &&
            <textarea name={name} placeholder={placeholder} value={value || ''}
                      defaultValue={defValue} onChange={onChange} maxLength={maxLength}
                      className={`${className} ${error ? "invalid" : ""} ${resizable ? "resizable" : ""}`}/>}

        {inputType === "select" && props.onPopupScroll &&

            <Select
                value={value}
                showSearch={props.showSearch ?? true}
                defaultOpen={false}
                style={{width: '100%'}}
                placeholder={placeholder}
                onPopupScroll={props.onPopupScroll}
                onChange={onChangeSelect}
                onSearch={onSearch}
                filterOption={false}
                disabled={!!disabled}
                dropdownRender={dropdownRender}
                // getPopupContainer={():any => document.getElementById(name)}
                onDropdownVisibleChange={(value) => toggleSelectVisibility(value)}
                suffixIcon={sortIcon ? <SortIcon title={''}/> : <BlackArrowRightIcon
                    className={`custom-suffix-icon ${selectOpen ? 'rotate' : ''}`}
                />}
                className={`custom-select ${props.className || ''} ${error ? "invalid" : ""}`}
            >
                {!!value && props.onClear && <Option value={undefined}>{props.onClear}</Option>}
                {options && !!options.length && options.map((item:any, index:number) => {
                    return <Option key={index}
                                   value={item?.id}>{item?.name}</Option>
                })}
            </Select>
        }

        {inputType === "select" && !props.onPopupScroll &&
            // <ConfigProvider renderEmpty={customizeRenderEmpty}>
            <>
                <Select
                    value={value}
                    showSearch={props.showSearch ?? true}
                    defaultOpen={false}
                    showArrow={showArrow}
                    // mode={type || 'default'} //
                    style={{width: '100%'}}
                    placeholder={placeholder}
                    onSearch={onSearch}
                    onChange={onChangeSelect}
                    // visible={"true"}
                    disabled={!!disabled}
                    dropdownRender={dropdownRender}
                    onDropdownVisibleChange={(value) => toggleSelectVisibility(value)}
                    suffixIcon={sortIcon ? <SortIcon/> : <BlackArrowRightIcon
                        className={`custom-suffix-icon ${selectOpen ? 'rotate' : ''}`}
                    />}
                    notFoundContent={props.notFoundContent}
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    // getPopupContainer={():any => document.getElementById(name)} //
                    className={`custom-select ${props.className || ''} ${error ? "invalid" : ""}`}
                >

                    {props.onClear && value !== undefined && <Option value={undefined} className="clear-option">
                        {typeof props.onClear === 'boolean' ? 'Ջնջել' : <span>{props.onClear}</span>}
                    </Option>}
                    {options && !!options.length && options.map((item: any, index: number) => {
                        return <Option key={index}
                                       value={item?.id}
                                       disabled={item.parent || item?.disabled}
                                       title={""}
                                       className={`${item.child ? 'child-option' : ''} ${item.parent ? 'parent-option' : ''}`}>
                            {item?.name}
                        </Option>
                    })}
                </Select>
                {errorMsg && <div className={`error-message ${!!errorMsg ? 'show' : 'hide'}`}>
                    {errorMsg}
                </div>}
            </>
        }

        {inputType === "wrapper" &&
            <> {props.children}</>
        }
    </div>
}
