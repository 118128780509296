import {
    _urlPost,
    request
} from "../api";
import {POSTS_CONSTS} from "../constants";
import {CURRENCY_ENUM, POST_TYPES} from "../../constants/constants";
import {PostItemType} from "./actionTypes";

export const GetPostsInitialInfo = (currency = CURRENCY_ENUM.AMD) => {
    const requestData = {
        url: `${_urlPost}/info/${currency}?type=${POST_TYPES.SELL.key}`,
        method: "GET",
        languageFlag: true
    };

    return (dispatch: any) => {
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;
                await dispatch({
                    type: POSTS_CONSTS.GET_POSTS_INITIAL_INFO,
                    payload: data
                })
            })
    };
};

export const GetPostsInfo = ({
                                 type = '',
                                 category = '',
                                 roomsCount = 0,
                                 text = '',
                                 minPrice = '',
                                 maxPrice = '',
                                 regions = [],
                                 residences = [],
                                 currency = CURRENCY_ENUM.AMD,
                             } = {}) => {
    const requestData = {
        url: `${_urlPost}/info/${currency}?`,
        method: "GET",
        languageFlag: true
    };
    type && (requestData.url += `&type=${type}`);
    category && (requestData.url += `&category=${category}`);
    text && (requestData.url += `&text=${text}`);
    roomsCount && (requestData.url += `&roomsCount=${roomsCount}`);
    minPrice && (requestData.url += `&minPrice=${minPrice}`);
    maxPrice && (requestData.url += `&maxPrice=${maxPrice}`);
    regions?.length && (requestData.url += `&regions=${regions.join(',')}`);
    residences?.length && (requestData.url += `&residences=${residences.join(',')}`);
    return (dispatch: any) => {
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;
                await dispatch({
                    type: POSTS_CONSTS.GET_POSTS_INFO,
                    payload: data
                })
            })
    };
};

export const GetPostSearch = ({
                                  reset = true,
                                  currency = CURRENCY_ENUM.AMD,
                                  sort = null,
                                  isUrgent = null,

                                  offset = 0,
                                  limit = 12,
                                  text = '',
                                  type = null,
                                  category = null,
                                  minPrice = null,
                                  maxPrice = null,
                                  roomsCount = null,
                                  minArea = null,
                                  maxArea = null,
                                  subCategory = null,
                                  bathroomsCount = null,
                                  floorNumber = null,
                                  condition = null,
                                  regions ,
                                  residences,
                                  filterValues = [],

                              }: any = {}) => {
    const requestData = {
        url: `${_urlPost}?offset=${offset}&limit=${limit}`,
        method: "GET",
        token: true,
        languageFlag: true,
    };
    currency && (requestData.url += `&currency=${currency}`);
    isUrgent !== null && (requestData.url += `&isUrgent=${isUrgent}`);

    text && (requestData.url += `&text=${text}`);
    type && (requestData.url += `&type=${type}`);
    category && (requestData.url += `&category=${category}`);
    minPrice && (requestData.url += `&minPrice=${minPrice}`);
    maxPrice && (requestData.url += `&maxPrice=${maxPrice}`);
    roomsCount && (requestData.url += `&roomsCount=${roomsCount}`);
    minArea && (requestData.url += `&minArea=${minArea}`);
    maxArea && (requestData.url += `&maxArea=${maxArea}`);
    subCategory && (requestData.url += `&subCategory=${subCategory}`);
    bathroomsCount && (requestData.url += `&bathroomsCount=${bathroomsCount}`);
    floorNumber && (requestData.url += `&floorNumber=${floorNumber}`);
    condition && (requestData.url += `&condition=${condition}`);
    filterValues?.length && (requestData.url += `&filterValueIds=${filterValues.join(',')}`);
    regions?.length && (requestData.url += `&regions=${regions.join(',')}`);
    residences?.length && (requestData.url += `&residences=${residences.join(',')}`);

    sort && (requestData.url += `&sort=${sort}`);

    return (dispatch: any) => {
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;
                await dispatch({
                    type: POSTS_CONSTS.GET_POST_SEARCH,
                    payload: {
                        posts: data?.posts,
                        count: data?.postsCount,
                        hasMore: data?.posts?.length === limit,
                        reset,
                    }
                })
            })
    };
};

export const GetBestOffers = ({
                                  offset = 0,
                                  limit = 12,
                                  category = null
                              }: any = {}) => {
    const requestData = {
        url: `${_urlPost}/specialOffers?offset=${offset}&limit=${limit}`,
        method: "GET",
        token: true,
        languageFlag: true
    };
    category && (requestData.url += `&category=${category}`);

    return (dispatch: any) => {
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;
                await dispatch({
                    type: POSTS_CONSTS.GET_BEST_OFFERS,
                    payload: data?.posts
                })
            })
    };
};


export const GetSimilarPosts = ({
                                    offset = 0,
                                    limit = 12,
                                    category = null,
                                    currency = CURRENCY_ENUM.AMD,
                                    type = 'SELL'
                                }: any = {}) => {
    const requestData = {
        url: `${_urlPost}?offset=${offset}&limit=${limit}&currency=${currency}`,
        method: "GET",
        token: true,
        languageFlag: true
    };
    category && (requestData.url += `&category=${category}`);
    type && (requestData.url += `&type=${type}`);
    return (dispatch: any) => {
        return request(requestData)
            .then(async (res) => {
                return res.data?.posts || [];
            })
    };
};

export const GetFavoritePosts = ({
                                     offset = 0,
                                     limit = 10,
                                 } = {}) => {
    const requestData = {
        url: `${_urlPost}/favorites?offset=${offset}&limit=${limit}`,
        method: "GET",
        token: true,
        languageFlag: true,
    };
    return (dispatch: any) => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                // console.log("data", data)
                data && dispatch({
                    type: POSTS_CONSTS.GET_FAVORITE_POSTS,
                    payload: {
                        data: data.posts,
                        count: data.postsCount,
                    }
                })
            })
    };
};


export const GetRecentlyViewedPostsByIds = (ids = []) => {
    const requestData = {
        url: `${_urlPost}/ids?ids=${ids.join(',')}`,
        method: "GET",
        token: true,
        languageFlag: true
    };

    return (dispatch: any) => {
        return request(requestData)
            .then(async (res) => {
                const data = res && res.data;
                await dispatch({
                    type: POSTS_CONSTS.RESET_RECENTLY_VIEWED_POSTS,
                    payload: data
                })
            })
    };
};

export const GetPostById = (id: string) => {
    const requestData = {
        url: `${_urlPost}/${id}`,
        method: "GET",
        languageFlag: true,
        token: true
    };
    return (dispatch: any) => {
        return request(requestData)
            .then((res) => {
                dispatch({
                    type: POSTS_CONSTS.ADD_POST_TO_RECENTLY_VIEWED,
                    payload: res.data
                })
                return res.data
            })
    };
};

export const SetInitialInfo = () => {
    // console.log('set initial')
    return (dispatch: any) => {
        dispatch({
            type: POSTS_CONSTS.SET_POSTS_INITIAL_INFO_AS_CURRENT,
        })
    }
}


export const AddPostToRecentlyViewed = (post: PostItemType) => {
    return (dispatch: any) => {
        dispatch({
            type: POSTS_CONSTS.ADD_POST_TO_RECENTLY_VIEWED,
            payload: post
        })
    }
}

export const LikePost = (id: string) => {
    const requestData = {
        url: `${_urlPost}/${id}/add-to-favorites`,
        method: "POST",
        token: true,
        scope: false
    };

    return (dispatch: any) => {
        return request(requestData).then(async () => {
            const likeState = true;
            await dispatch({
                type: POSTS_CONSTS.TOGGLE_POST_LIKE,
                payload: {id, likeState}
            });
        })
    };
};

export const UnLikePost = (id: string) => {
    const requestData = {
        url: `${_urlPost}/${id}/remove-from-favorites`,
        method: "DELETE",
        token: true,
        scope: false
    };
    return (dispatch: any) => {
        return request(requestData).then(async () => {
            const likeState = false;
            await dispatch({
                type: POSTS_CONSTS.TOGGLE_POST_LIKE,
                payload: {id, likeState}
            });
        })
    };
};


export const AddPostToComparedList = (post: PostItemType) => {
    // console.log(post,'post')
    return (dispatch: any) => {
        dispatch({
            type: POSTS_CONSTS.ADD_POST_TO_COMPARED_LIST,
            payload: post
        })
    }
}
export const RemovePostFromComparedList = (id: string) => {
    return (dispatch: any) => {
        dispatch({
            type: POSTS_CONSTS.REMOVE_POST_FROM_COMPARED_LIST,
            payload: id
        })
    }
}

export const SetFiltersData = (data: any) => {
    return (dispatch: any) => {
        console.log('SetFiltersData', data)
        dispatch({
            type: POSTS_CONSTS.SET_FILTERS_DATA,
            payload: data
        })
    }
}

