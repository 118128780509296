import React from "react";
import styled, {css}  from "styled-components";
import {useNavigate} from "react-router-dom";
import {LoadingOutlined} from "@ant-design/icons";

interface Props {
    className?: string;
    bordered?: boolean;
    lightBordered?: boolean;
    disabled?: boolean;
    light?: boolean;
    title?: any;
    link?: string;
    state?: any;
    characterId?: any;
    click?: any;
    loading?: boolean;
}

const Button = styled.button<Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 32px;
  font-size: 13px;
  border-radius: 2px;
  background: #CDBB99;
  color: white;
  border: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  transition: all 0.2s;

  ${props => props.bordered && css`
    color: black;
    border: 1px solid black;
    background: transparent;
    padding: 10px 42px;
    text-transform: uppercase;
    height: 40px;
`}
  
  ${props => props.lightBordered && css`
    color: #BCA477;
    border: 1px solid #BCA477;
    background: transparent;
    padding: 10px 42px;
    text-transform: uppercase;
    height: 40px;
`}
  
  ${props => props.light && css`
    color: #BCA477;
    background: #F6F3ED;
    padding: 10px 20px;
    height: 36px;
    &:hover{
      background-color: #BCA477;
      color: white;
    }
`}
  ${props => props.disabled && css`
    background-color: #E7DECE;
    color: white;
  `}
  
  .loading-icon{
    margin-left: 10px;
    svg{
      width: 15px;
      height: 15px;
    }
  }
  .tick-icon{
    height: 24px;
    width: 24px;
    margin-left: 10px;
    transition: all 0.2s;
    visibility: visible;
    path, polygon{
      fill: white;
    }
  }
  .show-tick{
    visibility: visible;
    opacity: 1;
  }
  .hide-tick{
    visibility: hidden;
    opacity: 0;
    width: 0;
    margin-left: 0;
  }
`;

export const MainButton: React.FC<Props> = ({
                                                className,
                                                click,
                                                title = '',
                                                state = null,
                                                loading = false,
                                                link,
                                                bordered = false,
                                                disabled = false,
                                                lightBordered = false,
                                                light = false,
                                            }) => {

    const navigate = useNavigate();

    return (
        <Button className={className}
                characterId={6}
                bordered={bordered}
                disabled={disabled}
                lightBordered={lightBordered}
                light={light}
                onClick={()=> {
                  if(link) {
                      navigate(link, {state})
                  }else{
                   !loading && click && click()
                  }
                }}
        >
            {title}
            {
                loading && <span className={"loading-icon"}><LoadingOutlined style={{fontSize: 12, color: "#fff"}}/></span>
            }
        </Button>
    )
}
