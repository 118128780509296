// Import packages
import React, {useEffect} from "react";


// Import assets
import "./about.scss"
import {MainButton} from "../../components/ui-elements/button/Buttons";
import Subscribe from "../homepage/subscribe/Subscribe";
import PageHeader from "../../components/header/page-header/page-header";
import {getPropsFromState} from "../../redux/mapStateToProps";
import {connect} from "react-redux";
import {generateImageMediaUrl} from "../../utils/generateMediaUrl";

// Import components

// Import utils.

function About(props){

    const {information,translation} = props

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])
    return <div className={'about_wrapper'}>
        <div className={'about_wrapper__header'}>
            <PageHeader name={information?.pageName}/>
        </div>
        <div className={'about_wrapper__components'}>
            <div className={'about_wrapper__components__firstBlock'}>
                <div className={'about_wrapper__components__firstBlock__description'}>
                    <div className={'title'}>{information?.title}</div>
                    <div className={'text'} dangerouslySetInnerHTML={{__html: information?.description}}/>
                    <div className={'buttons'}>
                        <MainButton  title={translation?.about_page_explore_btn_title} className={'buttons__apartment'} />
                        <MainButton  link={'/contact'} title={translation?.about_page_contact_btn_title} className={'buttons__contact'} bordered={true}/>
                    </div>
                </div>
                <div className={'about_wrapper__components__firstBlock__img'}>
                    <div className={'about_wrapper__components__firstBlock__img__wrapper'}>
                        <img src={generateImageMediaUrl(information?.backgroundImage?.path)} alt=""/>
                    </div>
                </div>
            </div>
            <div className={'about_wrapper__components__secondBlock'}>
                {information?.blocks?.length &&  information?.blocks?.map(item => {
                   return <div className={'secondBlock_wrapper'} key={item?.title}>
                       <div className={'info_block'}>
                           <div className={'title'}>{item?.title}</div>
                           <div className={'description'} dangerouslySetInnerHTML={{__html:  item?.description}}></div>
                       </div>
                       <span className={'line'}/>
                   </div>
                })}
            </div>
            <div className={'components__subscribe'}>
                <Subscribe/>
            </div>
        </div>
    </div>
}
const mapStateToProps = (state) => getPropsFromState(state, [
    'information',
    'translation',
]);

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps) (About);