// import React from "react";
import {_urlNotification, request} from "../api";
import {history} from "../../configs/history";
import {store} from "../store";

//import utils
import { NOTIFICATION_CONSTS} from "../constants";
import {GetNonOpenedNotificationsCount, SetNotificationAsOpened} from "../../socket/emitters";
import {checkBrowserTabNotVisible} from "../../utils/checkBrowserTabNotVisible";
import { PER_PAGE_NOTIFICATIONS_COUNT } from "../../constants/constants";

export const GetNotifications = ({
                                     offset = 0,
                                     limit = PER_PAGE_NOTIFICATIONS_COUNT,
                                 } = {}) => {
    const requestData = {
        url: `${_urlNotification}/?offset=${offset}&limit=${limit}`,
        method: "GET",
        token: true,
        languageFlag: true,
    };

    return dispatch => {
        return request(requestData)
            .then((res) => {
                const data = res && res.data;
                data && dispatch({
                    type: NOTIFICATION_CONSTS.GET_NOTIFICATION_LIST,
                    payload: {
                        data: data.notifications,
                        count: data.notificationsCount,
                    }
                });
                return data;
            }).catch(() => {
            })
    };
};

export const newNotification = (notification) => {
    // console.log('new Notif', notification);
    let path = history?.location?.pathname;
    let inNotificationPage = path && path.startsWith('/profile/notifications');
    // console.log("inNotificationPage", inNotificationPage);
    // !inNotificationPage && store.dispatch({
    //     type: NOTIFICATION_CONSTS.NEW_NOTIFICATION,
    //     payload: notification
    // });
    // console.log(inNotificationPage, history.location.pathname)

    if (!inNotificationPage || checkBrowserTabNotVisible()) {
        GetNonOpenedNotificationsCount();
    } if (inNotificationPage) {
        SetNotificationAsOpened(notification.id);
    }
};


