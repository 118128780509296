// Import packages
import React, { PureComponent } from "react";

// Import components

// Import styles
import "./profileImageUploader.scss";

//Import Images
import { UploadIcon } from "../../assets/images";

//import utils
import { hasExtension } from "../../utils/hasExtension";
import { ACCEPT_TYPES, ACCEPT_IMAGE_SIZE } from "../../constants/constants";
import { generateFileMediaUrl } from "../../utils/generateMediaUrl";


export class ImageUploader extends PureComponent {
    constructor (props) {
        super(props);
        this.state = {
            image: '',
            imageError: false,
            errorMessage: "",
        };
        this.imageSelector = React.createRef();
    }

    componentDidUpdate (prevProps) {
        if (prevProps?.loggedInUser !== this.props?.loggedInUser) {
            this.setState({
                image: '',
                imageError: false,
                errorMessage: "",
            })
            this.props.onChange(null)
        }
    }

    readFile (file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            // Read the image via FileReader API and save image result in state.
            reader.onload = function(e) {
                let dataURL = e.target.result;
                resolve(dataURL);
            };
            reader.onerror = function(e) {
                //console.log(e);
                reject(e);
            };
            reader.readAsDataURL(file);
        });
    }

    handleImageChange (e) {
        let { translation } = this.props;
        let file = e && e.target && e.target.files && e.target.files[0];

        if (file) {
            if (!hasExtension(file.name)) {
                this.setState({
                    imageError: true,
                    errorMessage: `Accepted: ${ACCEPT_TYPES.join('| ')}`
                });
                return;
            }
            if (file.size > ACCEPT_IMAGE_SIZE) {
                this.setState({
                    imageError: true,
                    errorMessage: `${translation?.settings_file_size_message} ${Math.floor(ACCEPT_IMAGE_SIZE / 1048576)}mb`
                });
                return;
            }
            this.readFile(file)
                .then(dataURL => {
                    // console.log(res);
                    this.setState({
                        image: dataURL,
                        imageError: false,
                        errorMessage: "",
                    }, () => this.props.onChange(file))
                });
        }
    }

    render () {
        let { image } = this.state;
        let { translation, loggedInUser, disabled } = this.props;
        const firstNameLatter = loggedInUser?.firstName?.slice(0, 1)
        const imageUrl = image ? image : (this.props?.loggedInUser?.profilePicturePath?.path
            ? generateFileMediaUrl(this.props?.loggedInUser?.profilePicturePath?.path)
            : '')
        return <div className={`image-uploader`}>
            <div className={'image-wrapper'}>
                <div className="image-wrapper-hover"/>
                {
                    imageUrl ? <img src={imageUrl} alt=" "/> : <div className={'userName'}>{firstNameLatter}</div>
                }
            </div>
            {!disabled && <div className={`img-selector`}
                  onClick={() => {
                      this.imageSelector.current.click();
                      // console.log('upload')
                  }}>
                <UploadIcon/>
            </div>}
            <input type="file" accept="image/*" ref={this.imageSelector}
                   onChange={(e) => this.handleImageChange(e)}/>
        </div>
    }
}
