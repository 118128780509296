// import packages
import React, { useEffect, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { YMaps, Map, Placemark, FullscreenControl } from 'react-yandex-maps';

// Import assets
import "./map.scss"

import {
    Card_Map,
    fullScreen,
    fullScreen2,
    LocationIcon,
} from "../../../assets/images";
import { generateImageMediaUrl } from "../../../utils/generateMediaUrl";
import { useNavigate } from "react-router-dom";
import { priceByCurrency } from "../../../utils/priceByCurrency";
import { priceWithCommas } from "../../../utils/helperFunctions";
import { POST_TYPES } from "../../../constants/constants";


function Balloon ({ id, selected, data, type, translation }) {
    const price = type === POST_TYPES.SELL.key ? data.priceSell: data.priceRent
    return (
        <div id={id} className={`ballon ${selected ? 'selected' : ""}`}>
            <div className={'content'}>
                <div className={'image'}>
                    <img src={generateImageMediaUrl(data?.mediaMain?.path)} alt=""/>
                </div>
                <div className={'info'}>
                    <div className="title">{data?.title}</div>
                    <div className="description">
                        {data?.condition && <span>{translation?.cards_condition_text} • </span>}
                        {data?.floorNumber &&
                        <span>{data?.floorNumber}{translation?.cards_floorNumber_text} {data.buildingFloorsCount ? data.buildingFloorsCount : '-'}</span>}
                        {data?.floorNumber === null &&
                            <span>{data.buildingFloorsCount} {translation?.filter_floor_label}</span>}
                        {data?.floorNumber || data.buildingFloorsCount && <span> • </span>}
                        {data?.roomsCount && <span>{data.roomsCount} {translation?.cards_roomsCount_text}</span>}
                    </div>
                    <div className="post_address">
                        <Card_Map/> {data?.location?.region.title}, {data?.location?.region?.residence?.title}
                    </div>
                    <div className={'bottom-part'}>
                        <div
                            className="price">{price ? priceWithCommas(priceByCurrency(price, data.currency)) :
                            <div>{translation?.homepage_post_price} :<span>{translation?.homepage_post_price_text}</span></div>
                        }</div>
                    </div>

                </div>
            </div>
            <div
                className={'placemark'}>{price ? priceWithCommas(priceByCurrency(price, data.currency)) : translation?.homepage_post_price_text}
            </div>
        </div>
    );

}

export const SearchMap = ({ posts, translation, type }) => {
    const [ymap, setYmap] = useState(null)
    const [ymapFullScreenControl, setYmapFullScreenControl] = useState(null)
    const [mapFullscreenIcon, setMapFullscreenIcon] = useState(fullScreen)
    const navigate = useNavigate();

    const onPlaceMarkClick = (item) => {
        navigate(`/post/${item.slug}`)
    };
    useEffect(() => {
        ymapFullScreenControl
        && ymapFullScreenControl.events.add('click', (event) => {
            setMapFullscreenIcon(event?.originalEvent?.target?._selected ? fullScreen: fullScreen2)
        })
    }, [ymapFullScreenControl])

    function createChipsLayout (id, item, calculateSize) {
        // Создадим макет метки.
        const html = ReactDOMServer.renderToString(
            <Balloon id={id}

                     translation={translation}

                     data={item}

                     type={type}
            />);
        var Chips = ymap.templateLayoutFactory.createClass(html,
            {
                build: function() {
                    Chips.superclass.build.call(this);
                    const el = document.getElementById(item.id)
                    this.getData().geoObject.events.add(['mouseenter'], function(e) {
                        el.classList.add('selected')
                    })
                    this.getData().geoObject.events.add(['mouseleave'], function(e) {
                        el.classList.remove('selected')
                    })
                    var map = this.getData().geoObject.getMap();
                    if (!this.inited) {
                        this.inited = true;
                        // Получим текущий уровень зума.
                        var zoom = map.getZoom();
                        // Подпишемся на событие изменения области просмотра карты.
                        map.events.add('boundschange', function() {
                            // Запустим перестраивание макета при изменении уровня зума.
                            var currentZoom = map.getZoom();
                            if (currentZoom !== zoom) {
                                zoom = currentZoom;
                                this.rebuild();
                            }
                        }, this);
                    }
                    let options = this.getData().options;
                    // Получим размер метки в зависимости от уровня зума.
                    let size = calculateSize(map.getZoom());
                    // console.log('size', size, 'zoom', map.getZoom(), 'cordi', 2 * size, size);
                    let element = this.getParentElement().getElementsByClassName('ballon')[0];
                    // По умолчанию при задании своего HTML макета фигура активной области не задается,
                    // и её нужно задать самостоятельно.
                    // Создадим фигуру активной области "Круг".
                    const width = item?.price ? 100 : 135;
                    const height = 25;


                    // let circleShape = { type: 'Circle', coordinates: [0, 0], radius: size / 2 };
                    let rectangleShape = { type: 'Rectangle', coordinates: [[0, 0], [width, height]] };
                    // Зададим высоту и ширину метки.
                    // element.style.width =  2 * size + 'px';
                    // element.style.height = size + 'px';
                    element.style.width = width + 'px';
                    element.style.height = height + 'px';
                    // Зададим смещение.
                    // element.style.marginLeft = element.style.marginTop = -size / 2 + 'px';

                    // Зададим фигуру активной области.
                    // options.set('shape', circleShape);
                    options.set('shape', rectangleShape);
                }
            }
        );
        return Chips;
    };

    return <div className="map">
        <YMaps query={{ apikey: '75a37665-fab6-4ccb-935a-bce1e1913cd1',
            lang: `en`}} >
            <Map
                defaultState={{
                    center: [40.177870, 44.512888],
                    zoom: 13,
                    hue: "ff0000",
                }}
                onLoad={(ympasInstance) => {
                    setYmap(ympasInstance)
                    // console.log('onload')
                }}
                options={{
                    minZoom: 5
                }}
                modules={["Placemark", "geocode", "geoObject.addon.balloon", "geoObject.addon.hint", "templateLayoutFactory", "layout.ImageWithContent"]}
                width={'100%'}
                height={'100%'}
            >
                {
                    ymap && !!posts?.list?.length && posts?.list?.map((item) => {
                        return <Placemark
                            geometry={[item?.location?.latitude, item?.location?.longitude]}
                            options={{
                                iconLayout: createChipsLayout(item.id, item, function(zoom) {
                                    // Минимальный размер метки будет 8px, а максимальный мы ограничивать не будем.
                                    // Размер метки будет расти с линейной зависимостью от уровня зума.
                                    // return 4 * zoom + 8;
                                    return 50;
                                })
                            }}
                            onClick={() => onPlaceMarkClick(item)}
                            key={item?.id}
                        />
                    })
                }
                {ymap &&
                <FullscreenControl
                    instanceRef={(ref) => {
                        setYmapFullScreenControl(ref)
                    }}
                    options={{
                        position: {
                            bottom: 40,
                            right: 20
                        }
                    }}
                    press={() => console.log('press')}
                    data={{ image: mapFullscreenIcon }}
                />}
            </Map>
        </YMaps>
    </div>
}


